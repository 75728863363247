import React, { useEffect, useState } from "react";

// import product1 from "../../assets/img/product1.png";
// import product2 from "../../assets/img/product2.png";
import { FaPlus, FaSearch } from "react-icons/fa";
import MaterialCard from "../material-card";
import services from "../services";
import Pagination from "../pagination";
import LoadingSpinner from "../../components/Loading_spinner";
import Spinner from "../../components/spinner";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const AllMaterials = () => {
  const [suppliers, setSuppliers] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [dataLength, setDataLength] = useState(null);
  const [materialFound, setMaterialFound] = useState(false);
  const [execute, setExecute] = useState(false);
  const [orderBy, setOrderBy] = useState('Newest');
  const [searchText, setSearchText] = useState('');

  const getSuppliers = (pageNo, searchTextValue=searchText, orderByValue = orderBy) => {
    // console.log(page);
    fetch(services.url+"/suppliers.php?per_page="+limit+"&page="+pageNo+"&search_text="+searchTextValue+"&orderBy="+orderByValue)
      .then((res) => res.json())
      .then((res) => {
        setExecute(true);
        setDataLength(res.no_of_rows);
        setSuppliers(res.suppliers);
        setLoading(false);
        setSearchLoading(false);
        if(res.found === 1){
          setMaterialFound(true);
        }else{
          setMaterialFound(false);
        }
        // console.log(res);
      });
  };

  useEffect(() => {
    setLoading(true);
    getSuppliers(page);
  }, []);

  let totalPage = Math.ceil(dataLength/limit);
  let start = (page - 1) * limit + 1;
  let end = page * limit;
  
  function handlePageChange(value){
    let pageno = page;
    if(value === "... "){
      setPage(1);
      pageno = 1;
    }
    else if(value === "pre"){
      if(page !== 1){
        setPage(page - 1);
        pageno = page - 1;
      }
    }else if(value === "next"){
      if(page !== totalPage){
        setPage(page + 1);
        pageno = page + 1;
      }
    }else if(value === " ..."){
      setPage(totalPage);
      pageno = totalPage;
    }else{
      setPage(value);
      pageno = value;
    }
    setLoading(true);
    getSuppliers(pageno);
  }

  const handleSearch = (ele) => {
    let searchTextValue = ele.currentTarget.value;
    setSearchText(searchTextValue)
    setSearchLoading(true);
    getSuppliers(1, searchTextValue);
  }

  const handleOrderBy = (ele) => {
    let orderByValue = ele.currentTarget.value;
    setOrderBy(orderByValue);
    getSuppliers(1, searchText, orderByValue);
  }
  // const customers = [
  //   {
  //     id: 1,
  //     logo: product1,
  //     name: "Name Supplier",
  //   },
  //   {
  //     id: 2,
  //     logo: product2,
  //     name: "Name Supplier",
  //   },
  // ];

  const deleteCard = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id
        };
        fetch(services.url+"/delete_supplier.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          setPage(1);
          getSuppliers(1);
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        });
      }
      
    });
  };

  return (
    <>
      <div className="flex md:flex-row flex-col-reverse md:gap-0 gap-3 md:p-0 p-3 md:my-7 justify-between items-center">
        <form
          action=""
          className="search flex justify-between items-center gap-3 bg-white px-6 py-3 rounded-full"
        >
          <FaSearch className="text-blue" />
          <input type="text" className="w-[300px] search_input" name="" id="" onChange={handleSearch} autoComplete="off" />
          {searchLoading ? <Spinner/> : ''}
        </form>

        <div className="right text-end flex justify-end items-center gap-7">
          {/* <p className="text-blue flex justify-start items-center gap-2">
            Newest <FaCaretDown className="text-xl" />
          </p> */}
          <select
            className="text-blue bg-transparent outline-none shadow-none border-none flex justify-start items-center gap-2"
            name=""
            id=""
            onChange={handleOrderBy}
          >
            <option value="Newest">Newest</option>
            <option value="Oldest">Oldest</option>
          </select>

          <Link
            to={"/material/add-new"}
            className="btn-blue flex justify-center items-center gap-2 px-5 py-3"
          >
            <FaPlus /> <span>Material</span>
          </Link>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-[20px]">
        {suppliers.map((supplier) => (
          <MaterialCard supplier={supplier} key={supplier.id} deleteCard={deleteCard} />
        ))}
      </div>
      <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} start={start} end={end} totalData={dataLength} onPageChange={handlePageChange}/>

      {loading ? <LoadingSpinner/> : ""}
    </>
  );
};

export default AllMaterials;
