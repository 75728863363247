import React from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import AllProjectsSupplier from "../../components/project-supplier";

const ProjectsSupplier = () => {
  return (
    <div className="customers">
      <AllProjectsSupplier />
      <Footer/>
    </div>
  );
};

export default ProjectsSupplier;
