import React from "react";
import services from "../services";

const MaterialDetails = ({ collections }) => {
  console.log(collections);
  return (
    <>
      <div className="w-100">
        {collections.map((materials, key) => (
          <>
            {Object.keys(materials).map((key2,index2)=>(
              <>
              <h1 style={{fontWeight:'bold'}}>{key2}</h1>
              <div className="material_box">
                {materials[key2].map((materialData) => (
                  <div className={'material_data_box_item'}>
                    <div className="material_data_box_item_image">
                      <img src={materialData.materialColor}/>
                    </div>
                    <div className="material_data_box_item_name">
                      <h1>{materialData.materialColorName}</h1>
                    </div>
                  </div>
                ))}
              </div>
              </>
            ))}
          </>
        ))}
      </div>
    </>
  );
};

export default MaterialDetails;
