import React, { useEffect, useState, useRef } from "react";
import {useNavigate, useParams } from "react-router-dom";
import services from "../../components/services";
import '../../assets/css/add_project.css';
import { FaAngleDown, FaAngleUp, FaPlus, FaTimes } from "react-icons/fa";
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import CustomColor from "../../assets/img/customer_color.png";


const ProjectMaterialSingle = () => {
  const ref = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [products, setPorduct] = useState([]);
  const [wrap, setWrap] = useState(null);
  const [found, setFound] = useState(null);
  const [categoryFound, setCategoryFound] = useState(null);
  const [mqty, setmqty] = useState(null);
  const [material, setMaterial] = useState([]);
  const [execute, setExecute] = useState(false);
  const [customColor, setCustomColor] = useState(null);
  const [colors, setColors] = useState([]);
  
  const navigate = useNavigate();

  const {product_id} = useParams();
  
  useEffect(() => {
    // localStorage.removeItem("sub_categories");
    let project = JSON.parse(localStorage.getItem('project'));
    console.log(JSON.parse(localStorage.getItem("material_data")));
    let supplier = project.supplierName;

    setLoading(true);
    setExecute(false);
    fetch(services.url+"/get_material_category_for_project.php?supplier="+supplier, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        
        setCollections(res);
        if(res.length > 0){
          setCategoryFound(1);
          var active_category = res.filter((item)=> item.active === 'active');
          if(active_category.length > 0){
            var category_id = active_category[0].id;
          
        
            // alert(category_id);  
            let data = {category_id}
            fetch(services.url+"/get_material_sub_category2.php", {
              method: "POST",
              // headers: {
              //   "Content-Type": "application/json",
              // },
              body: JSON.stringify(data),
            })
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                setExecute(true);
                setLoading(false);
                
                let load_material_data = JSON.parse(localStorage.getItem("material_data"));

                if(load_material_data === null || typeof load_material_data === "undefined"){

                }else{
                  var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
                  if (get_product_index >= 0) {
                    let materialData = load_material_data[get_product_index].material_data;
                    if(materialData.length > 0){
                      materialData.forEach((item)=>{
                        // console.log(item);
                        let materialDataIndex = item.index;
                        let materialDataSubCatId = item.subCatId;
                        var getMaterialSubCatIndex = res.subcategories.findIndex(el => el.subCatId == materialDataSubCatId);
                        // console.log(getMaterialSubCatIndex);
                        if(getMaterialSubCatIndex >= 0){
                          let getMaterialDataIndex = res.subcategories[getMaterialSubCatIndex].materials.findIndex(el=>el.index==materialDataIndex);
                          // console.log(getMaterialDataIndex);
                          // console.log('ok');
                          if(getMaterialDataIndex >= 0){
                            res.subcategories[getMaterialSubCatIndex].materials[getMaterialDataIndex].selected = true;
                            if(item.customColor == 1)  {
                              res.subcategories[getMaterialSubCatIndex].materials[getMaterialDataIndex].material_color = item.color;
                              res.subcategories[getMaterialSubCatIndex].materials[getMaterialDataIndex].material_color_name = item.name;
                            }
                          }
                        }
                      })
                      
                    }
                  }
                }

                setSubcategory(res.subcategories);
                
              });
          }else{
            setCategoryFound(0);
            setLoading(false);
          }
        }else{
          setCategoryFound(0);
          setLoading(false);
        }
        
        
      });

      
    
      // setTimeout(() => {
        
      // }, 200);
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));
    
  }, []);

  
  const handle_category_item = (ele) => {
    let category_id = ele.currentTarget.id;
    let data = {category_id}
    setLoading(true);
    setCollections(oldValue => {
      return oldValue.filter((item) => {
        if(item.id == category_id){
          item.active = 'active';
        }else{
          item.active = '';
        }
        return true;
      });
    })
    setExecute(false);
    fetch(services.url+"/get_material_sub_category2.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setExecute(true);
        console.log(res);
        // let elr = document.getElementsByClassName('product_box');
        // if(elr.length > 0){
        //   for(var i=0; i<elr.length; i++){
        //     elr[i].remove();
        //   }
        // }
        
        setLoading(false);

        let load_material_data = JSON.parse(localStorage.getItem("material_data"));

        if(load_material_data === null || typeof load_material_data === "undefined"){

        }else{
          var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
          if (get_product_index >= 0) {
            let materialData = load_material_data[get_product_index].material_data;
            if(materialData.length > 0){
              materialData.forEach((item)=>{
                // console.log(item);
                let materialDataIndex = item.index;
                let materialDataSubCatId = item.subCatId;
                var getMaterialSubCatIndex = res.subcategories.findIndex(el => el.subCatId == materialDataSubCatId);
                // console.log(getMaterialSubCatIndex);
                if(getMaterialSubCatIndex >= 0){
                  let getMaterialDataIndex = res.subcategories[getMaterialSubCatIndex].materials.findIndex(el=>el.index==materialDataIndex);
                  // console.log(getMaterialDataIndex);
                  // console.log('ok');
                  if(getMaterialDataIndex >= 0){
                    res.subcategories[getMaterialSubCatIndex].materials[getMaterialDataIndex].selected = true;
                    if(item.customColor == 1)  {
                      res.subcategories[getMaterialSubCatIndex].materials[getMaterialDataIndex].material_color = item.color;
                      res.subcategories[getMaterialSubCatIndex].materials[getMaterialDataIndex].material_color_name = item.name;
                    }  
                  }
                }
              })
              
            }
          }
        }

        setSubcategory(res.subcategories);
        
        setFound(null);
        setPorduct([]);
        setWrap(null);
        
      });
  }
  
  const handle_material_data_item = (e) => {
    let index = e.currentTarget.getAttribute('data-index');
    let color = document.getElementById('material_color-'+index).value;
    let name = document.getElementById('material_color_name-'+index).value;
    let subCatName = document.getElementById('sub_cat_name-'+index).value;
    let subCatId = index.split('-')[0];

    

    setSubcategory(oldValue=>{
      return oldValue.filter((item) => {
        if(item.subCatId == subCatId){
          return item.materials.filter((item2)=>{

            let load_material_data = JSON.parse(localStorage.getItem("material_data"));
            if(item2.index === index && item2.selected === false){
              item2.selected = true;
              
              if(load_material_data === null || typeof load_material_data === "undefined"){
                var new_material_data = [{
                  product_id,
                  material_data: [{index, subCatId, subCatName, color, name, customColor: 0, sameImage: 0}]
                }];
    
                localStorage.setItem("material_data", JSON.stringify(new_material_data));
    
                console.log(new_material_data);
              }else{
                var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
                if(get_product_index >= 0){
                  // console.log('exits');
                  let materialData = load_material_data[get_product_index].material_data;
                  if(materialData.length > 0){
                    let getSameMaterial = load_material_data[get_product_index].material_data.findIndex(el => el.subCatId == subCatId);
                    if(getSameMaterial >= 0){
                      load_material_data[get_product_index].material_data[getSameMaterial] = {index, subCatId, subCatName, color, name, customColor: 0, sameImage: 0};
                    }else{
                      materialData.push({index, subCatId, subCatName, color, name, customColor: 0, sameImage: 0});
                      load_material_data[get_product_index].material_data = materialData;
                    }
                    
                  }else{
                    load_material_data[get_product_index].material_data = [{index, subCatId, subCatName, color, name, customColor: 0, sameImage: 0}];
                  }
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }else{
                  
                  var new_material_data = {
                    product_id,
                    material_data: [{index, subCatId, subCatName, color, name, customColor: 0, sameImage: 0}]
                  };

                  load_material_data.push(new_material_data);
      
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                  
                }
              }

            }else if(item2.index === index && item2.selected === true){
              item2.selected = false;
              var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
              if(get_product_index >= 0){
                let materialData = load_material_data[get_product_index].material_data.filter(item => item.index !== index);
                // console.log(materialData);
                if(materialData.length == 0){
                  let products = load_material_data.filter(el => el.product_id != product_id);
                  if(products.length == 0){
                    localStorage.removeItem("material_data");
                  }else{
                    load_material_data = products;
                  }
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }else{
                  load_material_data[get_product_index].material_data = materialData;
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }
              }
              
            }else{
              item2.selected = false;
            }
            return true;
          })
        }
        return true;
      })
    })

  }

  const handle_material_custom_color = (e) => {

    /*
    let index = e.currentTarget.getAttribute('data-index');
    let value = e.currentTarget.value;
    let subCatName = document.getElementById('sub_cat_name-'+index).value;
    let subCatId = index.split('-')[0];

    setSubcategory(oldValue=>{
      return oldValue.filter((item) => {
        if(item.subCatId == subCatId){
          return item.materials.filter((item2)=>{

            let load_material_data = JSON.parse(localStorage.getItem("material_data"));
            if(item2.index === index && item2.selected === false){
              item2.selected = true;
              item2.material_color = value;
              item2.material_color_name = value;
              if(load_material_data === null || typeof load_material_data === "undefined"){
                var new_material_data = [{
                  product_id,
                  material_data: [{index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0}]
                }];
    
                localStorage.setItem("material_data", JSON.stringify(new_material_data));
    
                console.log(new_material_data);
              }else{
                var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
                if(get_product_index >= 0){
                  // console.log('exits');
                  let materialData = load_material_data[get_product_index].material_data;
                  if(materialData.length > 0){
                    let getSameMaterial = load_material_data[get_product_index].material_data.findIndex(el => el.subCatId == subCatId);
                    if(getSameMaterial >= 0){
                      load_material_data[get_product_index].material_data[getSameMaterial] = {index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0};
                    }else{
                      materialData.push({index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0});
                      load_material_data[get_product_index].material_data = materialData;
                    }
                    
                  }else{
                    load_material_data[get_product_index].material_data = [{index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0}];
                  }
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }
              }

            }else if(item2.index === index && item2.selected === true){
              item2.selected = false;
              item2.material_color = '';
              item2.material_color_name = '';
              var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
              if(get_product_index >= 0){
                let materialData = load_material_data[get_product_index].material_data.filter(item => item.index !== index);
                // console.log(materialData);
                if(materialData.length == 0){
                  let products = load_material_data.filter(el => el.product_id != product_id);
                  if(products.length == 0){
                    localStorage.removeItem("material_data");
                  }else{
                    load_material_data = products;
                  }
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }else{
                  load_material_data[get_product_index].material_data = materialData;
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }
              }
              
            }else{
              item2.selected = false;
            }
            return true;
          })
        }
        return true;
      })
    })
    */
  }

  const handle_material_custom_color_input = (e) => {
    let index = e.currentTarget.getAttribute('data-index');
    let value = e.currentTarget.value;
    let subCatName = document.getElementById('sub_cat_name-'+index).value;
    let subCatId = index.split('-')[0];

    setSubcategory(oldValue=>{
      return oldValue.filter((item) => {
        if(item.subCatId == subCatId){
          return item.materials.filter((item2)=>{

            let load_material_data = JSON.parse(localStorage.getItem("material_data"));
            if(item2.index === index && item2.selected === false){
              item2.material_color = value;
              item2.material_color_name = value;
              
              // if (value.length == 7) {
                item2.selected = true;
                if(load_material_data === null || typeof load_material_data === "undefined"){
                  var new_material_data = [{
                    product_id,
                    material_data: [{index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0}]
                  }];
      
                  localStorage.setItem("material_data", JSON.stringify(new_material_data));
      
                  console.log(new_material_data);
                }else{
                  var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
                  if(get_product_index >= 0){
                    // console.log('exits');
                    let materialData = load_material_data[get_product_index].material_data;
                    if(materialData.length > 0){
                      let getSameMaterial = load_material_data[get_product_index].material_data.findIndex(el => el.subCatId == subCatId);
                      if(getSameMaterial >= 0){
                        load_material_data[get_product_index].material_data[getSameMaterial] = {index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0};
                      }else{
                        materialData.push({index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0});
                        load_material_data[get_product_index].material_data = materialData;
                      }
                      
                    }else{
                      load_material_data[get_product_index].material_data = [{index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0}];
                    }
                    localStorage.setItem("material_data", JSON.stringify(load_material_data));
                  }else{
                    var new_material_data = {
                      product_id,
                      material_data: [{index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0}]
                    };

                    load_material_data.push(new_material_data);
        
                    localStorage.setItem("material_data", JSON.stringify(load_material_data));
                  }
                }

              // }
              

            }
            else if(item2.index === index && item2.selected === true){
              item2.material_color = value;
              item2.material_color_name = value;
              
              // if (value.length == 7) {
                item2.selected = true;
                if(load_material_data === null || typeof load_material_data === "undefined"){
                  var new_material_data = [{
                    product_id,
                    material_data: [{index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0}]
                  }];
      
                  localStorage.setItem("material_data", JSON.stringify(new_material_data));
      
                  console.log(new_material_data);
                }else{
                  var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
                  if(get_product_index >= 0){
                    // console.log('exits');
                    let materialData = load_material_data[get_product_index].material_data;
                    if(materialData.length > 0){
                      let getSameMaterial = load_material_data[get_product_index].material_data.findIndex(el => el.subCatId == subCatId);
                      if(getSameMaterial >= 0){
                        load_material_data[get_product_index].material_data[getSameMaterial] = {index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0};
                      }else{
                        materialData.push({index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0});
                        load_material_data[get_product_index].material_data = materialData;
                      }
                      
                    }else{
                      load_material_data[get_product_index].material_data = [{index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0}];
                    }
                    localStorage.setItem("material_data", JSON.stringify(load_material_data));
                  }
                }

              // }
              
            }else{
              item2.selected = false;
            }
            
            return true;
          })
        }
        return true;
      })
    })
  }

  const handle_material_same_image = (e) => {

    let index = e.currentTarget.getAttribute('data-index');
    let value = 'same as image';
    let subCatName = document.getElementById('sub_cat_name-'+index).value;
    let subCatId = index.split('-')[0];

    

    setSubcategory(oldValue=>{
      return oldValue.filter((item) => {
        if(item.subCatId == subCatId){
          return item.materials.filter((item2)=>{

            let load_material_data = JSON.parse(localStorage.getItem("material_data"));
            if(item2.index === index && item2.selected === false){
                
                item2.selected = true;
                if(load_material_data === null || typeof load_material_data === "undefined"){
                  var new_material_data = [{
                    product_id,
                    material_data: [{index, subCatId, subCatName, color:value, name:value, customColor: 0, sameImage: 1}]
                  }];
      
                  localStorage.setItem("material_data", JSON.stringify(new_material_data));
      
                  console.log(new_material_data);
                }else{
                  var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
                  if(get_product_index >= 0){
                    // console.log('exits');
                    let materialData = load_material_data[get_product_index].material_data;
                    if(materialData.length > 0){
                      let getSameMaterial = load_material_data[get_product_index].material_data.findIndex(el => el.subCatId == subCatId);
                      if(getSameMaterial >= 0){
                        load_material_data[get_product_index].material_data[getSameMaterial] = {index, subCatId, subCatName, color:value, name:value, customColor: 0, sameImage: 1};
                      }else{
                        materialData.push({index, subCatId, subCatName, color:value, name:value, customColor: 1, sameImage: 0});
                        load_material_data[get_product_index].material_data = materialData;
                      }
                      
                    }else{
                      load_material_data[get_product_index].material_data = [{index, subCatId, subCatName, color:value, name:value, customColor: 0, sameImage: 1}];
                    }
                    localStorage.setItem("material_data", JSON.stringify(load_material_data));
                  }else{
                    var new_material_data = {
                      product_id,
                      material_data: [{index, subCatId, subCatName, color:value, name:value, customColor: 0, sameImage: 1}]
                    };
        
                    load_material_data.push(new_material_data);

                    localStorage.setItem("material_data", JSON.stringify(load_material_data));
                  }
                }

              
              

            }else if(item2.index === index && item2.selected === true){
              item2.selected = false;
              item2.material_color = '';
              item2.material_color_name = '';
              var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
              if(get_product_index >= 0){
                let materialData = load_material_data[get_product_index].material_data.filter(item => item.index !== index);
                // console.log(materialData);
                if(materialData.length == 0){
                  let products = load_material_data.filter(el => el.product_id != product_id);
                  if(products.length == 0){
                    localStorage.removeItem("material_data");
                  }else{
                    load_material_data = products;
                  }
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }else{
                  load_material_data[get_product_index].material_data = materialData;
                  localStorage.setItem("material_data", JSON.stringify(load_material_data));
                }
              }
              
            }else{
              item2.selected = false;
            }
            return true;
          })
        }
        return true;
      })
    })

  }

  const gotoStep2 = () =>{
    navigate('/projects/add-new/step-2');
  }

  return (
    <div>
      {isLoading ? <LoadingSpinner/> : ""}
      <h1 style={{fontWeight:"bold", fontSize:"22px"}}>Add material for project</h1>
      <h1 style={{fontWeight:"bold", marginTop:"15px"}}>Material</h1>
      <div className="add_product_category_box">
        {collections.map((collection) => (
          <span className="item" key={collection.id} id={collection.id} onClick={handle_category_item}>
            {collection.divider !== '' ? <span className="divider">{collection.divider}</span> : ''}
            <span className={'collection_name ' + collection.active} id={collection.id}>{collection.categoryName}</span>
          </span>
        ))}
      </div>
      {subcategory.map((subcategory,key)=>(
        <>
          <h3 style={{fontWeight:'bold', marginTop:'15px'}}>{subcategory.subCatName}</h3>
          <div className="material_data">
            <div className="melamine_box">
              <div className="material_data_box">
                {subcategory.materials.map((material, key)=>(
                  <>
                    {material.isCustom == 0 && material.same_as_image == 0 ?
                    <div className={material.selected ? 'material_data_box_item active':'material_data_box_item'} data-index={material.index} key={key} onClick={handle_material_data_item}>
                      <div className="material_data_box_item_image">
                        <img src={material.material_color}/>
                        <input type="hidden" value={material.material_color} id={"material_color-"+material.index}/>
                      </div>
                      <div className="material_data_box_item_name">
                        <h1>{material.material_color_name}</h1>
                        <input type="hidden" value={material.material_color_name} id={"material_color_name-"+material.index}/>
                        <input type="hidden" value={subcategory.subCatName} id={"sub_cat_name-"+material.index}/>
                      </div>
                    </div>
                    : ''}
                    {material.isCustom == 1 ?
                    <div className={material.selected ? 'material_data_box_item custom_color active':'material_data_box_item custom_color'}>
                      {/* <input type="color" value={material.material_color} id={"color_"+material.index} data-index={material.index} onChange={handle_material_custom_color}/> */}
                      <img src={CustomColor}/>
                      <input type="text" value={material.material_color_name} id={"color_input_"+material.index} data-index={material.index} onChange={handle_material_custom_color_input} placeholder="enter color code"/>
                      <input type="hidden" value={subcategory.subCatName} id={"sub_cat_name-"+material.index}/>
                    </div>
                    : ''}
                    {material.same_as_image == 1 ?
                    <div className={material.selected ? 'material_data_box_item same_as_image active':'material_data_box_item same_as_image'} data-index={material.index} key={key} onClick={handle_material_same_image}>
                      <h1>Save as the picture product</h1>
                      <input type="hidden" value={subcategory.subCatName} id={"sub_cat_name-"+material.index}/>
                    </div>
                    : ''}
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      ))}
      <div className="text-center mt-10">
        <button
          onClick={gotoStep2}
          type="submit"
          className="btn-blue px-10"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default ProjectMaterialSingle;
