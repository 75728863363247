import axios from "axios";
import React, {useState} from "react";
import { FaBell, FaCog, FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../components/services";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import LoadingSpinner from "../../components/Loading_spinner";

const AddNewUser = () => {
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = React.useState(null);

  const navigate = useNavigate();

  const handleLogoUpload = (e) => {
    const payload = new FormData();
    payload.append("image", e.target.files[0]);

    axios
      .post(
        "https://api.imgbb.com/1/upload?key=bcc56bad76ece711720725d2a509d3dc",
        payload
      )
      .then((response) => {
        // console.log("response", response);
        // console.log("response url", response.data.data.image.url);
        setLogoUrl(response.data.data.image.url);
        // console.log("success");
      })
      .catch((error) => {
        console.log("error", error);
        alert("try another image agian");
      });
    // imgbbUploader()
  };
  const handleAddNewSupplier = (e) => {
    e.preventDefault();

    const form = e.target;

    const logo = logoUrl;
    const userName = form.userName.value;
    const firstName = form.firstName.value;
    const website = form.website.value;
    const email = form.email.value;
    const lastName = form.lastName.value;
    const password = form.password.value;
    const role = form.role.value;

    const data = {
      logo,
      userName,
      firstName,
      lastName,
      website,
      email,
      password,
      role,
    };
    
    setLoading(true);
    fetch(services.url+"/add_new_user.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "User has been added successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
              navigate('/users/all');
          }
          
        });
      });
      
  };
  return (
    <div>
      <TopBar title="Add New User"/>
      
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Supplier / Factory Details</p>
        </div>
        <form
          onSubmit={handleAddNewSupplier}
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          <div className="flex md:flex-row flex-col gap-5 justify-between items-start">
            <div className="lg:w-[2/12] md:w-1/2 w-full">
              <p className="text-blue font-bold">Photo *</p>
              {logoUrl && (
                <img
                  src={logoUrl}
                  alt="logo"
                  className="w-full h-auto rounded-full"
                />
              )}
              <label
                htmlFor="logo"
                className="logo-uploader block text-center mt-4"
              >
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  onChange={handleLogoUpload}
                />
                <span className="text-sm text-gray-400">
                  Drag and drop or click here to select file
                </span>
              </label>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">Username *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Samantha"
                  name="userName"
                  required
                  
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">First Name*</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Jakarta"
                  name="firstName"
                  required
                  
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Website *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Website"
                  name="website"
                  required
                  
                />
              </div>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">Email *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="william@mail.com"
                  name="email"
                  required
                  
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Last Name *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Mana William"
                  name="lastName"
                  required
                  
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Password *</p>
                <input
                  type="password"
                  className="w-full p-2 border rounded"
                  placeholder="Password"
                  name="password"
                  required
                  
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Role *</p>
                {/* <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Province "
                  name="province"
                  required
                  id=""
                /> */}
                <select name="role" className="w-full p-2 border rounded" id="">
                  <option value="Admin">Administrator</option>
                  <option value="Supplier">Supplier</option>
                </select>
              </div>
            </div>
          </div>

          <div className="text-right mt-7">
            <button
              type="submit"
              className="btn-blue bg-[#4D43B5] text-white px-8 py-2 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <Footer/>
      {loading ? <LoadingSpinner/> : ''}
    </div>
  );
};

export default AddNewUser;
