/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaChevronRight, FaEllipsisH } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import services from "../services";



const CollectionCard = ({ customer, deleteSubcategory }) => {
  console.log(customer);

  const navigate = useNavigate();

  const editCollection = (id) => {
    navigate('/product/categories/'+id+'/edit');
  }

  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <a target="_blank" rel="noopener noreferrer" href="/">
    //       View
    //     </a>
    //   ),
    // },
    {
      key: "1",
      label: 'Edit Collection',
      onClick: () => editCollection(customer.category_id),
    },
    {
      key: "2",
      danger: true,
      label: "Delete",
      onClick: () => deleteSubcategory(customer.id),
    },
  ];

  return (
    <div className="p-[20px] bg-white rounded-[10px] text-center">
      <div className="text-right">
        {/* <button className="btn">
          <FaEllipsisH className="text-[#a098ae]" />
        </button> */}
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()} style={{cursor:'pointer'}}>
            <FaEllipsisH className="text-[#a098ae] ml-auto block" />
          </a>
        </Dropdown>
      </div>
      <div className="content-image-box">
      <img
        src={services.url+'subcategory_images/'+ customer.image}
        className="block mx-auto mb-5 w-[80px]"
        alt=""
      />
      </div>
      <p className="text-blue text-lg font-bold">{customer.name}</p>
      
      <div className="links w-full mt-5 flex gap-4 justify-center items-center">
        <Link
          to={"/products/collection/" + customer.id}
          className="customer-link flex rounded-full justify-center items-center w-[40px] h-[40px] bg-[#4d44b5] text-white"
        >
          <FaChevronRight />
        </Link>
      </div>
    </div>
  );
};

export default CollectionCard;
