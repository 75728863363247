import React, {useEffect, useState} from "react";
import { FaUpload, FaTimes } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../components/services";
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const CustomProduct = () => {

  const [isLoading, setLoading] = React.useState(false);
  const [customProduct, setCustomProduct] = useState([]);

  const navigate = useNavigate();
  // localStorage.removeItem("custom_product");

  useEffect(() => {

    let load_custom_product_data = JSON.parse(localStorage.getItem("custom_product"));
    if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

    }else{
      
      let project_object = [];
      load_custom_product_data.forEach(val=>{
        project_object.push(val);
      })
      
      setCustomProduct(project_object);
    }
  },[]);

  const handleCustomProductFile = (ele) => {
    
    if(ele.target.files.length === 0) {

    }else{
      setLoading(true);
      let fileObject = ele.target.files[0];

      const formData = new FormData();
      formData.append('file', fileObject);

      fetch(services.url+"/upload_custom_product_file.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLoading(false);
        let load_custom_product_data = JSON.parse(localStorage.getItem("custom_product"));
        if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          let new_product = {
            index: 1,
            original_file_name: res.original_file_name,
            file: res.file_name,
            model: '',
            dimension: '',
            material: '',
            color: '',
            qty: 1,
            note: '',
            cbm: '',
            unitPrice: '',
            totalPrice: '',
            commentaire: ''
          }
          let new_custom_product = [];
          new_custom_product.push(new_product);
          
          setCustomProduct([...customProduct, new_product]);

          localStorage.setItem("custom_product", JSON.stringify(new_custom_product));

        }else{

          let lastElement = load_custom_product_data[load_custom_product_data.length - 1];
          let new_index = parseInt(lastElement.index) + parseInt(1);
          
          let new_custom_product = {
            index: new_index,
            original_file_name: res.original_file_name,
            file: res.file_name,
            model: '',
            dimension: '',
            material: '',
            color: '',
            qty: 1,
            note: '',
            cbm: '',
            unitPrice: '',
            totalPrice: '',
            commentaire: ''
          }

          setCustomProduct([...customProduct, new_custom_product]);

          load_custom_product_data.push(new_custom_product);

          localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
          
        }
      });

    }

    

    // setTimeout(() => {
    //   console.log(JSON.parse(localStorage.getItem("custom_product")));
    // }, 100);
   
  }

  // const uploadCustomFile = (e) => {
  //   var newFile = document.getElementById('new_file').files[0];
  //   console.log(newFile);
  // }

  const handleRemoveCustomProduct = (index, file) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      
      if (result.isConfirmed) {
        
        setLoading(true);
        
        const formData = new FormData();
        formData.append('file', file);

        fetch(services.url+"/remove_custom_product_file.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: formData,
        })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          
          setCustomProduct(oldValue=>{
            return oldValue.filter((item) => item.index != index);
          })
          
  
          let load_custom_product_data = JSON.parse(localStorage.getItem("custom_product"));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){
  
          }else{
  
            var get_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_index >=0){
              var new_array = load_custom_product_data.filter((item) => item.index !== index);
              if(new_array.length == 0){
                localStorage.removeItem("custom_product");
              }else{
                localStorage.setItem("custom_product", JSON.stringify(new_array));
              }
            }
          }
        });
        
      }
    });


  }

  const add_custom_product = () =>{
    navigate('/projects/add-new/step-2');
  }

  return (
    <div>
      <form action="">
        <div className="custom_product_box mt-5">
          <div className="file_input_box">
            <input type="file" name="file" id="file" onChange={handleCustomProductFile} />
            <label
              htmlFor="file"
              className="text-center inline-block p-3 border-2 border-dashed border-black"
            >
              <FaUpload className="block mx-auto mb-3" />
              <span>Upload File Technique</span> <br />
              <span className="text-xs text-gray-300">
                pdf, jpg, png, psg, dxf, dwg, skp, cad
              </span>
            </label>
          </div>
          <div className="display_custom_product_file_name">
            {customProduct.map((customProduct, key)=>(
              <div className="custom_product_file_name" key={key}>
                <span onClick={()=>handleRemoveCustomProduct(customProduct.index, customProduct.file)} className="custom_product_file_close"><FaTimes/></span>
                {customProduct.original_file_name}
              </div>
            ))}
          </div>
          {/* <input type="file" id="new_file"/>
          <button type="button" onClick={uploadCustomFile}>Upload</button> */}
        </div>
        

        <div className="mt-10">
          <label htmlFor="">Write Extra Note (optional)</label>
          <textarea
            name=""
            placeholder="Write Extra Note"
            id=""
            cols="30"
            rows="6"
            className="border border-[#4d44b5] rounded-md w-full p-[10px] mt-[10px]"
          ></textarea>
        </div>

        <div className="mt-10 text-center">
          <button type="button" onClick={add_custom_product} className="btn-blue px-10 text-lg">ADD</button>
        </div>
      </form>
      {isLoading ? <LoadingSpinner/> : ""}
    </div>
  );
};

export default CustomProduct;
