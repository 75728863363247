import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return(
        <div className="footer">
            Developed by <Link target="_blank" className="link-developed-by" to="https://happycoddingit.com/">Happy Codding IT</Link>
        </div>
    )
}

export default Footer;