import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import material1 from "../../assets/img/material1.png";
import material2 from "../../assets/img/material2.png";
import material3 from "../../assets/img/material3.png";
import material4 from "../../assets/img/material4.png";
import MaterialDetails from "../../components/material-details";
import services from "../../components/services";
import LoadingSpinner from "../../components/Loading_spinner";
import Spinner from "../../components/spinner";
import Pagination from "../../components/pagination";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const MaterialsDetails = () => {
  const detailsData = [
    {
      id: 1,
      name: "Melamine",
      image: material1,
      tag: "Wood",
    },
    {
      id: 2,
      name: "Veneer",
      image: material2,
      tag: "Wood",
    },
    {
      id: 3,
      name: "Pinewood",
      image: material3,
      tag: "Silence Panel",
    },
    {
      id: 4,
      name: "MDF",
      image: material4,
      tag: "Aluminium",
    },
  ];

  const [categories, setCategories] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);
  const [catId, setCatId] = useState('all');
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [execute, setExecute] = useState(false);
  const [materialsFound, setMaterialsFound] = useState(false);

  const {id} = useParams();

  const [filteredCollection, setFilteredCollection] =
    React.useState(detailsData);

  const getMaterials = (supplier, category='all') => {
  
    fetch(services.url+"/get_materials2.php?supplier="+supplier+"&category_id="+category)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setExecute(true);
        setLoading(false);
        setSearchLoading(false);
        setMaterials(res.materials);
        if(res.found === 1){
          setMaterialsFound(true);
        }else{
          setMaterialsFound(false);
        }
        // console.log(subcategory);
      });
    
  }

  useEffect(() => {

    let data = {supplier_id:id};

    fetch(services.url+"/get_material_category2.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setCategories(res.materials_categories);
        // console.log(subcategory);
      });
      // setLoading(true);
      getMaterials(id);

  }, []);

  const handelCategory = (e) => {
    let cat_id = e.currentTarget.id;
    // alert(cat_id);
    const filterBtn = document.querySelectorAll(".filterBtn");
    filterBtn.forEach((btn) => btn.classList.remove("active"));
    e.currentTarget.classList.add("active");
    setCatId(cat_id);
    setExecute(false);
    setLoading(true);
    
    getMaterials(id, cat_id);

  }




  return (
    <div className="customers">
      <TopBar title="Materials"/>
      
      <div className="filters mb-10 flex flex-wrap justify-center items-center gap-[15px]">
        <button className="btn filterBtn active" onClick={handelCategory} id="all">All</button>
        {categories.map(categorie=>(
          <div key={categorie.id}>
          <span className="text-blue">|</span>
          <button className="btn filterBtn" onClick={handelCategory} id={categorie.id}>{categorie.categoryName}</button>
          </div>
        ))}
      </div>
      
      {execute ?
      materialsFound ?
       <MaterialDetails collections={materials} />
       :
        <div className="not-found-text">Data not found!</div>
        : ''}
      
      {/* <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} start={start} end={end} totalData={dataLength} onPageChange={handlePageChange}/> */}
      {loading ? <LoadingSpinner/> : ""}

      {/* {filteredCollection.length === 0 ? (
        "No Data Found"
      ) : (
        <MaterialDetails collections={filteredCollection} />
      )} */}
      <Footer/>
    </div>
  );
};

export default MaterialsDetails;
