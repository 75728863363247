import React, {useState} from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import AllSupplier from "../../components/all-suppliers";
import Spinner from "../../components/spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const Supplier = () => {

  const [searchText, setSearchText] = useState(null);

  return (
    <div className="customers">
      <TopBar title="Supplier / Factory"/>
      
      <AllSupplier />
      <Footer/>
    </div>
  );
};

export default Supplier;
