import React, {useEffect, useRef, useState} from "react";
import { FaBell, FaCog, FaSearch, FaUser, FaSignOutAlt, FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import services from "../services";
import { format } from 'date-fns';

const TopBar = ({title,isSearch="false", subtitle={}, userNav=true}) => {

    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [userLogo, setUserLogo] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [showSideBar, setShowSideBar] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [newNotification, setNewNotification] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const navigate = useNavigate();

    let userMenuRef = useRef();
    let notificationMenuRef = useRef();

    

    window.addEventListener('resize', function(event) {
        if (window.matchMedia('screen and (max-width: 1023px)').matches) {
            setShowSideBar(false);
            setIsMobile(true);
        }else{
            setShowSideBar(true);
            setIsMobile(false);
        }
    }, true);

    if(userNav){
        setTimeout(() => {
            let user_role = localStorage.getItem("sensar_user_role");
            let user_role_id = localStorage.getItem("sensar_user_role_id");
            
    
            fetch(services.url+'/get_notification.php?user_role='+user_role+'&user_role_id='+user_role_id)
            .then(res=>res.json())
            .then((res)=>{
                // console.log(res);
                setNewNotification(res.new_notification_count);
                setNotifications(res.notifications);
            });
        }, 5000);
    }

    useEffect(() =>{
        // console.log(Object.keys(subtitle).length);
        // console.log(subtitle.customerName);

        if (window.matchMedia('screen and (max-width: 1023px)').matches) {
            setIsMobile(true);
            setShowSideBar(false);
        }else{
            setIsMobile(false);
            setShowSideBar(true);
            
        }

        setUserId(localStorage.getItem("sensar_user_id"));
        setUserLogo(localStorage.getItem("sensar_user_logo"));
        setUserFirstName(localStorage.getItem("sensar_user_first_name"));
        setUserLastName(localStorage.getItem("sensar_user_last_name"));
        setUserName(localStorage.getItem("sensar_user_name"));
        setUserRole(localStorage.getItem("sensar_user_role"));
        

        let handler = (e) => {
            if(!userMenuRef.current.contains(e.target)){
                let dropdowns = document.getElementsByClassName("dropdown-content");
                let i;
                for (i = 0; i < dropdowns.length; i++) {
                    let openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }

        let handlerNotification = (e) => {
            if(!notificationMenuRef.current.contains(e.target)){
                let dropdowns = document.getElementsByClassName("dropdown-notification-content");
                let i;
                for (i = 0; i < dropdowns.length; i++) {
                    let openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }

        
        if(userNav){
            document.addEventListener("mousedown", handler);
            document.addEventListener("mousedown", handlerNotification);
        }
        

        return() =>{
            if(userNav){
                document.removeEventListener("mousedown", handler);
                document.removeEventListener("mousedown", handlerNotification);
            }
            
        }

        
    }, [])

    const handleMenuBar = () =>{
        setShowSideBar(true);
    }

    const handleUserDropdown = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    }

    const handleNotificationDropdown = () => {

        let user_role = localStorage.getItem("sensar_user_role");
        let user_role_id = localStorage.getItem("sensar_user_role_id");
        

        fetch(services.url+'/hit_notification.php?user_role='+user_role+'&user_role_id='+user_role_id)
        .then(res=>res.json())
        .then((res)=>{
            setNewNotification(0);
        });

        document.getElementById("myNotificationDropdown").classList.toggle("show");
    }

    // window.addEventListener('click', function(e){   
    //     if (document.getElementById('user-dropdown').contains(e.target)){
    //         // Clicked in box
    //     } else{
    //         // Clicked outside the box
    //         let dropdowns = document.getElementsByClassName("dropdown-content");
    //         let i;
    //         for (i = 0; i < dropdowns.length; i++) {
    //             let openDropdown = dropdowns[i];
    //             if (openDropdown.classList.contains('show')) {
    //                 openDropdown.classList.remove('show');
    //             }
    //         }
    //     }
    // });

    const handleLogout = () => {
        localStorage.setItem("login", '');
        setTimeout(() => {
            localStorage.clear();
            navigate("/");
        }, 100);
        
    }
    return (
        // <div className="md:flex hidden justify-between items-center">
        <div className="top-bar flex justify-between items-center">
            <div className="top-title">
                <h2 className={showSideBar?`text-blue text-3xl font-bold`:`text-blue text-3xl font-bold title-margin`}>{title}</h2>
                {Object.keys(subtitle).length > 0 ?
                 <>
                 <span>{subtitle.customerName}/{format(subtitle.processDate, 'MMMM dd, yyyy')}</span>
                 
                {subtitle.new_request == 1 ?
                  <div className="status-btn">
                    <button style={{border:'1px solid orange', padding:'0px 5px', backgroundColor:'orange'}}>Not yet view</button>
                    <button style={{border:'1px solid grey', color:'#000', padding:'0px 5px'}}>Update from supplier</button>
                    </div>
                   : 
                   ''
                   }

                {subtitle.new_request == 0 && subtitle.validation == 0 ?
                  <div className="status-btn">
                    <button style={{border:'1px solid grey', padding:'0px 5px'}}>Not yet view</button>
                    <button style={{border:'1px solid green', backgroundColor:'green', color:'#fff', padding:'0px 5px'}}>Update from supplier</button>
                  </div>
                   : ''}
                
                {subtitle.new_request == 0 && subtitle.validation == 1 && subtitle.paid == 0 ?
                  <div className="status-btn">
                    {/* <button style={{border:'1px solid grey', padding:'0px 5px'}}>Not yet view</button> */}
                    <button style={{border:'1px solid green', backgroundColor:'green', color:'#fff', padding:'0px 5px'}}>Update from supplier</button>
                  </div>
                   : ''}

                {subtitle.validation == 1 && subtitle.paid == 1 ?
                  <div className="status-btn">
                    <button style={{border:'1px solid green', backgroundColor:'green', color:'#fff', padding:'0px 5px'}}>Paid</button>
                  </div>
                   : 
                   ''
                   }
                
                {subtitle.validation == 1 && subtitle.due == 1 ?
                    <div className="status-btn">
                        <button style={{border:'1px solid orange', backgroundColor:'orange', color:'#fff', padding:'0px 5px'}}>Not yet paid</button>
                    </div>
                   : 
                   ''
                   }
                 </>
                  : ''}
                  
            </div>
            
            <div className="actions text-right flex justify-end items-center gap-8">
                {isSearch === "true" ?
                <form
                action=""
                className="search flex justify-between items-center gap-3 bg-white px-6 py-3 rounded-full"
                >
                    <FaSearch className="text-blue" />
                    <input type="text" className="w-[200px]" name="" id="" />
                </form>
                : ''}
                {userNav ?
                 <>
                    <button onClick={handleNotificationDropdown} ref={notificationMenuRef} className="bell-btn btn relative action-btn w-[50px] h-[50px] flex justify-center items-center text-[#a098ae] bg-white rounded-full">
                        <FaBell className="text-2xl" />
                        {/* <div className="status bg-[#4d44b5] w-[8px] h-[8px] rounded-full absolute top-[10px] right-[10px]">1</div> */}
                        {newNotification == 0 ? ''
                        :
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {newNotification}
                            <span class="visually-hidden">unread messages</span>
                        </span>
                        }
                        
                        <div id="myNotificationDropdown" className="dropdown-notification-content">
                            {notifications.map((notification,key)=>(
                                <Link className="dropdown-notification-item" to={notification.type === 'New Project Quote' ? '/projects/supplier' : '/dashboard'}>
                                    <div className="notification-title">
                                    <h2>{notification.title}</h2>
                                    </div>
                                    <div className="notification-details">
                                    <p>{notification.details}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </button>
                    {/* <Link
                        to={"/settings"}
                        className="setting-btn btn relative action-btn w-[50px] h-[50px] flex justify-center items-center text-[#a098ae] bg-white rounded-full"
                    >
                        <FaCog className="text-2xl" />
                    </Link>
                    */}
                    {/* <Link
                        to={"/profile"}
                        className="flex justify-end gap-4 text-end items-center"
                    > */}
                    <div id="user-dropdown" ref={userMenuRef} onClick={handleUserDropdown}>
                        {/* <p className="text-blue text-lg font-bold">Nawfel M.</p>
                        <p className="text-sm pinkish">Admin</p> */}
                        <div>
                            <p className="text-blue text-lg font-bold">{userFirstName}</p>
                            <p className="text-sm pinkish">{userRole}</p>
                        </div>
                        <div className="user-logo w-[50px] h-[50px] flex justify-center items-center text-[#a098ae] bg-white rounded-full">
                            {/* <FaUser className="text-2xl" /> */}
                            <img src={userLogo} />
                        </div>
                        <div id="myDropdown" className="dropdown-content">
                            <Link className="dropdown-item" to="/users/profile">
                            <FaUser/>
                            <span>Profile</span>
                            </Link>
                            <div className="logout-btn" onClick={handleLogout}>
                            <FaSignOutAlt/>
                            <span>Logout</span>
                            </div>
                        </div>
                    </div>
                    {/* </Link> */}
                 </>
                  : ''}
                
            </div>
        </div>
    )
}

export default TopBar;