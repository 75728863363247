import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaUser, FaLock } from "react-icons/fa";
import services from "../../components/services";
import Spinner from "../../components/spinner";

const Login = () => {
  
    const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [msg, setMsg] = useState('');
    const [errorUser, setErrorUser] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let login = localStorage.getItem("sensar_login");
        if(login){
            navigate('/dashboard');
        }
        setTimeout(() => {
            setMsg('');
        }, 5000);
    }, [msg]);

    const handleInputChange = (e, type) => {
        switch(type){
            case "user":
                setErrorUser("");
                setUser(e.target.value);
                if(e.target.value === ''){
                    setErrorUser("Username has left blank");
                }else if(!e.target.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                    setErrorUser("Email is not valid!");
                }
            break;
            case "password":
                setErrorPassword("");
                setPassword(e.target.value);
                if(e.target.value === ''){
                    setErrorPassword("Password has left blank");
                }
            break;
            default:
        }
    }

    const handleLogin = (e) => {
        e.preventDefault();

        if(user === "" || password === ""){
            setMsg("");
            setError("All field are required!");
        }else if(errorUser !== "" || errorPassword !== ""){
            setMsg("");
            setError("Please enter valid username and password.");
        }else{
            setError("");
            let url = services.url+"/login.php";
            let headers = {
                "Accept": "application/json",
                "Content-type": "application/json"
            }
            let data = {
                user: user,
                password: password
            }
            setLoading(true);
            fetch(url, {
                method:"POST",
                headers:headers,
                body: JSON.stringify(data)
            }).then((response)=>response.json())
            .then((response)=>{
                setLoading(false);
                if(response[0].result === "Invalid username!" || response[0].result === "Invalid password!"){
                    setError(response[0].result);
                }else{
                    setMsg(response[0].result);
                    setTimeout(() => {
                        localStorage.setItem("sensar_login", true);
                        localStorage.setItem("sensar_user_id", response[0].userId);
                        localStorage.setItem("sensar_user_logo", response[0].userLogo);
                        localStorage.setItem("sensar_user_first_name", response[0].userFirstName);
                        localStorage.setItem("sensar_user_last_name", response[0].userLastName);
                        localStorage.setItem("sensar_user_name", response[0].userName);
                        localStorage.setItem("sensar_user_role", response[0].userRole);
                        localStorage.setItem("sensar_user_role_id", response[0].roleId);
                        navigate("/dashboard");
                    }, 1000);
                }
                
            }).catch((err)=>{
                setError(err);
                console.log(err);
            })
        }
    }

    return (
    <section className="main-login-section">
        <div className="container-fluid">
            <div className="row">
                <div className="company-logo-area col-lg-6 col-md-6 col-12">
                    <img className="login-logo" src={logo} alt="logo"/>
                    <h1 className="login-heading">Dashboard</h1>
                    <p className="adimn-text">Admin</p>
                </div>
                <div className="login-form-area col-lg-6 col-md-6 col-12">
                    <h1 className="sign-in-text">Sign In</h1>
                    <form method="POST" id="submitForm" onSubmit={handleLogin} action="">
                        <p className="msg">
                            {
                                error !== "" ? 
                                <span className="error">{error}</span> :
                                <span className="success">{msg}</span>
                            }
                        </p>
                        <div className="s-form-group">
                            <input className="inputBox form-control" type="text" placeholder="Email" name="email" autoComplete="email" onChange={(e)=>handleInputChange(e, "user")}/>
                            <i className="s-icon"><FaUser/></i>
                            <span className="validation-error">
                                {errorUser !== "" ? errorUser : ''}
                            </span>
                        </div>
                        <div className="s-form-group">
                            <input className="inputBox form-control" type="password" placeholder="Password" name="password" autoComplete="current-password" value={password} onChange={(e)=>handleInputChange(e, "password")}/>
                            <i className="s-icon"><FaLock/></i>
                            <span className="validation-error">
                                {errorPassword !== "" ? errorPassword : ''}
                            </span>
                        </div>
                        <div className="flex-box-seven">
                            <div className="s-form-group">
                                <input className="input-checkbox" type="checkbox" id="remember" name="remember"/>
                                <label htmlFor="remember">Remember me</label>
                            </div>
                            <div className="s-form-group">
                                Forgot Your Password?
                            </div>
                        </div>
                        <div className="s-form-group">
                            <button className="s-submit-button" id="submitBtn" type="submit">
                                {
                                    loading ?
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    :
                                    <span>Sign In</span>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Login;
