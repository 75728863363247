import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaUser, FaTrash, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../components/services";
import '../../assets/css/add_product.css';
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import axios from "axios";

const AddNewProductMultiple = () => {

  const [logoUrl, setLogoUrl] = React.useState(null);
  const [collections, setCollections] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [fileUrl, setFile] = React.useState(null);
  const [measurement, setMeasurement] = React.useState([]);

  function handleFileChange(e) {
      // console.log(e.target.files);
      // setFile(URL.createObjectURL(e.target.files[0]));
      const payload = new FormData();
      payload.append("image", e.target.files[0]);

      axios
        .post(
          "https://api.imgbb.com/1/upload?key=bcc56bad76ece711720725d2a509d3dc",
          payload
        )
        .then((response) => {
          // console.log("response", response);
          // console.log("response url", response.data.data.image.url);
          setLogoUrl(response.data.data.image.url);
          // console.log("success");
        })
        .catch((error) => {
          console.log("error", error);
          alert("try another image agian");
        });
  }

  const handleAddNewProduduct = (e) => {
    e.preventDefault();

    const form = e.target;

    const excel = form.excel.files[0];
    const supplier = form.supplier.value;
    const collection = form.collection.value;
    const subcategory = form.subcategory.value;
    
    

    // if(typeof logo === 'undefined'){
    //   console.log('no logo');
    // }else{
    //   console.log('logo');
    // }

    /*
    const data = {
      logo,
      productName,
      reference,
      supplier,
      collection,
      measurement,
      price,
    };
     console.log(data);
    */
    
    const formData = new FormData();
    formData.append('excel', excel);
    formData.append('supplier', supplier);
    formData.append('collection', collection);
    formData.append('subcategory', subcategory);
    
    
    if(typeof excel === 'undefined'){
      
      alert('Please upload a valid excel file');

    }else{
      setLoading(true);
      fetch(services.url+"/create_product_multiple.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setLoading(false);
          
          Swal.fire({
            title: "Success!",
            text: "Product has been added successfully!",
            icon: "success",
            confirmButtonText: "Okay",
          });
          form.reset();
          setLogoUrl(null);
          setMeasurement([]);
        });
    }
    
    
  };

  // useEffect(() => {
  //   fetch(services.url+"/get_categories.php", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((res) => res.json())

  //     .then((res) => {
  //       console.log(res);
  //       setCollections(res);
  //     });
  // }, []);

  useEffect(() => {
    fetch(services.url+"/get_suppliers.php", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((res) => {
        setSuppliers(res);
      });
  }, []);

  const getCategory = (supplier_id) => {
    
    if(supplier_id !== ''){
      setLoading(true);
      let data = {supplier_id};
      fetch(services.url+"/get_category2.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res);
          setLoading(false);
          setCollections(res.categories);
          // console.log(subcategory);
        });
    }else{
      setCollections([]);
      setSubcategory([]);
    }
    
  }

  const getSubcategory = (category_id) => {
    
    if(category_id !== ''){
      setLoading(true);
      let data = {category_id};
      fetch(services.url+"/get_subcategory.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res);
          setLoading(false);
          setSubcategory(res);
          // console.log(subcategory);
        });
    }else{
      setSubcategory([]);
    }
    
  }


  return (
    <div>
      <TopBar title="Add Multiple Product"/>
      
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Product Details</p>
        </div>
        <form
          onSubmit={handleAddNewProduduct}
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          <div className="flex md:flex-row flex-col gap-5 justify-between items-start">
            <div className="lg:w-[2/12] md:w-1/2 w-full">
              <p className="text-blue font-bold">Import Excel *</p>
              <label
                htmlFor="excel"
                className="logo-uploader block text-center mt-4"
              >
                <input
                  type="file"
                  name="excel"
                  id="excel"
                  accept=".xlsx, .xls, .csv"
                />
                <span className="text-sm text-gray-400">
                  Drag and drop or click here to select file
                </span>
              </label>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">Supplier *</p>
                <select
                  name="supplier"
                  className="w-full p-2 border rounded"
                  id="" onChange={(e)=>getCategory(e.target.value)}
                  required
                >
                  <option value="">--Select One--</option>
                  {suppliers.map((supplier) => (
                    <option value={supplier.id}>{supplier.companyName}</option>
                  ))}
                </select>
                <Link
                  to={"/supplier/add-new"}
                  className="btn-blue flex justify-center items-center gap-2 px-5 py-2" style={{borderRadius:'0px'}}
                >
                  <FaPlus /> <span>Add Supplier</span>
                </Link>
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Collection *</p>
                <select
                  name="collection"
                  className="w-full p-2 border rounded"
                  id="" onChange={(e)=>getSubcategory(e.target.value)}
                  required
                >
                  <option value="">--Select One--</option>
                  {collections.map((collection) => (
                    <option value={collection.id}>
                      {collection.categoryName}
                    </option>
                  ))}
                </select>
                <Link
                  to={"/product/categories/add-new"}
                  className="btn-blue flex justify-center items-center gap-2 px-5 py-2" style={{borderRadius:'0px'}}
                >
                  <FaPlus /> <span>Add Collection</span>
                </Link>
              </div>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">Sub Category *</p>
                <select
                  name="subcategory"
                  className="w-full p-2 border rounded"
                  id=""
                  required
                >
                  <option value="">--Select One--</option>
                  {subcategory.map((subcategory) => (
                    <option value={subcategory.subcategory_id}>
                      {subcategory.subcategory_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {isLoading ? <LoadingSpinner/> : ""}
          <div className="mt-5 text-center">
            <button type="submit" className="btn-blue px-10">
              Save
            </button>
          </div>
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default AddNewProductMultiple;
