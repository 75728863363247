import React, { useRef, useEffect, useState } from "react";
import { FaBell, FaCog, FaUser, FaCalendarAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../components/services";
import '../../assets/css/add_project.css';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

function StartDate({value,onClick}){
  return (
    <div className="input-group">
        <input type="text" placeholder="Select Start Date" className="form-control" value={value} onClick={onClick} readOnly/>
        <div className="input-group-append" onClick={onClick}>
            <span className="input-group-text">
                <FaCalendarAlt/>
            </span>
        </div>
    </div>
  )
}

function EndDate({value,onClick}){
  return (
    <div className="input-group">
        <input type="text" placeholder="Select End Date" className="form-control" value={value} onClick={onClick} readOnly/>
        <div className="input-group-append" onClick={onClick}>
            <span className="input-group-text">
                <FaCalendarAlt/>
            </span>
        </div>
    </div>
  )
}

const AddNewProject = () => {
  // const navigate = useNavigate();
  // const gotoStep2 = () => {
  //   navigate("/projects/add-new/step-2");
  // };

  localStorage.removeItem("material_data");
  localStorage.removeItem('sub_categories')

  const navigate = useNavigate();

  const [companies, setCompanies] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    fetch(services.url+"/get_customer.php", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((companies) => {
        setCompanies(companies);
      });
  }, []);

  useEffect(() => {
    fetch(services.url+"/get_suppliers.php", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((suppliers) => {
        setSuppliers(suppliers);
      });
  }, []);

  const handleCreateNewProject = (e) => {
    e.preventDefault();
    const form = e.target;

    const companyName = form.companyName.value;
    const projectName = form.projectName.value;
    const deliveryAddress = form.deliveryAddress.value;
    const province = form.province.value;
    const supplierName = form.supplierName.value;
    const countryName = form.countryName.value;
    const categoryProduct = form.categoryProduct.value;
    const projectDescription = form.projectDescription.value;

    let startYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(startDate);
    let startMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(startDate);
    let startDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(startDate);

    let endYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(endDate);
    let endMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(endDate);
    let endDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(endDate);

    let startFormatedDate = startYear+'-'+startMonth+'-'+startDay;
    let endFormatedDate = endYear+'-'+endMonth+'-'+endDay;

    
    const project = {
      requestType:'new',
      projectId:'',
      companyName,
      projectName,
      deliveryAddress,
      province,
      supplierName,
      startDate:startFormatedDate,
      endDate:endFormatedDate,
      countryName,
      categoryProduct,
      projectDescription
    }

    if(startDate === null || endDate === null){
      alert("Please select valid start/end date");
    }else{
      localStorage.setItem("project", JSON.stringify(project));

      navigate('/projects/add-new/step-2');
    }
    
  };
  return (
    <div>
      <TopBar title="Add New Project"/>
      
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <div className="flex justify-between items-center">
            <p className="text-xl text-white">Project Details</p>
            <p className="text-xl text-white">Step 1</p>
          </div>
        </div>
        <form action="" onSubmit={handleCreateNewProject}>
          <div className="form-body bg-white p-8 rounded-b-[10px]">
            <div className="flex md:flex-row flex-col gap-5 justify-between items-start">
              <div className="lg:w-[5/12] md:w-[1/2] w-full">
                <div className="form-group">
                  <p className="text-blue font-bold mb-4">For what Company *</p>
                  {/* <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="Company Name"
                    name="companyName"
                    
                    id=""
                  /> */}
                  <select
                    className="w-full p-2 border rounded"
                    name="companyName"
                    id=""
                    required
                    >
                    <option value=''>--Select One--</option>
                    {companies.map((company) => (
                      <option value={company.id}>{company.companyName}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-6">
                  <p className="text-blue font-bold mb-4">Name project*</p>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="Name Project"
                    name="projectName"
                    id=""
                    required
                  />
                </div>
                <div className="form-group mt-6">
                  <p className="text-blue font-bold mb-4">
                    Address for delivery *
                  </p>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="Address"
                    name="deliveryAddress"
                    id=""
                    required
                  />
                </div>
                <div className="form-group mt-6">
                  <p className="text-blue font-bold mb-4">Province *</p>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="Name Province"
                    name="province"
                    id=""
                    required
                  />
                </div>
              </div>
              <div className="lg:w-[5/12] md:w-[1/2] w-full">
                <div className="form-group">
                  <p className="text-blue font-bold mb-4">
                    Supplier we will use it *
                  </p>
                  {/* <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="william@mail.com"
                    name=""
                    
                    id=""
                  /> */}
                  <select
                    name="supplierName"
                    className="w-full border rounded p-2"
                    id=""
                    required
                    >
                    <option value=''>--Select One--</option>
                    {suppliers.map((supplier) => (
                      <option value={supplier.id}>
                        {supplier.companyName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mt-6">
                  <p className="text-blue font-bold mb-4">
                    Date Start project / Date End project*
                  </p>
                  <div className="date_start_end">
                      <Datepicker showMonthDropdown showYearDropdown selected={startDate} onChange={date=>setStartDate(date)} maxDate={endDate} shouldCloseOnSelect customInput={<StartDate/>}/>
                      &nbsp;
                      <Datepicker showMonthDropdown showYearDropdown selected={endDate} minDate={startDate} onChange={date=>setEndDate(date)} customInput={<EndDate/>}/>
                  </div>
                  
                </div>
                <div className="form-group mt-6">
                  <p className="text-blue font-bold mb-4">Country *</p>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="Name Country"
                    name="countryName"
                    required
                    id=""
                  />
                </div>
                <div className="form-group mt-6">
                  <p className="text-blue font-bold mb-4">Category Product</p>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="categoryProduct"
                    name="categoryProduct"
                    id=""
                  />
                  {/* <select name="" className="w-full p-2 border rounded" id="">
                    <option value="category 1">Category 1</option>
                    <option value="category 2">Category 2</option>
                    <option value="category 3">Category 3</option>
                  </select> */}
                </div>
              </div>
            </div>
            <div className="mt-6">
              <p className="text-blue font-bold mb-4">Description Project</p>
              <textarea
                className="w-full p-2 border rounded resize-none"
                name="projectDescription"
                id=""
                cols="30"
                rows="6"
              ></textarea>
            </div>
          </div>

          <div className="text-center mt-10">
            <button
              // onClick={gotoStep2}
              type="submit"
              className="btn-blue px-10"
            >
              Next
            </button>
          </div>
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default AddNewProject;
