import React, { useEffect, useState, useRef } from "react";
import {useNavigate } from "react-router-dom";
import services from "../../components/services";
import '../../assets/css/add_project.css';
import { FaAngleDown, FaAngleUp, FaPlus, FaTimes } from "react-icons/fa";
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";


const ProjectProduct = () => {
  const ref = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [products, setPorduct] = useState([]);
  const [wrap, setWrap] = useState(null);
  const [found, setFound] = useState(null);
  const [categoryFound, setCategoryFound] = useState(null);
  const [mqty, setmqty] = useState(null);
  const [material, setMaterial] = useState([]);
  const [execute, setExecute] = useState(false);
  
  
  const navigate = useNavigate();
  
  useEffect(() => {
    // localStorage.removeItem("sub_categories");
    let project = JSON.parse(localStorage.getItem('project'));
    // console.log(JSON.parse(localStorage.getItem('project')));
    let supplier = project.supplierName;
    
    setLoading(true);
    setExecute(false);
    fetch(services.url+"/get_categories2.php?supplier="+supplier, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        
        setCollections(res);
        if(res.length > 0){
          setCategoryFound(1);
          var active_category = res.filter((item)=> item.active === 'active');
          if(active_category.length > 0){
            var category_id = active_category[0].id;
          
        
            // alert(category_id);  
            let data = {category_id}
            fetch(services.url+"/get_sub_category2.php", {
              method: "POST",
              // headers: {
              //   "Content-Type": "application/json",
              // },
              body: JSON.stringify(data),
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res);
                setExecute(true);
                setLoading(false);
                let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
                if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

                }else{
                  load_sub_categories.forEach((val,key)=>{
                    var get_sub_cat_index = res.findIndex(el => el.subcategory_id == val.subcategoryId);
                    res[get_sub_cat_index].selected = true;
                  })
                }
                setSubcategory(res);
                
              });
          }else{
            setCategoryFound(0);
            setLoading(false);
          }
        }else{
          setCategoryFound(0);
          setLoading(false);
        }
        
        
      });

      
    
      // setTimeout(() => {
        
      // }, 200);
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));
    
  }, []);

  const handelMelamineDelete = (product_id, index) => {
    
    setMaterial(material.filter((item)=>{
        if(item.product_id === product_id){
          var load_material_data = JSON.parse(localStorage.getItem("material_data"));
          var new_melamine_data = item.material_data.melamine.filter((item2)=> item2.index !== index);
          if(new_melamine_data.length == 0 && item.material_data.screenPartition.length == 0 && item.material_data.colorDoor.length == 0){
            var new_material_data = material.filter((item3) => item3.product_id !== product_id);
            if(new_material_data.length == 0){
              localStorage.removeItem("material_data");
            }else{
              localStorage.setItem("material_data", JSON.stringify(new_material_data));
            }
            return item.product_id !== product_id;
          }
          item.material_data.melamine = new_melamine_data;
          var get_product_index = load_material_data.findIndex(el => el.product_id == product_id);
          load_material_data[get_product_index].material_data.melamine = new_melamine_data;
          localStorage.setItem("material_data", JSON.stringify(load_material_data));
          return item;
        }else{
          return item;
        }
      })
    )
    
  }

  
  const handle_category_item = (ele) => {
    let category_id = ele.currentTarget.id;
    let data = {category_id}
    setLoading(true);
    setCollections(oldValue => {
      return oldValue.filter((item) => {
        if(item.id == category_id){
          item.active = 'active';
        }else{
          item.active = '';
        }
        return true;
      });
    })
    setExecute(false);
    fetch(services.url+"/get_sub_category2.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setExecute(true);
        // console.log(res);
        // let elr = document.getElementsByClassName('product_box');
        // if(elr.length > 0){
        //   for(var i=0; i<elr.length; i++){
        //     elr[i].remove();
        //   }
        // }
        
        setLoading(false);
        let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
        if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

        }else{
          load_sub_categories.forEach((val,key)=>{
            var get_sub_cat_index = res.findIndex(el => el.subcategory_id == val.subcategoryId);
            if(get_sub_cat_index >= 0){
              res[get_sub_cat_index].selected = true;
            }
            
          })
        }
        setSubcategory(res);
        setFound(null);
        setPorduct([]);
        setWrap(null);
        
      });
  }

  
  
  const handle_subcategory_item = (ele) => {
    let id = ele.currentTarget.id;
    let index = ele.currentTarget.getAttribute('data-index');
    let wrap_id = ele.currentTarget.getAttribute('data-wrap_id');
    
    let load_material_data = JSON.parse(localStorage.getItem("material_data"));
    if (load_material_data === null || typeof load_material_data === "undefined") {
      // console.log(load_material_data);
      
    } else { 
      var material_data = [];
      load_material_data.forEach((val) => {
        console.log(val);
        material_data.push(val);
      })
      setMaterial(material_data);
      
    }

    setSubcategory(oldValue => {
      return oldValue.filter((item) => {
        if(item.index == index && item.checked == false){
          item.checked = true;
        }else if(item.index == index && item.checked == true){
          item.checked = false;
        }else{
          item.checked = false;
        }
        return true;
      });
    })
    setTimeout(()=>{
      // console.log(subcategory);
      let is_checked = document.getElementById('subcategory_checkbox-'+id).checked;
      if(is_checked){
        setLoading(true);
        setFound(null);
        setPorduct([]);
        setWrap(null);
        let subcatdata = {subcategory_id:id, wrap_id:wrap_id};
        fetch(services.url+"/get_products.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(subcatdata),
        })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          setFound(res.found);
          let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

          }else{
            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == id);
            if(get_sub_cat_index >= 0){
              var products = load_sub_categories[get_sub_cat_index]['products'];
              let subcategory_products = res.products;
              // console.log(products);
              products.forEach((val,key)=>{
                var subcategory_product_index = subcategory_products.findIndex(el => el.product_id == val.productId);
                if(subcategory_product_index >= 0){
                  subcategory_products[subcategory_product_index].checked = true;
                  subcategory_products[subcategory_product_index].custom_measurement = val.product_mesurment_value;
                  subcategory_products[subcategory_product_index].custom_measurement_qty = val.product_mesurment_qty;
                }
                var product_measurement = val.product_mesurment;
                if(product_measurement.length > 0){
                  product_measurement.forEach((mval,mkey)=>{
                    var sub_categorie_product_measurement =  subcategory_products[subcategory_product_index].measurement;
                    var sub_categorie_product_measurement_index = sub_categorie_product_measurement.findIndex(el => el.measurement == mval.measurementValue);

                    if(sub_categorie_product_measurement_index >=0){
                      subcategory_products[subcategory_product_index].measurement[sub_categorie_product_measurement_index].qty = mval.qty;
                    }
                    // console.log(mval);
                  })
                }
                // console.log(val);
              })
              // console.log(subcategory_products);
            }
            
            // console.log(get_sub_cat_index);
            // res.products.forEach((val,key)=>{
            //   console.log(val);
            // })
          }
          setPorduct(res.products);
          setWrap(res.wrap_id);
          // console.log(res.products);
        });
      }else{
        setFound(null);
        setPorduct([]);
        setWrap(null);
      }
      
    },100)
    
  }

  const increment_measurement = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementIndex = id.split('-')[3];
    let measurementValue = document.getElementById('mv_'+id).innerHTML;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let isCustom = 0;
    let qty = products[productIndex].measurement[measurementIndex].qty === '' ? 1 : parseInt(products[productIndex].measurement[measurementIndex].qty)+parseInt(1);
    let model_no = e.currentTarget.getAttribute('data-model_no');
    
    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        
        if(item.product_id == productId && item.checked == false){
          item.checked = true;
          item.measurement[measurementIndex].qty = qty;
          
          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
            let load_new_sub_categories = Array();
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_new_sub_categories.push(new_sub_category);

            localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

          }else{

            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);

            if(get_sub_cat_index >= 0){

              var products = load_sub_categories[get_sub_cat_index]['products'];
              var get_product_index = products.findIndex(el => el.productId == productId);

              if(get_product_index >= 0){

                var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
                if(product_mesurment.length > 0){
                  var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
                  if(get_product_mesurment_index >= 0){
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=qty;
                  }else{
                    var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                    product_mesurment.push(more_product_mesurment);
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
                  }
                }else{
                  var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                  product_mesurment.push(more_product_mesurment);
                  load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
                }

              }else{
                let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                products.push(new_product);
                load_sub_categories[get_sub_cat_index]['products'] = products;
              }

            }else{
              let products = Array();
              let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              let new_sub_category = {subcategoryId, selected:true,products};
              
              load_sub_categories.push(new_sub_category);

            }
            
            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

          }

        }else if(item.product_id == productId && item.checked == true && qty >= 1){
          item.measurement[measurementIndex].qty = qty;

          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
            let load_new_sub_categories = Array();
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_new_sub_categories.push(new_sub_category);

            localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

          }else{
            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
            // console.log('product found');
            var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
            if(product_mesurment.length > 0){
              var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
              if(get_product_mesurment_index >= 0){
                load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=qty;
              }else{
                var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                product_mesurment.push(more_product_mesurment);
                load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
              }
            }else{
              var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              product_mesurment.push(more_product_mesurment);
              load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
            }

            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }
        }
        return true;
      });
    })

    setTimeout(() => {
      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));  
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        setSubcategory(oldValue => {
          return oldValue.filter((item) => {
            item.selected = false;
            return true;
          });
        })
      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        if(get_sub_cat_index >=0){
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = true;
              }
              return true;
            });
          })
        }else{
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = false;
              }
              return true;
            });
          })
        }
        // console.log(get_sub_cat_index);
      }
    }, 100);
  }
  
  const decrement_measurement = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementIndex = id.split('-')[3];
    let measurementValue = document.getElementById('mv_'+id).innerHTML;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let isCustom = 0;

    let model_no = e.currentTarget.getAttribute('data-model_no');

    let qty = products[productIndex].measurement[measurementIndex].qty === '' ? 1 : products[productIndex].measurement[measurementIndex].qty;
    if(parseInt(qty) > 0)
      qty--
    
    let productCustomMeasurement = products[productIndex].custom_measurement_qty === '' ? 0 : products[productIndex].custom_measurement_qty;
    let productMeasurement = products[productIndex].measurement;
    const sum = productMeasurement.reduce((accumulator, object) => {
      return accumulator + object.qty;
    }, 0);

    let totalQty = 0;
    if(products[productIndex].measurement[measurementIndex].qty == 0){
      totalQty = parseInt(sum)+parseInt(productCustomMeasurement);
    }else{
      totalQty = (parseInt(sum)+parseInt(productCustomMeasurement))-1;
    }
    
    // console.log(qty);
    // console.log(totalQty);
    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        
        if(item.product_id == productId && item.checked == true && totalQty > 0){
          item.measurement[measurementIndex].qty = qty;
          
          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
            let load_new_sub_categories = Array();
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_new_sub_categories.push(new_sub_category);

            localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

          }else{
            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
                
            var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
            
            if(product_mesurment.length > 0){
              var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
              
              if(get_product_mesurment_index >= 0){
                if(qty == 0){
                  var new_measurement_array = product_mesurment.filter((item) => item.measurementValue !== measurementValue);
                  // console.log(new_measurement_array);
                  load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = new_measurement_array;
                }else{
                  load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=qty;
                }
                
              }else{
                var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                product_mesurment.push(more_product_mesurment);
                load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
              }
            }

            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }
        }
        else if(item.product_id == productId && item.checked == true && totalQty == 0){

          
          item.checked = false;
          item.measurement[measurementIndex].qty = qty;

          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          // console.log(get_sub_cat_index);
          var products_array = load_sub_categories[get_sub_cat_index].products;
          var new_product_array = products_array.filter((item) => item.productId !== productId);
          // console.log(new_product_array);
          if(new_product_array.length == 0){
            
            var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
            // console.log(new_sub_categories.length);
            if(new_sub_categories.length == 0){
              localStorage.removeItem("sub_categories");
            }else{
              localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
            }
            
          }else{
            load_sub_categories[get_sub_cat_index].products = new_product_array;
            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }

          var load_material_data = JSON.parse(localStorage.getItem("material_data"));
          // console.log(material);
          // console.log(load_material_data);

          if (load_material_data === null || typeof load_material_data === "undefined") {

          }else{
            setMaterial(material.filter((item)=>{
              return item.product_id != productId;
            }));
    
            var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
            localStorage.setItem("material_data", JSON.stringify(new_material_data));
          }
        }
        return true;
      });
    })
    setTimeout(() => {
      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));  
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        setSubcategory(oldValue => {
          return oldValue.filter((item) => {
            item.selected = false;
            return true;
          });
        })
      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        if(get_sub_cat_index >=0){
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = true;
              }
              return true;
            });
          })
        }else{
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = false;
              }
              return true;
            });
          })
        }
        // console.log(get_sub_cat_index);
      }
    }, 100);
    // console.log(id);
  }
  
  const handelProductCheckbox = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let model_no = e.currentTarget.getAttribute('data-model_no');
    
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
    // console.log(load_sub_categories);
    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        if(item.product_id == productId && item.checked == false){
          item.checked = true;
          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            let load_new_sub_categories = Array();
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_new_sub_categories.push(new_sub_category);

            localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

          }else{
            
            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
            if(get_sub_cat_index >= 0){
              var products = load_sub_categories[get_sub_cat_index]['products'];
              var new_product = {productId, product_mesurment_value:'', product_mesurment_qty:'', product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              load_sub_categories[get_sub_cat_index]['products'] = products;
            }else{
              var products = Array();
                        
              var new_product = {productId, product_mesurment_value:'', product_mesurment_qty:'', product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              var new_sub_category = {subcategoryId,products};
              
              load_sub_categories.push(new_sub_category);
            }
            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }
        }else if(item.product_id == productId && item.checked == true){
          item.checked = false;
          
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          // console.log(get_sub_cat_index);
          var products_array = load_sub_categories[get_sub_cat_index].products;
          var new_product_array = products_array.filter((item) => item.productId !== productId);
          // console.log(new_product_array.length);
          if(new_product_array.length == 0){
            var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
            // console.log(new_sub_categories.length);
            if(new_sub_categories.length == 0){
              localStorage.removeItem("sub_categories");
            }
          }else{
            load_sub_categories[get_sub_cat_index].products = new_product_array;
            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }

          var load_material_data = JSON.parse(localStorage.getItem("material_data"));
          // console.log(material);
          // console.log(load_material_data);

          if (load_material_data === null || typeof load_material_data === "undefined") {

          }else{
            setMaterial(material.filter((item)=>{
              return item.product_id != productId;
            }));
    
            var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
            localStorage.setItem("material_data", JSON.stringify(new_material_data));
          }
        }
        return true;
      });
    })
    setTimeout(() => {
      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));  
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        setSubcategory(oldValue => {
          return oldValue.filter((item) => {
            item.selected = false;
            return true;
          });
        })
      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        if(get_sub_cat_index >=0){
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = true;
              }
              return true;
            });
          })
        }else{
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = false;
              }
              return true;
            });
          })
        }
        // console.log(get_sub_cat_index);
      }

    }, 100);
    
  }
  
  const measurementInput = (e) => {
    let value = e.currentTarget.value;
    let id = e.currentTarget.id;
    // console.log(id);
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementIndex = id.split('-')[3];
    let measurementValue = document.getElementById('mv_'+id).innerHTML;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let isCustom = 0;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        
        if(item.product_id == productId){
          item.measurement[measurementIndex].qty = value;
        }
        
        return true;
      });
    })
    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    setTimeout(() => {
      let productCustomMeasurementQty = products[productIndex].custom_measurement_qty === '' ? 0 : products[productIndex].custom_measurement_qty;
      let productMeasurement = products[productIndex].measurement;
      const sum = productMeasurement.reduce((accumulator, object) => {
        let qty = object.qty === '' ? 0 : object.qty;
        return parseInt(accumulator) + parseInt(qty);
      }, 0);
      let totalQty = parseInt(productCustomMeasurementQty) + parseInt(sum);
      // console.log(products);
      // console.log(productCustomMeasurementQty);

      setPorduct(oldValue => {
        return oldValue.filter((item) => {
          // console.log(totalQty);
          if(item.product_id == productId && totalQty > 0){
            item.checked = true;
            if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
              
              if(value !== ''){
                let load_new_sub_categories = Array();
                let products = Array();
                let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                products.push(new_product);
                let new_sub_category = {subcategoryId, selected:true,products};
                
                load_new_sub_categories.push(new_sub_category);
    
                localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));
              }
              
            }else{

              var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
              if(get_sub_cat_index >= 0){
                
                var products = load_sub_categories[get_sub_cat_index]['products'];
                var get_product_index = products.findIndex(el => el.productId == productId);
                // console.log(products);
                if(get_product_index >= 0){
                  // console.log('product found');
                  var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
                  if(product_mesurment.length > 0){
                    var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
                    if(get_product_mesurment_index >= 0){
                      if(value === ''){
                        
                        var new_measurement_array = product_mesurment.filter((item) => item.measurementValue !== measurementValue);
                        // console.log(new_measurement_array);
                        load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = new_measurement_array;
                      }else{
                        load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=value;
                      }
                      
                    }else{
                      
                      var more_product_mesurment = {measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                      product_mesurment.push(more_product_mesurment);
                      load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
                    }
                  }else{
                      var more_product_mesurment = {measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                      product_mesurment.push(more_product_mesurment);
                      load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
                  }
                }else{
                  // console.log('product not found');
                  let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                  products.push(new_product);
                  load_sub_categories[get_sub_cat_index]['products'] = products;
                }

              }else{
                let products = Array();
                let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                products.push(new_product);
                let new_sub_category = {subcategoryId, selected:true,products};
                
                load_sub_categories.push(new_sub_category);
              }
              
              localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
  
            }
          }else if(item.product_id == productId && totalQty == 0){
            item.checked = false;
            
            if(value != 0 || value === ''){
              if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

              }else{
                
                var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
                // console.log(get_sub_cat_index);
                var products_array = load_sub_categories[get_sub_cat_index].products;
                var new_product_array = products_array.filter((item) => item.productId !== productId);
                // console.log(new_product_array.length);
                if(new_product_array.length == 0){
                  var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
                  // console.log(new_sub_categories.length);
                  if(new_sub_categories.length == 0){
                    localStorage.removeItem("sub_categories");
                  }else{
                    localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
                  }
                }else{
                  load_sub_categories[get_sub_cat_index].products = new_product_array;
                  localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
                }
              }
              
            }

            var load_material_data = JSON.parse(localStorage.getItem("material_data"));
            // console.log(material);
            // console.log(load_material_data);

            if (load_material_data === null || typeof load_material_data === "undefined") {

            }else{
              setMaterial(material.filter((item)=>{
                return item.product_id != productId;
              }));
      
              var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
              localStorage.setItem("material_data", JSON.stringify(new_material_data));
            }
            
            
          }
          return true;
        });
      })
    }, 100);

    setTimeout(() => {
      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));  
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        setSubcategory(oldValue => {
          return oldValue.filter((item) => {
            item.selected = false;
            return true;
          });
        })
      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        if(get_sub_cat_index >=0){
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = true;
              }
              return true;
            });
          })
        }else{
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = false;
              }
              return true;
            });
          })
        }
        // console.log(get_sub_cat_index);
      }
      
    }, 200);
    // console.log(value);
    // products[productIndex].measurement[measurementIndex].qty = value;
  }

  const increment_measurement_custom = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementValue = document.getElementById('mcv_'+id).value;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let isCustom = 0;
    let qty = products[productIndex].custom_measurement_qty === '' ? 1 : parseInt(products[productIndex].custom_measurement_qty)+parseInt(1);
    let model_no = e.currentTarget.getAttribute('data-model_no');
    
    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        
        if(item.product_id == productId && item.checked == false){
          item.checked = true;
          item.custom_measurement = measurementValue;
          item.custom_measurement_qty = qty;
          
          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
            let load_new_sub_categories = Array();
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_new_sub_categories.push(new_sub_category);

            localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

          }else{

            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);

            if(get_sub_cat_index >= 0){

              var products = load_sub_categories[get_sub_cat_index]['products'];
              var get_product_index = products.findIndex(el => el.productId == productId);

              if(get_product_index >= 0){

                load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = measurementValue;
                load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = qty;
                

              }else{
                let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                products.push(new_product);
                load_sub_categories[get_sub_cat_index]['products'] = products;
              }

            }else{
              let products = Array();
              let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              let new_sub_category = {subcategoryId, selected:true,products};
              
              load_sub_categories.push(new_sub_category);

            }
            
            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

          }

        }else if(item.product_id == productId && item.checked == true && qty >= 1){
          
          item.custom_measurement = measurementValue;
          item.custom_measurement_qty = qty;

          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
            let load_new_sub_categories = Array();
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_new_sub_categories.push(new_sub_category);

            localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

          }else{
            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
            // console.log('product found');
            load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = measurementValue;
            load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = qty;
            

            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }
        }
        return true;
      });
    })

    setTimeout(() => {
      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));  
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        setSubcategory(oldValue => {
          return oldValue.filter((item) => {
            item.selected = false;
            return true;
          });
        })
      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        if(get_sub_cat_index >=0){
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = true;
              }
              return true;
            });
          })
        }else{
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = false;
              }
              return true;
            });
          })
        }
        // console.log(get_sub_cat_index);
      }
    }, 100);
  }

  const decrement_measurement_custom = (e) => {

    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementValue = document.getElementById('mcv_'+id).value;
    let isCustom = 0;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    let qty = products[productIndex].custom_measurement_qty === '' ? 1 : products[productIndex].custom_measurement_qty;
    if(parseInt(qty) > 0)
      qty--
    
    let productCustomMeasurement = products[productIndex].custom_measurement_qty === '' ? 0 : products[productIndex].custom_measurement_qty;
    let productMeasurement = products[productIndex].measurement;
    const sum = productMeasurement.reduce((accumulator, object) => {
      return accumulator + object.qty;
    }, 0);

    let totalQty = 0;
    if(products[productIndex].custom_measurement_qty == 0){
      totalQty = parseInt(sum)+parseInt(productCustomMeasurement);
    }else{
      totalQty = (parseInt(sum)+parseInt(productCustomMeasurement))-1;
    }
    
    // console.log(qty);
    // console.log(totalQty);
    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        
        if(item.product_id == productId && item.checked == true && totalQty > 0){
          item.custom_measurement = measurementValue;
          item.custom_measurement_qty = qty;
          
          if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
            let load_new_sub_categories = Array();
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_new_sub_categories.push(new_sub_category);

            localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

          }else{
            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
                
            load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = measurementValue;
            load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = qty;
            
            

            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }
        }
        else if(item.product_id == productId && item.checked == true && totalQty == 0){

          
          item.checked = false;
          item.custom_measurement = measurementValue;
          item.custom_measurement_qty = qty;

          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          // console.log(get_sub_cat_index);
          var products_array = load_sub_categories[get_sub_cat_index].products;
          var new_product_array = products_array.filter((item) => item.productId !== productId);
          // console.log(new_product_array);
          if(new_product_array.length == 0){
            
            var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
            // console.log(new_sub_categories.length);
            if(new_sub_categories.length == 0){
              localStorage.removeItem("sub_categories");
            }else{
              localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
            }
            
          }else{
            load_sub_categories[get_sub_cat_index].products = new_product_array;
            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }

          var load_material_data = JSON.parse(localStorage.getItem("material_data"));
          // console.log(material);
          // console.log(load_material_data);

          if (load_material_data === null || typeof load_material_data === "undefined") {

          }else{
            setMaterial(material.filter((item)=>{
              return item.product_id != productId;
            }));
    
            var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
            localStorage.setItem("material_data", JSON.stringify(new_material_data));
          }
        }
        return true;
      });
    })
    setTimeout(() => {
      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));  
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        setSubcategory(oldValue => {
          return oldValue.filter((item) => {
            item.selected = false;
            return true;
          });
        })
      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        if(get_sub_cat_index >=0){
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = true;
              }
              return true;
            });
          })
        }else{
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = false;
              }
              return true;
            });
          })
        }
        // console.log(get_sub_cat_index);
      }
    }, 100);
    // console.log(id);

  }

  const measurementValueInput_custom = (e) => {

    let value = e.currentTarget.value;
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementQty = document.getElementById('mcq_'+id).value;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        
        if(item.product_id == productId){
          item.custom_measurement = value;
        }
        
        return true;
      });
    })
    
    setTimeout(() => {
      let productCustomMeasurementQty = products[productIndex].custom_measurement_qty === '' ? 0 : products[productIndex].custom_measurement_qty;
      let productMeasurement = products[productIndex].measurement;
      const sum = productMeasurement.reduce((accumulator, object) => {
        let qty = object.qty === '' ? 0 : object.qty;
        return parseInt(accumulator) + parseInt(qty);
      }, 0);
      let totalQty = parseInt(productCustomMeasurementQty) + parseInt(sum);

      // console.log(totalQty);

      setPorduct(oldValue => {
        return oldValue.filter((item) => {
          
          if(item.product_id == productId){
            // item.checked = true;
            
            if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
              
              if(value !== ''){
                let load_new_sub_categories = Array();
                let products = Array();
                let new_product = {productId, productImage, product_mesurment_value:value, product_mesurment_qty:measurementQty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                products.push(new_product);
                let new_sub_category = {subcategoryId, selected:true,products};
                
                load_new_sub_categories.push(new_sub_category);
    
                localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));
              }
              
            }else{
              
              if(value !== ''){
                
                var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
                if(get_sub_cat_index >= 0){
                  
                  var products = load_sub_categories[get_sub_cat_index]['products'];
                  var get_product_index = products.findIndex(el => el.productId == productId);
                  // console.log(products);
                  if(get_product_index >= 0){
                    // console.log('product found');
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = value;
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = measurementQty;
                    
                  }else{
                    let new_product = {productId, productImage, product_mesurment_value:value, product_mesurment_qty:measurementQty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                    products.push(new_product);
                    load_sub_categories[get_sub_cat_index]['products'] = products;
                  }

                }else{
                  
                  let products = Array();
                  let new_product = {productId, productImage, product_mesurment_value:value, product_mesurment_qty:measurementQty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                  products.push(new_product);
                  let new_sub_category = {subcategoryId, selected:true,products};
                  
                  load_sub_categories.push(new_sub_category);
                }
              }else{
                var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
                if(get_sub_cat_index >= 0){
                  
                  var products = load_sub_categories[get_sub_cat_index]['products'];
                  var get_product_index = products.findIndex(el => el.productId == productId);
                  // console.log(products);
                  if(get_product_index >= 0){
                    // console.log('product found');
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = '';
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = '';
                    
                  }

                }
              }
              
              
              localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
  
            }

          }
          /*
          else if(item.product_id == productId && totalQty == 0){
            item.checked = false;
            if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

            }else{
              var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
              // console.log(get_sub_cat_index);
              var products_array = load_sub_categories[get_sub_cat_index].products;
              var new_product_array = products_array.filter((item) => item.productId !== productId);
              // console.log(new_product_array.length);
              if(new_product_array.length == 0){
                var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
                // console.log(new_sub_categories.length);
                if(new_sub_categories.length == 0){
                  localStorage.removeItem("sub_categories");
                }else{
                  localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
                }
              }else{
                load_sub_categories[get_sub_cat_index].products = new_product_array;
                localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
              }
            }
            
          }
          */
          
          return true;
        });
      })

    }, 100);

  }

  const measurementInput_custom = (e) => {

    let value = e.currentTarget.value;
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementValue = document.getElementById('mcv_'+id).value;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    setPorduct(oldValue => {
      return oldValue.filter((item) => {
        
        if(item.product_id == productId){
          item.custom_measurement_qty = value;
        }
        
        return true;
      });
    })

    setTimeout(() => {
      let productCustomMeasurementQty = products[productIndex].custom_measurement_qty === '' ? 0 : products[productIndex].custom_measurement_qty;
      let productMeasurement = products[productIndex].measurement;
      const sum = productMeasurement.reduce((accumulator, object) => {
        let qty = object.qty === '' ? 0 : object.qty;
        return parseInt(accumulator) + parseInt(qty);
      }, 0);
      let totalQty = parseInt(productCustomMeasurementQty) + parseInt(sum);

      // console.log(totalQty);

      setPorduct(oldValue => {
        return oldValue.filter((item) => {
          
          if(item.product_id == productId && totalQty > 0 && measurementValue !== ''){
            item.checked = true;
            
            if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
              
              if(value !== ''){
                let load_new_sub_categories = Array();
                let products = Array();
                let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                products.push(new_product);
                let new_sub_category = {subcategoryId, selected:true,products};
                
                load_new_sub_categories.push(new_sub_category);
    
                localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));
              }
              
            }else{
              
              if(value !== ''){
                var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
                if(get_sub_cat_index >= 0){
                  
                  var products = load_sub_categories[get_sub_cat_index]['products'];
                  var get_product_index = products.findIndex(el => el.productId == productId);
                  // console.log(products);
                  if(get_product_index >= 0){
                    // console.log('product found');
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = measurementValue;
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = value;
                    
                  }else{
                    let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                    products.push(new_product);
                    load_sub_categories[get_sub_cat_index]['products'] = products;
                  }

                }else{
                  let products = Array();
                  let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                  products.push(new_product);
                  let new_sub_category = {subcategoryId, selected:true,products};
                  
                  load_sub_categories.push(new_sub_category);
                }
              }else{
                var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
                if(get_sub_cat_index >= 0){
                  
                  var products = load_sub_categories[get_sub_cat_index]['products'];
                  var get_product_index = products.findIndex(el => el.productId == productId);
                  // console.log(products);
                  if(get_product_index >= 0){
                    // console.log('product found');
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = '';
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = '';
                    
                  }else{
                    let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                    products.push(new_product);
                    load_sub_categories[get_sub_cat_index]['products'] = products;
                  }

                }
              }
              
              
              localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
  
            }
            
          }else if(item.product_id == productId && totalQty == 0){
            item.checked = false;

            
            var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
            // console.log(get_sub_cat_index);
            var products_array = load_sub_categories[get_sub_cat_index].products;
            var new_product_array = products_array.filter((item) => item.productId !== productId);
            // console.log(new_product_array.length);
            if(new_product_array.length == 0){
              var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
              // console.log(new_sub_categories.length);
              if(new_sub_categories.length == 0){
                localStorage.removeItem("sub_categories");
              }else{
                localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
              }
            }else{
              load_sub_categories[get_sub_cat_index].products = new_product_array;
              localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
            }

            var load_material_data = JSON.parse(localStorage.getItem("material_data"));
            // console.log(material);
            // console.log(load_material_data);

            if (load_material_data === null || typeof load_material_data === "undefined") {

            }else{
              setMaterial(material.filter((item)=>{
                return item.product_id != productId;
              }));
      
              var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
              localStorage.setItem("material_data", JSON.stringify(new_material_data));
            }
            
          }
          
          return true;
        });
      })
    }, 100);

    setTimeout(() => {
      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
      // console.log(JSON.parse(localStorage.getItem("sub_categories")));  
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        setSubcategory(oldValue => {
          return oldValue.filter((item) => {
            item.selected = false;
            return true;
          });
        })
      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        if(get_sub_cat_index >=0){
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = true;
              }
              return true;
            });
          })
        }else{
          setSubcategory(oldValue => {
            return oldValue.filter((item) => {
              if(item.subcategory_id == subcategoryId){
                item.selected = false;
              }
              return true;
            });
          })
        }
        // console.log(get_sub_cat_index);
      }
    }, 200);
  }

  const handelSelectMaterial = (ele) => {
    let product_id = ele.currentTarget.id;
    navigate('/projects/add-new/project-material/'+product_id);
  }

  const handelMaterialDelete = (productId, index) => {
    var load_material_data = JSON.parse(localStorage.getItem("material_data"));
    var get_product_index = load_material_data.findIndex(el => el.product_id == productId);
    // console.log(material);

    setMaterial(material.filter((item)=>{
      if(item.product_id == productId){
        
        var load_material_data = JSON.parse(localStorage.getItem("material_data"));
        var new_material_data = item.material_data.filter((item2)=> item2.index !== index);
        
        if(new_material_data.length == 0){
          var new_material_data = material.filter((item3) => item3.product_id !== productId);
          if(new_material_data.length == 0){
            localStorage.removeItem("material_data");
          }else{
            localStorage.setItem("material_data", JSON.stringify(new_material_data));
          }
          return item.product_id !== productId;
        }else{
          item.material_data = new_material_data;
          var get_product_index = load_material_data.findIndex(el => el.product_id == productId);
          load_material_data[get_product_index].material_data = new_material_data;
          localStorage.setItem("material_data", JSON.stringify(load_material_data));
        }
        
        return item;
      }else{
        return item;
      }
    }))
  }

  const gotoStep2 = () =>{
    navigate('/projects/add-new/step-2');
  }

  return (
    <div>
      {isLoading ? <LoadingSpinner/> : ""}
      <h1 style={{fontWeight:"bold", fontSize:"22px"}}>Add product for project</h1>
      <h1 style={{fontWeight:"bold", marginTop:"15px"}}>Product</h1>
      <div className="add_product_category_box">
        {collections.map((collection) => (
          <span className="item" key={collection.id} id={collection.id} onClick={handle_category_item}>
            {collection.divider !== '' ? <span className="divider">{collection.divider}</span> : ''}
            <span className={'collection_name ' + collection.active} id={collection.id}>{collection.categoryName}</span>
          </span>
        ))}
      </div>
      <h1 style={{fontWeight:"bold", marginTop:"15px", marginBottom:"10px"}}>Collection</h1>
      <div className="add_product_subcategory_box">
          {
          execute ? 
          categoryFound == 1 ?  subcategory.map((subcategory, key) => (
            <>
            <div className={subcategory.last==='last' ? 'subcategory_item last' : 'subcategory_item'} data-wrap_id={Math.ceil(subcategory.index/9)} data-index={subcategory.index} data-is_checked={subcategory.checked} id={subcategory.subcategory_id} onClick={handle_subcategory_item} key={subcategory.subcategory_id}>
              {subcategory.selected ? <span className="selected">Selected</span> : ''}
              <input type="checkbox" checked={subcategory.checked} onChange={e => {}} id={'subcategory_checkbox-'+subcategory.subcategory_id} className="subcategory_checkbox"/>
              <div className="subcategory_item_image">
                <img src={services.url+'/subcategory_images/'+subcategory.image}/>
              </div>
              <h1>{subcategory.subcategory_name}</h1>
              {subcategory.checked === true ? <FaAngleDown style={{margin:'auto'}}/> : ''}
            </div>
            
            {
            subcategory.last==='last' ?
              Object.keys(products).length > 0 ?
                wrap == Math.ceil(subcategory.index/9) ?
                <div className="product_box">
                {products.map((product, key) => (
                  <div className="product_item" key={key}>
                    <div className="product_image">
                      <input type="checkbox" id={'p_'+product.subcategory_id+'-'+product.product_id} onChange={handelProductCheckbox} checked={product.checked} data-model_no={product.reference} className="product_checkbox"/>
                      <img src={product.image}/>
                      <input type="hidden" id={'pi_'+product.subcategory_id+'-'+product.product_id} value={product.image}/>
                    </div>
                    <div className="product_name">
                      <h2>{product.productName}</h2>
                    </div>
                    <div className="product_measurement">
                      <div className="measurement_title">
                        <span>Size</span>
                        <span>Quantity</span>
                      </div>
                      {product.measurement.map((measurement, key2)=>(
                        
                        <div className="measurement_value">
                          <span id={'mv_'+product.subcategory_id+'-'+product.product_id+'-'+key+'-'+key2}>{measurement.measurement}</span>
                          <span className="measurement_input">
                            <input type="text" className="mqty" autoComplete="off" data-model_no={product.reference} onChange={measurementInput} id={'mq_'+product.subcategory_id+'-'+product.product_id+'-'+key+'-'+key2} data-product_key={key} data-measurement_key={key2} value={measurement.qty}/>
                            <span className="measurement_btn">
                              <button onClick={increment_measurement} data-model_no={product.reference} className="increment_measurement" id={'inc_'+product.subcategory_id+'-'+product.product_id+'-'+key+'-'+key2}><FaAngleUp/></button>
                              <button onClick={decrement_measurement} data-model_no={product.reference} className="decrement_measurement" id={'decInc_'+product.subcategory_id+'-'+product.product_id+'-'+key+'-'+key2}><FaAngleDown/></button>
                            </span>
                          </span>
                        </div>
                      ))}
                      <div className="measurement_value">
                        <span>
                          <input autoComplete="off" id={'mcv_'+product.subcategory_id+'-'+product.product_id+'-'+key} type="text" className="custom_measurement" data-model_no={product.reference} onChange={measurementValueInput_custom} value={product.custom_measurement} placeholder="Custom size"/>
                        </span>
                        <span className="measurement_input">
                          <input type="text" className="mqty" autoComplete="off" onChange={measurementInput_custom} data-model_no={product.reference} id={'mcq_'+product.subcategory_id+'-'+product.product_id+'-'+key} data-product_key={key} value={product.custom_measurement_qty}/>
                          <span className="measurement_btn">
                            <button data-model_no={product.reference} onClick={increment_measurement_custom} className="increment_measurement" id={'cinc_'+product.subcategory_id+'-'+product.product_id+'-'+key}><FaAngleUp/></button>
                            <button data-model_no={product.reference} onClick={decrement_measurement_custom} className="decrement_measurement" id={'cdecInc_'+product.subcategory_id+'-'+product.product_id+'-'+key}><FaAngleDown/></button>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="selected_material_box">
                      {
                        material.map((material) => (
                          <>
                            {
                              material.product_id == product.product_id ?
                              <div className="product_material">
                                <div className="product_material_title">
                                  Select Material
                                </div>
                                {
                                  material.material_data.map((material_data) => (
                                    <>
                                      <div className="product_material_data_title">
                                        {material_data.subCatName}
                                      </div>
                                      <div className="product_material_data_item">
                                        <div className="product_material_data_name">{material_data.name}</div>
                                        <span className="product_material_data_remove" onClick={()=>handelMaterialDelete(product.product_id, material_data.index)}><FaTimes size={12} color="#ff0000"/></span>
                                      </div>
                                    </>
                                  ))
                                }
                                
                              </div>
                              : ''
                            }
                          </>
                        ))
                      }
                    </div>
                    <div className="select_material_btn">
                      <button id={product.product_id} onClick={handelSelectMaterial}><FaPlus/> Select material</button>
                    </div>
                  </div>
                ))}
                
                </div>
                :''
              : 
                found == 0 ?
                  wrap == Math.ceil(subcategory.index/9) ?
                  <div className="product_box">
                    <h1 className="product_not_found">Product not found!</h1>
                  </div>
                  : ''
                : ''
            : ''
            }

            </>
          )) : 
          <div className="product_box">
            <h1 className="product_not_found">Category not found!</h1>
          </div>
          : ''
          }
      </div>
      <div className="text-center mt-10">
        <button
          onClick={gotoStep2}
          type="submit"
          className="btn-blue px-10"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default ProjectProduct;
