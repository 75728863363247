import React from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import AllProjects from "../../components/projects";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const Projects = () => {
  return (
    <div className="customers">
      <TopBar title="Projects"/>
      
      <AllProjects />
      <Footer/>
    </div>
  );
};

export default Projects;
