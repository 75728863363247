import React, { useEffect } from "react";
import Swal from "sweetalert2";
const Quotes = () => {

    useEffect(()=>{
        Swal.fire("Coming soon...");
    },[]);

    return(
        <div className="coming_soon">
            <h1>coming soon...</h1>
        </div>
    )
}

export default Quotes;