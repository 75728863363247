import React, {useState, useEffect} from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser, FaDollarSign } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import AllProjectsSupplierDetails from "../../components/project-supplier-details";
import services from "../../components/services";
import Pagination from "../../components/pagination";
import fileIcon from "../../assets/img/file_icon.png";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/Loading_spinner";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { format } from 'date-fns';

const InvoiceExportPdf = () => {

    const [projects, setProjects] = React.useState({});
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(8);
    const [dataLength, setDataLength] = useState(null);
    const [projectFound, setProjectFound] = useState(false);
    const [execute, setExecute] = useState(false);
    const [checkRequest, setCheckRequest] = useState(false);
    const [projectProduct, setProjectProduct] = useState([]);
    const [projectCustomProduct, setProjectCustomProduct] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [projectIds, setProjectIds] = useState([]);
    const [customProductds, setCustomProductIds] = useState([]);
    const [projectCustomProductIds, setProjectCustomProductIds] = useState([]);
    const [projectTotalPrice, setProjectTotalPrice] = useState([]);
    const [loader, setLoader] = useState(false);

    const {id} = useParams();

    const getSupplierProjectDetails = () => {
        
        fetch(services.url+"/get_invoice_product_for_pdf.php?id="+id)
          .then((res) => res.json())
          .then((res) => {
            setExecute(true);
            setLoading(false);
            
            if(res.found === 1){
              setCheckRequest(true);
              setProjectIds(res.project_id);
              setProjectProduct(res.project_product);
              setProjectCustomProduct(res.project_custom_product);
              setCustomProductIds(res.custom_product_ids);
              setProjectCustomProductIds(res.project_custom_product_ids);
              setProjects(res.project[0]);
              const sum1 = res.project_custom_product.reduce((accumulator, object)=>{
                let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
                return accumulator + parseFloat(totalPrice);
              },0);
              const sum2 = res.project_product.reduce((accumulator, object)=>{
                let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
                return accumulator + parseFloat(totalPrice);
              },0);
              let total = sum1+sum2;
              setTotalPrice(total);
    
              const projectTotal = [];
    
              res.project_id.forEach(val=>{
                // console.log(val);
                var pcp = res.project_custom_product.filter((item)=>item.project_id == val);
                var projectSum = pcp.reduce((accumulator, object)=>{
    
                    let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
                    return accumulator + parseFloat(totalPrice);
                    
                },0);
                var new_project_total = {project_id:val, totalPrice:projectSum};
                projectTotal.push(new_project_total);
              })
              
              setProjectTotalPrice(projectTotal);
    
            }else{
              setCheckRequest(false);
            }
            
            console.log(res);
          });
      }

    useEffect(() => {
        setLoading(true);
        getSupplierProjectDetails();
        // getProjects(page);
    
        // fetch("https://sensar.vercel.app/api/v1/projects", {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // })
        //   .then((res) => res.json())
    
        //   .then((res) => {
        //     setProjects(res);
        //   });
      }, []);

      const exportpdf = () => {

        // let capture = document.getElementById('export_pdf_document');
        // setLoader(true);
        // html2canvas(capture).then((canvas)=>{
        //     const imgData = canvas.toDataURL('img/png');
        //     const doc = new jsPDF('l', 'mm', 'a4');
        //     const componentWidth = doc.internal.pageSize.getWidth();
        //     const componentHeight = doc.internal.pageSize.getHeight();

        //     doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
        //     setLoader(false);
        //     doc.save('invoice.pdf');
        // })

        const doc = new jsPDF('l', 'mm', 'a4')

        
        let customerName = document.getElementById("customerName").innerHTML;

        let status_btn = document.querySelectorAll(".status_btn");
        // console.log(status_btn);
        let status_btn_text = "";
        let divider = "";
        status_btn.forEach(val=>{
          status_btn_text += divider+val.innerHTML;
          divider = "/";
        })

        doc.text("Project/"+customerName+"/"+status_btn_text, 14, 20)
        doc.text("Custom Product", 14, 30)

        autoTable(doc, {
          html: '#my_table0',
          startY: 35,
          bodyStyles: {minCellHeight:20},
          didDrawCell:function(data){
            if(data.column.index === 1 && data.cell.section === "body"){
              // let td = data.cell.raw;
              // let img = td.getElementsByTagName('img')[0];
              // let dim = data.cell.height - data.cell.padding('vertical');
              // let textPos = data.cell.textPos;
              // console.log("img: "+img.src);
              // if(img.src === ''){

              // }else{
              //   doc.addImage(img.src, data.cell.x, data.cell.y, dim, dim)
              // }
              // doc.addImage(img.src, data.cell.x, data.cell.y, dim, dim)
            }
          },
        })
        
        doc.text(
          "Product",
          14,
          doc.lastAutoTable.finalY + 10
        )
        

        autoTable(doc, {
          html: '#my_table',
          bodyStyles: {minCellHeight:20},
          didDrawCell:function(data){
            if(data.column.index === 1 && data.cell.section === "body"){
              // let td = data.cell.raw;
              // let img = td.getElementsByTagName('img')[0];
              // img.crossOrigin = 'Anonymous';
              // let dim = data.cell.height - data.cell.padding('vertical');
              // let textPos = data.cell.textPos;
              // console.log("img: "+img.src);
              
              // if(img.src === ''){

              // }else{
                // doc.addImage(img.src, data.cell.x, data.cell.y, dim, dim)
              // }
              
            }
          },
          startY:doc.lastAutoTable.finalY + 12
        })

        
        let totalPrice = document.getElementById("totalPrice").innerHTML;
        doc.text(totalPrice, 250, doc.lastAutoTable.finalY + 10, {
            align: 'right',
        });
        
        doc.save('table.pdf')
      }

  return (
    <div className="customers">
        <div className="invoice-export-pdf">
            <div className="top-title">
                <span style={{fontSize:"20px", fontWeight:"bold"}}>Project/</span>
                <span id="customerName">{projects.customerName}/{projects.processDate}
                {/* {format(projects.processDate, 'MMMM dd, yyyy')} */}
                </span>
                {projects.new_request == 1 ?
                  <div className="status-btn">
                    <button className="status_btn" style={{border:'1px solid orange', padding:'0px 5px', backgroundColor:'orange'}}>Not yet view</button>
                    <button className="status_btn" style={{border:'1px solid grey', color:'#000', padding:'0px 5px'}}>Update from supplier</button>
                    </div>
                   : 
                   ''
                   }
                {projects.new_request == 0 && projects.validation == 0 ?
                  <div className="status-btn">
                    <button className="status_btn" style={{border:'1px solid grey', padding:'0px 5px'}}>Not yet view</button>
                    <button className="status_btn" style={{border:'1px solid green', backgroundColor:'green', color:'#fff', padding:'0px 5px'}}>Update from supplier</button>
                  </div>
                   : ''}
                {projects.new_request == 0 && projects.validation == 1 && projects.paid == 0 ?
                  <div className="status-btn">
                    {/* <button className="status_btn" style={{border:'1px solid grey', padding:'0px 5px'}}>Not yet view</button> */}
                    <button className="status_btn" style={{border:'1px solid green', backgroundColor:'green', color:'#fff', padding:'0px 5px'}}>Update from supplier</button>
                  </div>
                   : ''}
                {projects.validation == 1 && projects.paid == 1 ?
                  <div className="status-btn">
                    <button className="status_btn" style={{border:'1px solid green', backgroundColor:'green', color:'#fff', padding:'0px 5px'}}>Paid</button>
                  </div>
                   : 
                   ''
                   }
                {projects.validation == 1 && projects.due == 1 ?
                    <div className="status-btn">
                        <button className="status_btn" style={{border:'1px solid orange', backgroundColor:'orange', color:'#fff', padding:'0px 5px'}}>Not yet paid</button>
                    </div>
                   : 
                   ''
                   }
            </div>
            <div>
                <button onClick={exportpdf} disabled={!(loader===false)} className="btn-blue bg-[#4D43B5] text-white px-8 py-2 rounded">
                {
                    loader ? 'Downloading' : 'Download'
                }
                </button>
            </div>
        </div>
       <div className="form mt-5" id="export_pdf_document">
        <form
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          
          <div className="">
            {projectCustomProduct.length == 0 ?
            ''
              :
              <>
                <h2 style={{fontWeight:'bold', textDecoration:'underline', color: '#52637a'}}>Custom product : </h2>
                <table className="table project_product_box" id="my_table0" cellspacing="0">
                  <thead>
                    <tr className="project_product_header">
                      <th width="60px">Item</th>
                      <th width="100px">File technique</th>
                      <th width="100px">Model NO.</th>
                      <th width="145px">Dimension(mm)</th>
                      <th width="90px">Material</th>
                      <th width="90px">Color</th>
                      <th width="90px">Quantity</th>
                      <th width="70px">CBM Total</th>
                      <th width="90px">Unit price EXW USD</th>
                      <th width="90px">Total price USD</th>
                      <th width="200px">Note</th>
                      <th width="200px">Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectCustomProduct.map((projectCustomProduct,key)=>(
                        <tr className="project_product_item" key={key}>
                          <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                            {key+1}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            <label>
                              <img style={{margin:'auto', width:'40px'}}
                                src={fileIcon}
                                alt=""
                              />
                            </label>
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectCustomProduct.model}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectCustomProduct.dimension}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectCustomProduct.material}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectCustomProduct.color}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectCustomProduct.qty}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectCustomProduct.cbm}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            <div className="price-box">
                              <FaDollarSign/>
                              {projectCustomProduct.unitPrice}
                            </div>
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            <div className="price-box">
                              <FaDollarSign/>
                              {projectCustomProduct.totalPrice}
                            </div>
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.note}
                          </td>
                          <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                              {projectCustomProduct.Commentaire}
                          </td>
                        </tr>
                      ))
                    }              
                  </tbody>
                </table>
              </>
              }
            {
              projectProduct.length == 0 ?
               ''
              :
              <>
                <br/>
                <h2 style={{fontWeight:'bold', textDecoration:'underline', color: '#52637a'}}>Product : </h2>
                <table className="table project_product_box" id="my_table" cellspacing="0">
                  <thead>
                    <tr className="project_product_header">
                      <th>Item</th>
                      <th>Picture</th>
                      <th>Model NO.</th>
                      <th>Dimension(mm)</th>
                      <th>Material</th>
                      <th>Quantity</th>
                      <th>CBM Total</th>
                      <th>Unit price EXW USD</th>
                      <th>Total price USD</th>
                      <th>Note</th>
                      <th>Commentaire</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectProduct.map((projectProduct, key)=>(
                        <tr className="project_product_item" key={key}>
                          <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                            {key+1}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            <img src={projectProduct.productImage}/>
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectProduct.model ==='' ? 'empty' : projectProduct.model}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectProduct.measurement}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {
                              <div className="product_material">
                              {projectProduct.product_materials.map((product_materials)=>(
                                <>
                                <div className="product_material_data_title">
                                  {product_materials.subCatName}
                                </div>
                                <div className="product_material_data_item">
                                  <div className="product_material_data_name">{product_materials.name}</div>
                                </div>
                                </>
                              ))
                              }
                              </div>
                            }
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectProduct.measurement_qty}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectProduct.cbm}
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            <div className="price-box">
                              <FaDollarSign/>
                              {projectProduct.unitPrice}
                            </div>
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            <div className="price-box">
                              <FaDollarSign/>
                              {projectProduct.totalPrice}
                            </div>
                          </td>
                          <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                            {projectProduct.note}
                          </td>
                          <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                            {projectProduct.commentaire}
                          </td>
                        </tr>
                      ))
                    }              
                  </tbody>
                </table>
              </>
            }
            
          </div>

          <div id="totalPrice" className="text-right mt-7" style={{color: '#52637a'}}>
            Total price USD : ${totalPrice}
          </div>
        </form>
      </div>
      {loading ? <LoadingSpinner/> : ""}
      <Footer/>
    </div>
  );
};

export default InvoiceExportPdf;
