import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FaBell, FaCog, FaPlus, FaEdit, FaTrash, FaUser } from "react-icons/fa";
import services from "../../components/services";
import "../../assets/css/add_subcategory_card.css";
import LoadingSpinner from "../../components/Loading_spinner";
import Swal from "sweetalert2";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const Categories = () => {
  const [isLoading, setLoading] = React.useState(false);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(services.url+"/categories.php", {
      method: "GET",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setCategories(res);
      });
  }, []);

  /*const items = categories.map((category) => {
    return {
      key: category.id,
      label: category[0],
      children: category[0].categoryName
        ? category[0].categoryName
        : "No Subcategory",
    };
  });*/

  const toggle = (index) => {
    if(selected === index){
      return setSelected(null);
    }
    setSelected(index);
  }

  const handleEditSupplier = (e) => {
    e.stopPropagation();
  }

  const handleRemoveSupplier = (e) =>{
    e.stopPropagation();
    let id = e.currentTarget.id;
    let supplier_id = id.split('-')[1];

    const data = {id:supplier_id};

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      
      if (result.isConfirmed) {
        setLoading(true);
        fetch(services.url+"/delete_supplier.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setLoading(false);
          Swal.fire({
            title: "Success!",
            text: "Supplier has been deleted successfully!",
            icon: "success",
            confirmButtonText: "Okay",
          });
          setCategories(categories.filter(item => item.supplier_id !== supplier_id));
        });
      }
    });
  }

  const handelDelete = (id) => {
    const data = {id};

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      
      if (result.isConfirmed) {
        setLoading(true);
        fetch(services.url+"/delete_category.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setLoading(false);
          Swal.fire({
            title: "Success!",
            text: "Category has been deleted successfully!",
            icon: "success",
            confirmButtonText: "Okay",
          });
          setCategories(res);
        });
      }
    });
    /*
    setLoading(true);
    fetch(services.url+"/delete_category.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      setLoading(false);
      Swal.fire({
        title: "Success!",
        text: "Category has been deleted successfully!",
        icon: "success",
        confirmButtonText: "Okay",
      });
      setCategories(res);
    });
    */
  }

  return (
    <div>
      <TopBar title="Categories"/>
      {isLoading ? <LoadingSpinner/> : ""}
      
      <div className="flex justify-end mt-5 items-center gap-3 mb-5">
        <Link
          to={"/product/categories/add-new"}
          className="btn-blue flex justify-center items-center gap-2 px-5 py-3"
        >
          <FaPlus /> <span>Category</span>
        </Link>
      </div>

      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Categories</p>
        </div>
        <div className="card-body bg-white p-8 rounded-b-[10px]">
          <h3 className="text-xl font-bold mb-5">Category List</h3>
          {/* <Collapse
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
            className="bg-white border-none shadow-none rounded-0"
          /> */}
          <div className="accordion">
            {categories.map((supplier, index)=>(

              <div className="item">
                <div className="title" onClick={()=> toggle(index)}>
                  <h2>{supplier.supplier_name} <sub>(Supplier)</sub></h2>
                  <div className="title-right">
                    <Link onClick={handleEditSupplier} to={`/supplier/edit/${supplier.supplier_id}`}><FaEdit/></Link>
                    <span id={`supplier-${supplier.supplier_id}`} onClick={handleRemoveSupplier}><FaTrash color="#F00"/></span>
                    <span>{selected === index ? '-' : '+'}</span>
                  </div>
                </div>
                <div className={selected===index? 'show':'content'}>
                  { supplier.categories.length > 0 ?
                  supplier.categories.map((category,index2)=>(
                    <div>
                      <div className="category_name">
                        {category.category_name}
                        <span className="action_icons">
                          <Link to={`/product/categories/${category.category_id}/edit`}><FaEdit/></Link>
                          <span onClick={()=>handelDelete(category.category_id)}><FaTrash color="#F00"/></span>
                        </span>
                      </div>
                      {category.subcategories.map((subcategory, index3)=>(
                        <p>---{subcategory.name}</p>
                      ))}
                    </div>
                  )) : 'No Category Found'
                }
                </div>
              </div>

            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Categories;
