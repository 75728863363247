/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect } from "react";
import { FaBell, FaCog, FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/Loading_spinner";
import AddNewSubcategoryCard from "../../components/add-subcategory-card";
import services from "../../components/services";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";


const AddNewCategory = () => {

  const navigate = useNavigate();

  const [isLoading, setLoading] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState(null);
  const [imgUrl, setImgUrl] = React.useState(null);
  const [imgFile, setImgFile] = React.useState(null);
  const [subCategory, setSubCategory] = React.useState(null);
  const [subCategories, setSubCategories] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);

  
  const handleImage = (e) => {
    e.preventDefault();

    let image = document.getElementById("photo_material").files[0];
    
    var reader = new FileReader();
    reader.onload = function (e) {
      // e.target.result   
      // console.log(e.target.result);
      setImgUrl(e.target.result);
    }
    reader.readAsDataURL(image);
  }
  
  const handleUpdateSubcategory = (e) => {
    e.preventDefault();
    
    let image_info = document.getElementById("photo_material").files[0];
    if(subCategory == null || image_info == null){
    //   alert('Invalid Subcategory Image/Name');
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Invalid Subcategory Image/Name!",
        });
    }else{ 
      var len = parseInt(subCategories.length) + parseInt(1);
      console.log(len);
      setSubCategories([
        ...subCategories,
        { id: len, name: subCategory, image: imgUrl}
      ]);

      setTimeout(() => {
        let image_name = image_info.name;
        let image_type = image_info.type;
        // Create a new File object
        const myFile = new File([image_info], image_name, {
            type: image_type,
            lastModified: new Date(),
        });
        console.log(myFile);
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(myFile);
        console.log('id = '+len);
        document.getElementById("sub_cat_img-"+len).files = dataTransfer.files;

        console.log(len);

        setImgUrl(null);
        setSubCategory(null);
        document.getElementById("photo_material").value = null;
        document.getElementById('subcategory').value = '';
      }, 100);

      
    }
    
  }

  const handleRemoveSubcategory = (e) => {
    e.preventDefault();
    const name = e.target.getAttribute("name")
    setSubCategories(subCategories.filter(item => item.name !== name));
  }

  const handelUpdateName = (index, preName, newName) => {
    // console.log(index + ' => ' + preName + ' -> '+newName);
    let newArr = [...subCategories];
    newArr[index].name = newName; 
    setSubCategories(newArr);
  }
  
  const handleAddNewCategory = (e) => {
    e.preventDefault();
    
    const category_name = document.getElementById('categoryName').value;
    const supplier = document.getElementById('supplier').value;
    const subcategory_name = document.querySelectorAll('.subcategory_name');
    const subcategory_images = document.querySelectorAll('.subcategory_images');
    
    const formData = new FormData();
    
    formData.append('category_name', category_name);
    formData.append('supplier', supplier);

    subcategory_name.forEach((input_field,key)=>{
      formData.append('subcategory_name[]', input_field.value);
    })

    subcategory_images.forEach((input_field,key)=>{
      formData.append('subcategory_images[]', input_field.files[0]);
    })

    if(category_name.length == 0){
        // alert('Category filed must filled out');
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Category filed must filled out!",
        });
    }else if(supplier.length == 0){
        // alert('Supplier filed must filled out');
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Supplier filed must filled out!",
        });
    }else if(subcategory_name.length == 0){
        // alert('Please add at least one subcategory');
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please add at least one subcategory!",
        });
    }else{
        setLoading(true);
        fetch(services.url+"/create_subcategory.php", {
            method: "POST",
            // headers: {
            //   "Content-Type": "application/json",
            // },
            body: formData,
          })
            .then((res) => res.json())
            .then((res) => {
              // console.log(res);
              setLoading(false);
              setCategoryName(null);
              document.getElementById('categoryName').value = '';
              document.getElementById('supplier').value = '';
              setSubCategories([]);
              
              Swal.fire({
                title: "Success!",
                text: "Subcategory has been added successfully!",
                icon: "success",
                confirmButtonText: "Okay",
              }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/product/categories');
                }
                
              });
              
              
            });
    }
    
  }

  useEffect(() => {
    fetch(services.url+"/get_suppliers.php", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((res) => {
        setSuppliers(res);
      });
  }, []);
  // console.log(subCategories);
  return (
    <div>
      <TopBar title="Add New Category"/>
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Category Details</p>
        </div>
        <form
          onSubmit={handleAddNewCategory}
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          <div className="form-group mb-4">
            <p className="text-blue font-bold mb-4">Name Category *</p>
            <input
              type="text"
              className="w-full p-2 border rounded"
              placeholder="Wood"
              name="categoryName"
              id="categoryName"
              onChange={(e)=> setCategoryName(e.target.value)}
            />
          </div>

          <p className="text-blue pt-5 font-bold mb-4">
            Subcategory (Optional)
          </p>
          <div className="row flex justify-between">
            <div className="col-md-6">
              <div className="flex justify-start items-center gap-4">
                <div className="photo">
                  <label htmlFor="" className="text-xs text-blue">
                    Photo Subcategory
                  </label>
                  <input type="file" name="photo_material" id="photo_material" onChange={handleImage}/>
                  <label
                    htmlFor="photo_material"
                    className="file-label text-xs block border border-dashed p-4 mt-3 w-[150px] text-center"
                  >
                    Drag and drop or click here to select file
                  </label>
                </div>

                <div className="flex border rounded overflow-hidden w-[300px] mt-4">
                  <input
                    type="text"
                    className="w-full p-2 border-r"
                    placeholder="Subcategory"
                    name="subcategory"
                    id="subcategory"
                    onChange={e => setSubCategory(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={handleUpdateSubcategory}
                    className="btn-blue bg-[#4D43B5] text-white px-4 py-2"
                  >
                    Add
                  </button>
                </div>
                {isLoading ? <LoadingSpinner/> : ""}
                
              </div>
            </div>
            <div className="col-md-6 category_supplier_box">
              <div className="form-group mt-6">
                  
                  <p className="text-blue font-bold mb-4">Supplier *</p>
                  <select
                    name="supplier"
                    className="w-full p-2 border rounded"
                    id="supplier"
                  >
                    <option value="">--Select One--</option>
                    {suppliers.map((supplier) => (
                      <option value={supplier.id}>{supplier.companyName}</option>
                    ))}
                  </select>
                </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="flex justify-start items-center gap-4">
              <div className="photo">
                <label htmlFor="" className="text-xs text-blue">
                  Photo Subcategory
                </label>
                <input type="file" name="photo_material" id="photo_material" onChange={handleImage} />
                <label
                  htmlFor="photo_material"
                  className="file-label text-xs block border border-dashed p-4 mt-3 w-[150px] text-center"
                >
                  Drag and drop or click here to select file
                </label>
              </div>

              <div className="flex border rounded overflow-hidden w-[300px] mt-4">
                <input
                  type="text"
                  className="w-full p-2 border-r"
                  placeholder="Subcategory"
                  name="subcategory"
                  id="subcategory"
                  onChange={e => setSubCategory(e.target.value)}
                />
                <button
                  type="button"
                  onClick={handleUpdateSubcategory}
                  className="btn bg-[#4D43B5] text-white px-4 py-2"
                >
                  Add
                </button>
              </div>
              {isLoading ? <LoadingSpinner/> : ""}
              
            </div>
            <div className="col-md-6">
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Supplier *</p>
                <select
                  name="supplier"
                  className="w-full p-2 border rounded"
                  id=""
                >
                  {suppliers.map((supplier) => (
                    <option value={supplier.id}>{supplier.companyName}</option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}
          <div className="flex flex-wrap justify-start items-center gap-2">
            { subCategories.map((subcategory, index)=>(
              <AddNewSubcategoryCard key={index} updateName={(e)=>{ handelUpdateName(e.target.getAttribute('data-ind'), e.target.getAttribute('id'), e.target.getAttribute('value')) }} closeFunction={handleRemoveSubcategory} subCategories={subcategory} index={index}/>
            )) }
            
            {/* <ul>
              {subCategories.map(subcategory => (
                <li key={subcategory.id}>{subcategory.name}</li>
              ))}
            </ul> */}
          </div>
          <div className="text-right mt-7">
            <button
              type="submit"
              className="btn-blue bg-[#4D43B5] text-white px-8 py-2 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default AddNewCategory;
