import React, { useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight, FaPlus, FaSearch, FaDollarSign, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import services from "../../components/services";
import Pagination from "../pagination";
import LoadingSpinner from "../../components/Loading_spinner";
import Spinner from "../../components/spinner";
import ProjectSupplierCard from "../project-supplier-card";
import TopBar from "../top-bar";
import fileIcon from "../../assets/img/file_icon.png";
import Swal from "sweetalert2";

const AllProjectsSupplier = () => {
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [dataLength, setDataLength] = useState(null);
  const [projectFound, setProjectFound] = useState(false);
  const [execute, setExecute] = useState(false);
  const [checkRequest, setCheckRequest] = useState(false);
  const [projectProduct, setProjectProduct] = useState([]);
  const [projectCustomProduct, setProjectCustomProduct] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [projectIds, setProjectIds] = useState([]);
  const [customProductds, setCustomProductIds] = useState([]);
  const [projectCustomProductIds, setProjectCustomProductIds] = useState([]);
  const [projectTotalPrice, setProjectTotalPrice] = useState([]);
  const [orderBy, setOrderBy] = useState('Newest');
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  const getProjects = (pageNo, searchTextValue=searchText, orderByValue = orderBy) => {
    // console.log(page);

    let supplier_id = localStorage.getItem("sensar_user_role_id");
    // console.log(supplier_id);
    
    fetch(services.url+"/projects.php?supplier_id="+supplier_id+"&per_page="+limit+"&page="+pageNo+"&search_text="+searchTextValue+"&orderBy="+orderByValue)
      .then((res) => res.json())
      .then((res) => {
        setExecute(true);
        setLoading(false);
        setDataLength(res.no_of_rows);
        setProjects(res.projects);
        setSearchLoading(false);
        if(res.found === 1){
          setProjectFound(true);
        }else{
          setProjectFound(false);
        }
        // console.log(res);
      });
  };

  const checkRequestQuote = () => {
    let supplier_id = localStorage.getItem("sensar_user_role_id");

    fetch(services.url+"/check_request_quote.php?supplier_id="+supplier_id)
      .then((res) => res.json())
      .then((res) => {
        setExecute(true);
        setLoading(false);
        if(res.found === 1){
          setCheckRequest(true);
          setProjectIds(res.project_id);
          setProjectProduct(res.project_product);
          setProjectCustomProduct(res.project_custom_product);
          setCustomProductIds(res.custom_product_ids);
          setProjectCustomProductIds(res.project_custom_product_ids);
          // console.log(res.project_id);
          console.log(res.project_product);
          // console.log(res.project_custom_product);
          // console.log(res.custom_product_ids);
          // console.log(res.project_custom_product_ids);
          const sum1 = res.project_custom_product.reduce((accumulator, object)=>{
            let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
            return accumulator + parseFloat(totalPrice);
          },0);
          const sum2 = res.project_product.reduce((accumulator, object)=>{
            let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
            return accumulator + parseFloat(totalPrice);
          },0);
          let total = sum1+sum2;
          setTotalPrice(total);

          const projectTotal = [];

          res.project_id.forEach(val=>{
            // console.log(val);
            var pcp = res.project_custom_product.filter((item)=>item.project_id == val);
            var pp = res.project_product.filter((item)=>item.projectId == val);
            var projectSum = pcp.reduce((accumulator, object)=>{

                let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
                // console.log(object.totalPrice);
                
                return accumulator + parseFloat(totalPrice);
                
            },0);

            var projectSum2 = pp.reduce((accumulator, object)=>{

                let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
                // console.log(object.totalPrice);
                
                return accumulator + parseFloat(totalPrice);
                
            },0);
            // console.log(projectSum);
            let total2 = projectSum+projectSum2;
            var new_project_total = {project_id:val, totalPrice:total2};
            projectTotal.push(new_project_total);
          })
          // console.log(projectTotal);
          setProjectTotalPrice(projectTotal);

        }else{
          setCheckRequest(false);
          setExecute(false);
          setLoading(true);
          getProjects(page);
        }
        // console.log(res);
      });
  }

  useEffect(() => {
    setLoading(true);
    checkRequestQuote();
    // getProjects(page);

    // fetch("https://sensar.vercel.app/api/v1/projects", {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => res.json())

    //   .then((res) => {
    //     setProjects(res);
    //   });
  }, []);

  let totalPage = Math.ceil(dataLength/limit);
  let start = (page - 1) * limit + 1;
  let end = page * limit;
  
  function handlePageChange(value){
    let pageno = page;
    if(value === "... "){
      setPage(1);
      pageno = 1;
    }
    else if(value === "pre"){
      if(page !== 1){
        setPage(page - 1);
        pageno = page - 1;
      }
    }else if(value === "next"){
      if(page !== totalPage){
        setPage(page + 1);
        pageno = page + 1;
      }
    }else if(value === " ..."){
      setPage(totalPage);
      pageno = totalPage;
    }else{
      setPage(value);
      pageno = value;
    }
    setLoading(true);
    getProjects(pageno);
  }

  const handleSearch = (ele) => {
    let searchTextValue = ele.currentTarget.value;
    setSearchText(searchTextValue)
    setSearchLoading(true);
    getProjects(1, searchTextValue);
  }
  
  const handlecbm = (ele) => {
    let id = ele.currentTarget.getAttribute('data-id');
    let value = ele.currentTarget.value;
    setProjectCustomProduct(oldvalue => {
      return oldvalue.filter((item)=>{
        if(item.id == id){
          item.cbm = value;
        }
        return true;
      })
    })
    // console.log(projectCustomProduct);
  }

  const handleOrderBy = (ele) => {
    let orderByValue = ele.currentTarget.value;
    setOrderBy(orderByValue);
    getProjects(1, searchText, orderByValue);
  }

  const handleunitprice = (ele) => {
    let id = ele.currentTarget.getAttribute('data-id');
    let qty = ele.currentTarget.getAttribute('data-qty');
    let value = ele.currentTarget.value;
    let totalPrice = value.length == 0 ? '' : parseFloat(value) * parseFloat(qty);

    setProjectCustomProduct(oldvalue => {
      return oldvalue.filter((item)=>{
        if(item.id == id){
          item.unitPrice = value;
          item.totalPrice = totalPrice;
        }
        return true;
      })
    })
    setTimeout(() => {
      
      const sum1 = projectCustomProduct.reduce((accumulator, object)=>{
        let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
        return accumulator + parseFloat(totalPrice);
      },0);
      const sum2 = projectProduct.reduce((accumulator, object)=>{

        let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
        return accumulator + parseFloat(totalPrice);
        // return accumulator;
        // return object.totalPrice;
        // console.log(object.totalPrice === null || object.totalPrice === '');
      },0);
      let total = sum1+sum2;
      // console.log(sum1);
      // console.log(sum2);
      
      setTotalPrice(total);
      
      const projectTotal2 = [];

      projectIds.forEach(val=>{
        
        var pcp = projectCustomProduct.filter((item)=>item.project_id == val);
        var pp = projectProduct.filter((item)=>item.projectId == val);
        var projectSum = pcp.reduce((accumulator, object)=>{

            let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
            // console.log(object.totalPrice);
            
            return accumulator + parseFloat(totalPrice);
            
        },0);

        var projectSum2 = pp.reduce((accumulator, object)=>{

            let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
            // console.log(object.totalPrice);
            
            return accumulator + parseFloat(totalPrice);
            
        },0);
        // console.log(projectSum);
        let total2 = projectSum+projectSum2;
        var new_project_total = {project_id:val, totalPrice:total2};
        projectTotal2.push(new_project_total);

      })     
      setProjectTotalPrice(projectTotal2); 
      

    }, 100);
    
  }
  const handlecommentaire = (ele) => {
    let id = ele.currentTarget.getAttribute('data-id');
    let value = ele.currentTarget.value;
    setProjectCustomProduct(oldvalue => {
      return oldvalue.filter((item)=>{
        if(item.id == id){
          item.commentaire = value;
        }
        return true;
      })
    })
    // console.log(projectCustomProduct);
  }

  const handlecbm2 = (ele) => {
    let id = ele.currentTarget.getAttribute('data-id');
    let value = ele.currentTarget.value;
    setProjectProduct(oldvalue => {
      return oldvalue.filter((item)=>{
        if(item.id == id){
          item.cbm = value;
        }
        return true;
      })
    })
    console.log(projectProduct);
  }

  const handleunitprice2 = (ele) => {
    let id = ele.currentTarget.getAttribute('data-id');
    let project_id = ele.currentTarget.getAttribute('data-project_id');
    let qty = ele.currentTarget.getAttribute('data-qty');
    let value = ele.currentTarget.value;
    let totalPrice = value.length == 0 ? '' : parseFloat(value) * parseFloat(qty);

    setProjectProduct(oldvalue => {
      return oldvalue.filter((item)=>{
        if(item.id == id){
          item.unitPrice = value;
          item.totalPrice = totalPrice;
        }
        return true;
      })
    })
    setTimeout(() => {
      const sum1 = projectCustomProduct.reduce((accumulator, object)=>{
        let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
        return accumulator + parseFloat(totalPrice);
      },0);
      const sum2 = projectProduct.reduce((accumulator, object)=>{
        let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
        return accumulator + parseFloat(totalPrice);
      },0);
      let total = sum1+sum2;
      setTotalPrice(total);

      const projectTotal2 = [];

      projectIds.forEach(val=>{
        
        var pcp = projectCustomProduct.filter((item)=>item.project_id == val);
        var pp = projectProduct.filter((item)=>item.projectId == val);
        var projectSum = pcp.reduce((accumulator, object)=>{

            let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
            // console.log(object.totalPrice);
            
            return accumulator + parseFloat(totalPrice);
            
        },0);

        var projectSum2 = pp.reduce((accumulator, object)=>{

            let totalPrice = object.totalPrice === null || object.totalPrice === '' ? 0 : object.totalPrice;
            // console.log(object.totalPrice);
            
            return accumulator + parseFloat(totalPrice);
            
        },0);
        // console.log(projectSum);
        let total2 = projectSum+projectSum2;
        var new_project_total = {project_id:val, totalPrice:total2};
        projectTotal2.push(new_project_total);

      })     
      setProjectTotalPrice(projectTotal2); 

    }, 100);
    // console.log(projectProduct);
  }
  const handlecommentaire2 = (ele) => {
    let id = ele.currentTarget.getAttribute('data-id');
    let value = ele.currentTarget.value;
    setProjectProduct(oldvalue => {
      return oldvalue.filter((item)=>{
        if(item.id == id){
          item.commentaire = value;
        }
        return true;
      })
    })
    // console.log(projectProduct);
  }

  const handelCustomRemove = (ele) => {
    ele.preventDefault();
    let id = ele.currentTarget.getAttribute('data-id');
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      
      if (result.isConfirmed) {
        setProjectCustomProduct(projectCustomProduct.filter(item => item.id != id));
      }
    });
    // console.log(projectCustomProduct);
  }

  const handelCustomRemove2 = (ele) => {
    ele.preventDefault();
    let id = ele.currentTarget.getAttribute('data-id');
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      
      if (result.isConfirmed) {
        setProjectProduct(projectProduct.filter(item => item.id != id));

        let pp1 = projectProduct.filter(item => item.id != id)
        const sum1 = projectCustomProduct.reduce((accumulator, object)=>{
          let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
          return accumulator + parseFloat(totalPrice);
        },0);
        const sum2 = pp1.reduce((accumulator, object)=>{
          let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
          return accumulator + parseFloat(totalPrice);
        },0);
        let total = sum1+sum2;
        setTotalPrice(total);

        const projectTotal2 = [];

        projectIds.forEach(val=>{
          
          var pcp = projectCustomProduct.filter((item)=>item.project_id == val);
          var pp = pp1.filter((item)=>item.projectId == val);
          var projectSum = pcp.reduce((accumulator, object)=>{

              let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
              // console.log(object.totalPrice);
              
              return accumulator + parseFloat(totalPrice);
              
          },0);

          var projectSum2 = pp.reduce((accumulator, object)=>{

              let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
              // console.log(object.totalPrice);
              
              return accumulator + parseFloat(totalPrice);
              
          },0);
          // console.log(projectSum);
          let total2 = projectSum+projectSum2;
          var new_project_total = {project_id:val, totalPrice:total2};
          projectTotal2.push(new_project_total);

        })     
        setProjectTotalPrice(projectTotal2); 
        
      }
    });
    // console.log(projectCustomProduct);

    
  }

  const handleSendQuote = (ele) => {
    ele.preventDefault();
    // console.log(projectIds);
    // console.log(projectProduct);
    // console.log(projectCustomProduct);
    // console.log(customProductds);
    // console.log(projectCustomProductIds);
    // console.log(projectTotalPrice);
    
    const data = {
      projectIds,
      projectProduct,
      projectCustomProduct,
      totalPrice,
      customProductds,
      projectCustomProductIds,
      projectTotalPrice,
      url:services.baseUrl
    }
    console.log(data);
    
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      
      if (result.isConfirmed) {
        
        setExecute(false);
        setLoading(true);

        fetch(services.url+"/send_quote.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((res) => {
            setExecute(true);
            setLoading(false);
            console.log(res);
            Swal.fire({
              title: "Success!",
              text: "Quote send successfully!",
              icon: "success",
              confirmButtonText: "Okay",
            }).then((result) => {
              if (result.isConfirmed) {
                  navigate('/dashboard');
              }
              
            });
          });

      }
    });
    
  }

  return (
    <>
      {checkRequest ?
       <>
       <TopBar title="Request from customer"/>
       <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Please complete the quote</p>
        </div>
        <form
          action=""
          onSubmit={handleSendQuote}
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          
          <div className="">
            {
              projectCustomProduct.length == 0 ? ''
              : 
              <>
                <h2 style={{fontWeight:'bold', textDecoration:'underline', color: '#52637a'}}>Custom product : </h2>
                <div class="table-responsive">
                  <table className="table project_product_box" cellspacing="0">
                    <thead>
                      <tr className="project_product_header">
                        <th>Item</th>
                        <th>File technique</th>
                        <th>Model NO.</th>
                        <th>Dimension(mm)</th>
                        <th>Material</th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th>CBM Total</th>
                        <th>Unit price EXW USD</th>
                        <th>Total price USD</th>
                        <th>Note</th>
                        <th>Commentaire</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectCustomProduct.map((projectCustomProduct,key)=>(
                          <tr className="project_product_item" key={key}>
                            <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                              {key+1}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <label>
                                <Link to={services.url+'custom_product_file/'+projectCustomProduct.file} target="_blank" download>
                                  <img style={{margin:'auto', width:'40px'}}
                                    src={fileIcon}
                                    alt=""
                                  />
                                </Link>
                              </label>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.model}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.dimension}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.material}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.color}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.qty}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <input type="text" data-id={projectCustomProduct.id} onChange={handlecbm} value={projectCustomProduct.cbm} className="cbm-input"/>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                <input type="numbber" data-qty={projectCustomProduct.qty} data-id={projectCustomProduct.id} data-project_id={projectCustomProduct.project_id} onChange={handleunitprice} value={projectCustomProduct.unitPrice} className="price-box-input"/>
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                <input type="numbber" value={projectCustomProduct.totalPrice} readOnly className="price-box-input"/>
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.note}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <textarea data-id={projectCustomProduct.id} onChange={handlecommentaire} value={projectCustomProduct.commentaire} className="custom_product_box_commentaire" placeholder="Commentaire"></textarea>
                            </td>
                            <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                              <button data-id={projectCustomProduct.id} onClick={handelCustomRemove} className="text-white bg-red-500 w-[30px] h-[30px] flex justify-center items-center rounded-full">
                                <FaTimes />
                              </button>
                            </td>
                          </tr>
                        ))
                      }              
                    </tbody>
                  </table>
                </div>
              </>
            }
            
            {
              projectProduct.length == 0 ? ''
              :
              <>
                <br/>
                <h2 style={{fontWeight:'bold', textDecoration:'underline', color: '#52637a'}}>Product : </h2>
                <div class="table-responsive">
                  <table className="project_product_box" cellspacing="0">
                    <thead>
                      <tr className="project_product_header">
                        <th>Item</th>
                        <th>Picture</th>
                        <th>Model NO.</th>
                        <th>Dimension(mm)</th>
                        <th>Material</th>
                        <th>Quantity</th>
                        <th>CBM Total</th>
                        <th>Unit price EXW USD</th>
                        <th>Total price USD</th>
                        <th>Note</th>
                        <th>Commentaire</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectProduct.map((projectProduct, key)=>(
                          <tr className="project_product_item" key={key}>
                            <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                              {key+1}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <img src={projectProduct.productImage}/>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.model ==='' ? 'empty' : projectProduct.model}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.measurement}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {
                                <div className="product_material">
                                {projectProduct.product_materials.map((product_materials)=>(
                                  <>
                                  <div className="product_material_data_title">
                                    {product_materials.subCatName}
                                  </div>
                                  <div className="product_material_data_item">
                                    <div className="product_material_data_name">{product_materials.name}</div>
                                  </div>
                                  </>
                                ))
                                }
                                </div>
                              }
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.measurement_qty}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <input type="text" data-id={projectProduct.id} onChange={handlecbm2} value={projectProduct.cbm} className="cbm-input"/>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                <input type="numbber" data-qty={projectProduct.measurement_qty} data-id={projectProduct.id} data-project_id={projectProduct.projectId} onChange={handleunitprice2} value={projectProduct.unitPrice} className="price-box-input"/>
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                <input type="numbber" value={projectProduct.totalPrice} readOnly className="price-box-input"/>
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.note}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <textarea data-id={projectProduct.id} onChange={handlecommentaire2} value={projectProduct.commentaire} className="custom_product_box_commentaire" placeholder="Commentaire"></textarea>
                            </td>
                            <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                              <button data-id={projectProduct.id} onClick={handelCustomRemove2} className="text-white bg-red-500 w-[30px] h-[30px] flex justify-center items-center rounded-full">
                                <FaTimes />
                              </button>
                            </td>
                          </tr>
                        ))
                      }              
                    </tbody>
                  </table>
                </div>
              </>
            }
            
          </div>

          <div className="text-right mt-7" style={{color: '#52637a'}}>
            <b>Total price USD : ${totalPrice}</b>
            <br/><br/>
            <button
              type="submit"
              className="btn-blue bg-[#4D43B5] text-white px-8 py-2 rounded"
            >
              SEND QUOTE
            </button>
          </div>
        </form>
      </div>
       </>
       :
       <>
       <TopBar title="Projects"/>
       <div className="flex md:flex-row flex-col-reverse md:gap-0 gap-3 md:p-0 p-3 md:my-7 justify-between items-center">
        <form
          action=""
          className="search flex justify-between items-center gap-3 bg-white px-6 py-3 rounded-full"
        >
          <FaSearch className="text-blue" />
          <input type="text" className="w-[300px] search_input" name="" id="" onChange={handleSearch} autoComplete="off" />
          {searchLoading ? <Spinner/> : ''}
        </form>

        <div className="right text-end flex justify-end items-center gap-7">
          {/* <p className="text-blue flex justify-start items-center gap-2">
            Newest <FaCaretDown className="text-xl" />
          </p> */}
          <select
            className="text-blue bg-transparent outline-none shadow-none border-none flex justify-start items-center gap-2"
            name=""
            id=""
            onChange={handleOrderBy}
          >
            <option value="Newest">Newest</option>
            <option value="Oldest">Oldest</option>
          </select>
        </div>
      </div>
      {execute ?
      projectFound ?
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-[20px]">
        {projects.map((project) => (
          <ProjectSupplierCard project={project} key={project.project_id} />
        ))}
      </div>
        :
        <div className="not-found-text">Data not found!</div>
      : ''}
      <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} start={start} end={end} totalData={dataLength} onPageChange={handlePageChange}/>
       </> }
       {loading ? <LoadingSpinner/> : ""}
      
    </>
  );
};

export default AllProjectsSupplier;
