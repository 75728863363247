import axios from "axios";
import React, { useEffect } from "react";
import { FaBell, FaCog, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../components/services";
import '../../assets/css/add_materail.css';
import AddNewMaterialTypeCard from "../../components/add-material-type-card";
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";


const AddNewMaterial = () => {
  
  const [isLoading, setLoading] = React.useState(false);
  const [logoUrl, setLogoUrl] = React.useState(null);
  const [imgUrl, setImgUrl] = React.useState(null);
  const [collections, setCollections] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);
  const [loadingCollection, setLoadingCollection] = React.useState(false);
  const [loadingSupplier, setLoadingSupplier] = React.useState(false);
  const [subcategory, setSubcategory] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);
  const [color, setColor] = React.useState(null);

  useEffect(() => {
    setLoadingCollection(true);
    // fetch(services.url+"/get_categories.php")
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setCollections(res);
    //     setLoadingCollection(false);
    //   });
    // setLoadingSupplier(true);
    fetch(services.url+"/get_suppliers.php")
      .then((res) => res.json())
      .then((res) => {
        
        setSuppliers(res);
        setLoadingSupplier(false);
      });
  }, []);
  
  const handleLogoUpload = (e) => {
    const payload = new FormData();
    payload.append("image", e.target.files[0]);

    axios
      .post(
        "https://api.imgbb.com/1/upload?key=bcc56bad76ece711720725d2a509d3dc",
        payload
      )
      .then((response) => {
        // console.log("response", response);
        // console.log("response url", response.data.data.image.url);
        setLogoUrl(response.data.data.image.url);
        // console.log("success");
      })
      .catch((error) => {
        console.log("error", error);
        alert("try another image agian");
      });
    // imgbbUploader()
  };
  const handleAddSingleMaterial = (e) => {
    e.preventDefault()
    
    
    const reference = document.getElementById('reference').value;
    const supplier = document.getElementById('supplier').value;
    const collection = document.getElementById('collection').value;
    const subcategory = document.getElementById('subcategory').value;
    
    const formData = new FormData();

    formData.append('logo', logoUrl);
    formData.append('type', 'single');
    formData.append('reference', reference);
    formData.append('supplier', supplier);
    formData.append('collection', collection);
    formData.append('subcategory', subcategory);

        
    if(logoUrl == null){
      Swal.fire({
        icon: "error",
        title: "Validation Error!",
        text: "Please select material logo.",
      });
    }
    else if(reference === '' || supplier === '' || collection === '' || subcategory === ''){
      Swal.fire({
        icon: "error",
        title: "Validation Error!",
        text: "Please fill out all input.",
      });
    }
    else{
      setLoading(true);
      fetch(services.url+"/create_material_single.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setLoading(false);
          
          Swal.fire({
            title: "Success!",
            text: "Material has been added successfully!",
            icon: "success",
            confirmButtonText: "Okay",
          });
          setLogoUrl(null);
          document.getElementById('reference').value='';
          document.getElementById('supplier').value='';
          document.getElementById('collection').value='';
          document.getElementById('subcategory').value='';
          
        });
    }

    

    /*
    const logo = logoUrl;
    const type = "single";
    const name = form.name.value;
    const reference = form.reference.value;
    const supplier = form.supplier.value;
    const collection = form.collection.value;
    const tags = form.tags.value;
    const price = form.price.value;
    const description = form.description.value;

    const data = {
      img: logo,
      type,
      name,
      reference,
      supplier,
      collection,
      tags,
      price,
      description,
    };

    fetch(services.url+"/create_material_single.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Success!",
          text: "Material has been added successfully!",
          icon: "success",
          confirmButtonText: "Okay",
        });
        form.reset();
      });
      */
  };

  
  const getCategory = (supplier_id) => {
    
    if(supplier_id !== ''){
      setLoading(true);
      let data = {supplier_id};
      fetch(services.url+"/get_material_category2.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res);
          setLoading(false);
          setCollections(res.materials_categories);
          setSubcategory([]);
          // console.log(subcategory);
        });
    }else{
      setCollections([]);
      setSubcategory([]);
    }
    
  }

  const getSubcategory = (category_id) => {
    
    if(category_id !== ''){
      setLoading(true);
      let data = {category_id};
      fetch(services.url+"/get_material_subcategory.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res);
          setLoading(false);
          setSubcategory(res);
          // console.log(subcategory);
        });
    }else{
      setSubcategory([]);
    }
    
  }


  // const handleImage = (e) => {
  //   e.preventDefault();

  //   let image = document.getElementById("photo_material").files[0];
    
  //   var reader = new FileReader();
  //   reader.onload = function (e) {
  //     // e.target.result   
  //     // console.log(e.target.result);
  //     setImgUrl(e.target.result);
  //   }
  //   reader.readAsDataURL(image);
  // }

  // const handleUpdateSubcategory = (e) => {
  //   e.preventDefault();
    
  //   let image_info = document.getElementById("photo_material").files[0];
  //   if(color == null || image_info == null){
  //   //   alert('Invalid Subcategory Image/Name');
  //       Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Invalid Subcategory Image/Name!",
  //       });
  //   }else{ 
  //     var len = parseInt(materials.length) + parseInt(1);
  //     console.log(len);
  //     setMaterials([
  //       ...materials,
  //       { id: len, name: color, image: imgUrl}
  //     ]);

  //     setTimeout(() => {
  //       let image_name = image_info.name;
  //       let image_type = image_info.type;
  //       // Create a new File object
  //       const myFile = new File([image_info], image_name, {
  //           type: image_type,
  //           lastModified: new Date(),
  //       });
  //       console.log(myFile);
  //       const dataTransfer = new DataTransfer();
  //       dataTransfer.items.add(myFile);
  //       console.log('id = '+len);
  //       document.getElementById("mat_img-"+len).files = dataTransfer.files;

  //       console.log(len);

  //       setImgUrl(null);
  //       setColor(null);
  //       document.getElementById("photo_material").value = null;
  //       document.getElementById('color').value = '';
  //     }, 100);
      
  //   }
    
  // }

  // const handleRemoveMaterial = (e) => {
  //   e.preventDefault();
  //   const name = e.target.getAttribute("name")
  //   setMaterials(materials.filter(item => item.name !== name));
  // }

  // const handelUpdateName = (index, preName, newName) => {
  //   // console.log(index + ' => ' + preName + ' -> '+newName);
  //   let newArr = [...materials];
  //   newArr[index].name = newName; 
  //   setMaterials(newArr);
  // }

  return (
    <div>
      <TopBar title="Add Single Material"/>
      
      {isLoading ? <LoadingSpinner/> : ''}

      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Material Details</p>
        </div>
        {/* <form
          onSubmit={handleAddSingleMaterial}
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        > */}
          <div className="flex md:flex-row flex-col gap-5 justify-between items-start">
            <div className="lg:w-[2/12] md:w-1/2 w-full">
              <p className="text-blue font-bold">Photo Material *</p>
              {logoUrl && (
                <img
                  src={logoUrl}
                  alt="logo"
                  name="logo"
                  className="w-full h-auto rounded-[3px]"
                />
              )}
              <label
                htmlFor="logo"
                className="logo-uploader block text-center mt-4"
              >
                <input
                  type="file"
                  name=""
                  id="logo"
                  onChange={handleLogoUpload}
                />
                <span className="text-sm text-gray-400">
                  Drag and drop or click here to select file
                </span>
              </label>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">Reference *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Reference Name"
                  name="reference"
                  required
                  id="reference"
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Supplier *</p>
                <select
                  name="supplier"
                  className="w-full p-2 border rounded"
                  id="supplier"
                  onChange={(e)=>getCategory(e.target.value)}
                >
                  {/* <option value="MEET&CO">MEET&CO</option>
                  <option value="MEET&CO">JANNO</option>
                  <option value="MEET&CO">BRANDOM</option> */}
                  <option value="">--Select Supplier--</option>
                  {loadingSupplier ? (
                    <option value="" disabled>
                      Loading Supplier
                    </option>
                  ) : (
                    suppliers.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.companyName}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">
                  Collection Material *
                </p>
                <select
                  name="collection"
                  className="w-full p-2 border rounded"
                  id="collection"
                  onChange={(e)=>getSubcategory(e.target.value)}
                >
                  <option value="">--Select Collection--</option>
                  {
                    collections.map((collection) => (
                      <option key={collection.id} value={collection.id}>
                        {collection.categoryName}
                      </option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Sub Category *</p>
                <select
                  name="subcategory"
                  className="w-full p-2 border rounded"
                  id="subcategory"
                  required
                >
                  <option value="">--Select One--</option>
                  {subcategory.map((subcategory) => (
                    <option value={subcategory.subcategory_id}>
                      {subcategory.subcategory_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* <p className="text-blue pt-5 font-bold mb-4">
            Material
          </p>
          <div className="flex md:flex-row flex-col gap-5 justify-between items-start">
            <div className="col-md-6">
              <div className="flex justify-start items-center gap-4">
                <div className="photo">
                  <label htmlFor="" className="text-xs text-blue">
                    Photo Material
                  </label>
                  <input type="file" name="photo_material" id="photo_material" onChange={handleImage}/>
                  <label
                    htmlFor="photo_material"
                    className="file-label text-xs block border border-dashed p-4 mt-3 w-[150px] text-center"
                  >
                    Drag and drop or click here to select file
                  </label>
                </div>

                <div className="flex border rounded overflow-hidden w-[300px] mt-4">
                  <input
                    type="text"
                    className="w-full p-2 border-r"
                    placeholder="Color"
                    name="color"
                    id="color"
                    onChange={e => setColor(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={handleUpdateSubcategory}
                    className="btn-blue bg-[#4D43B5] text-white px-4 py-2"
                  >
                    Add
                  </button>
                </div>
                
              </div>
            </div>
          </div> */}
          {/* <div className="flex flex-wrap justify-start items-center gap-2">
            { materials.map((material, index)=>(
              <AddNewMaterialTypeCard key={index} updateName={(e)=>{ handelUpdateName(e.target.getAttribute('data-ind'), e.target.getAttribute('id'), e.target.getAttribute('value')) }} closeFunction={handleRemoveMaterial} materials={material} index={index}/>
            )) }
          </div> */}
          <div className="mt-20 text-center">
            <button type="button" onClick={handleAddSingleMaterial} className="btn-blue px-10">
              Save
            </button>
          </div>
        {/* </form> */}
      </div>
      <Footer/>
    </div>
  );
};

export default AddNewMaterial;
