import React, {useState, useEffect} from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser, FaDollarSign } from "react-icons/fa";
import { Link, useParams, useNavigate } from "react-router-dom";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import AllProjectsSupplierDetails from "../../components/project-supplier-details";
import services from "../../components/services";
import Pagination from "../../components/pagination";
import fileIcon from "../../assets/img/file_icon.png";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/Loading_spinner";

const ProjectsDetails = () => {

    const [projects, setProjects] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(8);
    const [dataLength, setDataLength] = useState(null);
    const [projectFound, setProjectFound] = useState(false);
    const [execute, setExecute] = useState(false);
    const [checkRequest, setCheckRequest] = useState(false);
    const [projectProduct, setProjectProduct] = useState([]);
    const [projectCustomProduct, setProjectCustomProduct] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [projectIds, setProjectIds] = useState([]);
    const [customProductds, setCustomProductIds] = useState([]);
    const [projectCustomProductIds, setProjectCustomProductIds] = useState([]);
    const [projectTotalPrice, setProjectTotalPrice] = useState([]);
    const [new_request, setNewRequest] = useState(null);
    const [send_qoute, setSendQoute] = useState(null);
    const [isValidation, setValidation] = useState(null);
    const [process, setProcess] = useState(null);

    const {id} = useParams();

    const navigate = useNavigate();

    const getSupplierProjectDetails = () => {
        
        fetch(services.url+"/view_supplier_project_details.php?id="+id)
          .then((res) => res.json())
          .then((res) => {
            setExecute(true);
            setLoading(false);
            if(res.found === 1){
              setCheckRequest(true);
              setNewRequest(res.project[0].new_request);
              setSendQoute(res.project[0].send_qoute);
              setValidation(res.project[0].validation);
              setProcess(res.project[0].process);
              setProjects(res.project);
              setProjectIds(res.project_id);
              setProjectProduct(res.project_product);
              setProjectCustomProduct(res.project_custom_product);
              setCustomProductIds(res.custom_product_ids);
              setProjectCustomProductIds(res.project_custom_product_ids);
              console.log(res);
              // console.log(res.project_id);
              // console.log(res.project_product);
              // console.log(res.project_custom_product);
              // console.log(res.custom_product_ids);
              // console.log(res.project_custom_product_ids);
              const sum1 = res.project_custom_product.reduce((accumulator, object)=>{
                let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
                return accumulator + parseFloat(totalPrice);
              },0);
              const sum2 = res.project_product.reduce((accumulator, object)=>{
                let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
                return accumulator + parseFloat(totalPrice);
              },0);
              let total = sum1+sum2;
              setTotalPrice(total);
    
              const projectTotal = [];
    
              res.project_id.forEach(val=>{
                // console.log(val);
                var pcp = res.project_custom_product.filter((item)=>item.project_id == val);
                var projectSum = pcp.reduce((accumulator, object)=>{
    
                    let totalPrice = object.totalPrice === '' ? 0 : object.totalPrice;
                    return accumulator + parseFloat(totalPrice);
                    
                },0);
                var new_project_total = {project_id:val, totalPrice:projectSum};
                projectTotal.push(new_project_total);
              })
              
              setProjectTotalPrice(projectTotal);
    
            }else{
              setCheckRequest(false);
            }
            // console.log(res);
          });
      }

    useEffect(() => {
        setLoading(true);
        getSupplierProjectDetails();
        // getProjects(page);
    
        // fetch("https://sensar.vercel.app/api/v1/projects", {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // })
        //   .then((res) => res.json())
    
        //   .then((res) => {
        //     setProjects(res);
        //   });
      }, []);

      const validation = (id) => {
        // alert(id);
        
        Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            
            if (result.isConfirmed) {

                setLoading(true);

                let user_role = localStorage.getItem("sensar_user_role");
                let user_role_id = localStorage.getItem("sensar_user_role_id");

                let data = {
                  id,
                  user_role,
                  user_role_id,
                  projectIds,
                  url:services.baseUrl
                }

                fetch(services.url+"/project_validate.php",{
                  method: "POST",
                  // headers: {
                  //   "Content-Type": "application/json",
                  // },
                  body: JSON.stringify(data),
                })
                .then((res) => res.json())
                .then((res) => {
                    setLoading(false);
                    Swal.fire({
                        title: "Success!",
                        text: "Project has been validated successfully!",
                        icon: "success",
                        confirmButtonText: "Okay",
                      }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/dashboard');
                        }
                        
                      });
                })
            }
        })
      }

  const requestUpdate = (id) =>{
    fetch(services.url+"/get_project_for_update.php?id="+id)
    .then((res) => res.json())
    .then((res) => {
        console.log(res);
        let projectData = res.project[0];
        const project = {
          requestType:'update',
          projectId:projectData.id,
          companyName:projectData.companyName,
          projectName:projectData.projectName,
          deliveryAddress:projectData.deliveryAddress,
          province:projectData.province,
          supplierName:projectData.supplierName,
          startDate:projectData.startDate,
          endDate:projectData.endDate,
          countryName:projectData.countryName,
          categoryProduct:projectData.categoryProduct,
          projectDescription:projectData.projectDescription
        }
        console.log(project);

        localStorage.setItem("project", JSON.stringify(project));

        let customProductData = res.project_custom_product;

        let customProductDataArray = [];
        
        customProductData.forEach(val=>{

          let new_custom_product = {
            index: val.id,
            original_file_name: val.file,
            file: val.file,
            model: val.model,
            dimension: val.dimension,
            material: val.material,
            color: val.color,
            qty: val.qty,
            note: val.note,
            cbm: val.cbm,
            unitPrice: val.unitPrice,
            totalPrice: val.totalPrice,
            commentaire: val.commentaire
          }

          customProductDataArray.push(new_custom_product);
        })
        console.log(customProductDataArray);

        localStorage.setItem("custom_product", JSON.stringify(customProductDataArray));

        let projectProjectData = res.project_product;
        // let customProjectProduct = {};
        // let projectProductArray = [];
        projectProjectData.forEach(val=>{
          let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
          let load_material_data = JSON.parse(localStorage.getItem("material_data"));
          if(val.isCustom == 0){
            
            if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
              let load_new_sub_categories = Array();
              let products = Array();
              let new_product = {productId:val.productId, productImage:val.productImage, product_mesurment_value:'', product_mesurment_qty:'', model:val.model, metarial:[], color:[], product_mesurment:[{measurementValue:val.measurement, qty:val.measurement_qty, isCustom:0, model:val.model, metarial:[], color:[], note: val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};

              products.push(new_product);

              let new_sub_category = {subcategoryId:val.subcategoryId, selected:true,products};
              
              load_new_sub_categories.push(new_sub_category);

              localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));


            }else{

              var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == val.subcategoryId);

              if(get_sub_cat_index >= 0){

                var products = load_sub_categories[get_sub_cat_index]['products'];
                var get_product_index = products.findIndex(el => el.productId == val.productId);

                if(get_product_index >= 0){

                  var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
                  if(product_mesurment.length > 0){
                    var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == val.measurement);
                    if(get_product_mesurment_index >= 0){
                      load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=val.measurement_qty;
                    }else{
                      var more_product_mesurment = {measurementValue:val.measurement, qty:val.measurement_qty, isCustom:0, model:val.model, metarial:[], color:[], note: val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire};
                      product_mesurment.push(more_product_mesurment);
                      load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
                    }
                  }else{
                    var more_product_mesurment = {measurementValue:val.measurement, qty:val.measurement_qty, isCustom:0, model:val.model, metarial:[], color:[], note: val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire};
                    product_mesurment.push(more_product_mesurment);
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
                  }

                }else{
                  let new_product = {productId:val.productId, productImage:val.productImage, product_mesurment_value:'', product_mesurment_qty:'', model:val.model, metarial:[], color:[], product_mesurment:[{measurementValue:val.measurement, qty:val.measurement_qty, isCustom:0, model:val.model, metarial:[], color:[], note: val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                  products.push(new_product);
                  load_sub_categories[get_sub_cat_index]['products'] = products;
                }

              }else{
                let products = Array();
                let new_product = {productId:val.productId, productImage:val.productImage, product_mesurment_value:'', product_mesurment_qty:'', model:val.model, metarial:[], color:[], product_mesurment:[{measurementValue:val.measurement, qty:val.measurement_qty, isCustom:0, model:val.model, metarial:[], color:[], note: val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                products.push(new_product);
                let new_sub_category = {subcategoryId:val.subcategoryId, selected:true,products};
                
                load_sub_categories.push(new_sub_category);

              }
              
              localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

            }
            
          }else{

            if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

              let load_new_sub_categories = Array();
              let products = Array();
              let new_product = {productId:val.productId, productImage:val.productImage, product_mesurment_value:val.measurement, product_mesurment_qty:val.measurement_qty, model:val.model, metarial:[], color:[], product_mesurment:[], note:val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire};
              products.push(new_product);
              let new_sub_category = {subcategoryId:val.subcategoryId, selected:true,products};
              
              load_new_sub_categories.push(new_sub_category);

              localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

            }else{

              var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == val.subcategoryId);

              if(get_sub_cat_index >= 0){

                var products = load_sub_categories[get_sub_cat_index]['products'];
                var get_product_index = products.findIndex(el => el.productId == val.productId);

                if(get_product_index >= 0){

                  load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = val.measurement;
                  load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = val.measurement_qty;
                  

                }else{
                  let new_product = {productId:val.productId, productImage:val.productImage, product_mesurment_value:val.measurement, product_mesurment_qty:val.measurement_qty, model:val.model, metarial:[], color:[], product_mesurment:[], note:val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire};
                  products.push(new_product);
                  load_sub_categories[get_sub_cat_index]['products'] = products;
                }

              }else{
                let products = Array();
                let new_product = {productId:val.productId, productImage:val.productImage, product_mesurment_value:val.measurement, product_mesurment_qty:val.measurement_qty, model:val.model, metarial:[], color:[], product_mesurment:[], note:val.note, cbm:val.cbm, unitPrice:val.unitPrice, totalPrice:val.totalPrice, commentaire:val.commentaire};
                products.push(new_product);
                let new_sub_category = {subcategoryId:val.subcategoryId, selected:true,products};
                
                load_sub_categories.push(new_sub_category);

              }
              
              localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

            }

          }

          val.product_materials.forEach(val2=>{

            if(load_material_data === null || typeof load_material_data === "undefined"){

              var new_material_data = [{
                product_id:val.productId,
                material_data: [{index:val2.index, subCatId:val2.subCatId, subCatName:val2.subCatName, color:val2.color, name:val2.name, customColor: val2.customColor, sameImage: val2.sameImage}]
              }];
  
              localStorage.setItem("material_data", JSON.stringify(new_material_data));

            }else{

              var get_product_index = load_material_data.findIndex(el => el.product_id == val.productId);
              if(get_product_index >= 0){
                // console.log('exits');
                let materialData = load_material_data[get_product_index].material_data;
                if(materialData.length > 0){
                  let getSameMaterial = load_material_data[get_product_index].material_data.findIndex(el => el.subCatId == val2.subCatId);
                  if(getSameMaterial >= 0){
                    load_material_data[get_product_index].material_data[getSameMaterial] = {index:val2.index, subCatId:val2.subCatId, subCatName:val2.subCatName, color:val2.color, name:val2.name, customColor: val2.customColor, sameImage: val2.sameImage};
                  }else{
                    materialData.push({index:val2.index, subCatId:val2.subCatId, subCatName:val2.subCatName, color:val2.color, name:val2.name, customColor: val2.customColor, sameImage: val2.sameImage});
                    load_material_data[get_product_index].material_data = materialData;
                  }
                  
                }else{
                  load_material_data[get_product_index].material_data = [{index:val2.index, subCatId:val2.subCatId, subCatName:val2.subCatName, color:val2.color, name:val2.name, customColor: val2.customColor, sameImage: val2.sameImage}];
                }
                localStorage.setItem("material_data", JSON.stringify(load_material_data));
              }else{
                
                var new_material_data = {
                  product_id:val.productId,
                  material_data: [{index:val2.index, subCatId:val2.subCatId, subCatName:val2.subCatName, color:val2.color, name:val2.name, customColor: val2.customColor, sameImage: val2.sameImage}]
                };

                load_material_data.push(new_material_data);
    
                localStorage.setItem("material_data", JSON.stringify(load_material_data));
                
              }

            }

          })

        })
        navigate('/projects/add-new/step-2');
    })
  }

  return (
    <div className="customers">
    <TopBar title="Project/" userNav={false} subtitle={projects[0]}/>
       <div className="form mt-5">
        <form
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          
          <div className="">
            {projectCustomProduct.length == 0 ?
            ''
              :
              <>
                <h2 style={{fontWeight:'bold', textDecoration:'underline', color: '#52637a'}}>Custom product : </h2>
                <div class="table-responsive">
                  <table className="table project_product_box" cellspacing="0">
                    <thead>
                      <tr className="project_product_header">
                        <th width="60px">Item</th>
                        <th width="100px">File technique</th>
                        <th width="100px">Model NO.</th>
                        <th width="145px">Dimension(mm)</th>
                        <th width="90px">Material</th>
                        <th width="90px">Color</th>
                        <th width="90px">Quantity</th>
                        <th width="70px">CBM Total</th>
                        <th width="90px">Unit price EXW USD</th>
                        <th width="90px">Total price USD</th>
                        <th width="200px">Note</th>
                        <th width="200px">Commentaire</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectCustomProduct.map((projectCustomProduct,key)=>(
                          <tr className="project_product_item" key={key}>
                            <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                              {key+1}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <label>
                                <img style={{margin:'auto', width:'40px'}}
                                  src={fileIcon}
                                  alt=""
                                />
                              </label>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.model}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.dimension}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.material}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.color}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.qty}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectCustomProduct.cbm}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                {projectCustomProduct.unitPrice}
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                {projectCustomProduct.totalPrice}
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                                {projectCustomProduct.note}
                            </td>
                            <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                                {projectCustomProduct.commentaire}
                            </td>
                          </tr>
                        ))
                      }              
                    </tbody>
                  </table>
                </div>                
              </>
              }
            {
              projectProduct.length == 0 ? ''
              :
              <>
                <br/>
                <h2 style={{fontWeight:'bold', textDecoration:'underline', color: '#52637a'}}>Product : </h2>
                <div class="table-responsive">
                  <table className="table project_product_box" cellspacing="0">
                    <thead>
                      <tr className="project_product_header">
                        <th>Item</th>
                        <th>Picture</th>
                        <th>Model NO.</th>
                        <th>Dimension(mm)</th>
                        <th>Material</th>
                        <th>Quantity</th>
                        <th>CBM Total</th>
                        <th>Unit price EXW USD</th>
                        <th>Total price USD</th>
                        <th>Note</th>
                        <th>Commentaire</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectProduct.map((projectProduct, key)=>(
                          <tr className="project_product_item" key={key}>
                            <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                              {key+1}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <img src={projectProduct.productImage}/>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.model ==='' ? 'empty' : projectProduct.model}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.measurement}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {
                                <div className="product_material">
                                {projectProduct.product_materials.map((product_materials)=>(
                                  <>
                                  <div className="product_material_data_title">
                                    {product_materials.subCatName}
                                  </div>
                                  <div className="product_material_data_item">
                                    <div className="product_material_data_name">{product_materials.name}</div>
                                  </div>
                                  </>
                                ))
                                }
                                </div>
                              }
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.measurement_qty}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.cbm}
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                {projectProduct.unitPrice}
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              <div className="price-box">
                                <FaDollarSign/>
                                {projectProduct.totalPrice}
                              </div>
                            </td>
                            <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                              {projectProduct.note}
                            </td>
                            <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                              {projectProduct.commentaire}
                            </td>
                          </tr>
                        ))
                      }              
                    </tbody>
                  </table>
                </div>
              </>
            }
            
          </div>

          <div className="text-right mt-7" style={{color: '#52637a'}}>
            <b>Total price USD : ${totalPrice}</b>
            <br/><br/>
          </div>
          {
            send_qoute == 1 && isValidation == 0 && process == 0 ?
            <div className="text-center mt-10">
              <button
              type="button"
              className="btn-blue" onClick={()=>requestUpdate(id)} style={{backgroundColor:"transparent", border:"1px solid blue", color:"black", width:"200px", padding:"15px 10px", marginRight:"10px"}}
              >
                Request Update
              </button>
              <button
                type="button"
                className="btn-blue px-10"
                onClick={()=>validation(id)}
                disabled={new_request == 0 ? false:true}
              >
                Validation
              </button>
            </div>:''
          }
          
        </form>
        {loading ? <LoadingSpinner/> : ""}
      </div>
      <Footer/>
    </div>
  );
};

export default ProjectsDetails;
