import React from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { returnPaginationRange } from "../../utils/appUtils";

const Pagination = (props) => {

    let array = returnPaginationRange(props.totalPage, props.page, props.limit, props.siblings);

    return (
        <div className="flex md:flex-row flex-col md:gap-0 gap-3 md:pb-0 pb-5 w-full mt-5 justify-between items-center">
          <p className="text-sm pinkish">
            Showing <span className="text-blue">{props.start}-{props.end}</span> from{" "}
            <span className="text-blue">{props.totalData}</span> data
          </p>
          <div className="pagination text-right flex justify-end items-center gap-3">
            {props.page === 1 ?
             <button style={{cursor:"no-drop"}} className="">
                <FaCaretLeft className="text-3xl pinkish" />
            </button>
             : 
             <button onClick={()=> props.onPageChange("pre")} className="">
              <FaCaretLeft className="text-3xl pinkish" />
            </button>}
            
            {array.map(value=>{
                if(value === props.page){
                    return (
                        <button key={value} className="btn-grey flex justify-center items-center w-[40px] h-[40px] text-white text-3xl">
                            {value}
                        </button>
                    )
                }else{
                    return (
                        <button key={value} onClick={()=> props.onPageChange(value)} className="btn-blue flex justify-center items-center w-[40px] h-[40px] text-white text-3xl">
                            {value}
                        </button>
                    )
                }
            })}
            {props.page === props.totalPage ?
             <button style={{cursor:"no-drop"}} className="">
                <FaCaretRight className="text-3xl pinkish" />
            </button>
             : 
             <button onClick={()=> props.onPageChange("next")} className="">
              <FaCaretRight className="text-3xl pinkish" />
            </button>}
          </div>
        </div>
    )
}

export default Pagination;