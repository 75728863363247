// import { Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  FaBell,
  FaCog,
  FaPlus,
  FaTimes,
  FaUpload,
  FaUser,
  FaAngleUp,
  FaAngleDown
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'antd';
import Swal from "sweetalert2";
import services from "../../components/services";
import '../../assets/css/add_project.css';
// import fileIcon from "../../assets/img/file_icon.png";
import fileIcon from "../../assets/img/file_icon.png";
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import Spinner from "../../components/spinner";

const Step2 = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = React.useState(false);
  const [products, setPorduct] = useState([]);
  const [material, setMaterial] = useState([]);
  const [customProduct, setCustomProduct] = useState([]);
  const [modalView, setModalView] = useState(false);
  const [foundProductDatabase, setFoundProductDatabase] = useState(false);
  const [searchProduct, setSearchProduct] = useState(false);
  const [productDatabase, setProductDatabase] = useState({});
  const [project, setProject] = useState({});

  useEffect(() => {
    
    let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

    let project = JSON.parse(localStorage.getItem('project'));
    setProject(project);
    
    // console.log(subcategories);
    let product = [];
    if(subcategories === null || typeof subcategories === "undefined"){

    }else{
      subcategories.forEach((val,key) => {
        // products.push(val.products);
        val.products.forEach((val2,key2)=>{
          // console.log(val2);
          val2.product_mesurment.forEach((val3,key3)=>{
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
              product.push(new_product_object);
              // console.log(val3);
          })
          if(val2.product_mesurment_value !== ''){
            var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
            product.push(new_product_object);
          }
        })
        // console.log(val.products);
      })
    }

    let load_material_data = JSON.parse(localStorage.getItem("material_data"));
    if (load_material_data === null || typeof load_material_data === "undefined") {
      // console.log(load_material_data);
      
    } else { 
      var material_data = [];
      load_material_data.forEach((val) => {
        // console.log(val);
        var new_material_data = val;
        material_data.push(new_material_data);
      })
      setMaterial(material_data);
      
    }
    
    setPorduct(product);

    let load_custom_product_data = JSON.parse(localStorage.getItem("custom_product"));
    // console.log(load_custom_product_data);
    if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

    }else{
      var custom_product_data = [];
      load_custom_product_data.forEach((val) => {
        // console.log(val);
        custom_product_data.push(val);
      })
      setCustomProduct(custom_product_data);

    }

    // console.log(products);

  }, []);

  const handleCustomProductModel = (e) => {
    let index = e.currentTarget.id;
    index = index.split('-')[1];
    let value = e.currentTarget.value;

    setCustomProduct(oldValue=> {
      return oldValue.filter((item)=>{
        if(item.index == index){
          item.model = value;
          // console.log(item.qty);
          var load_custom_product_data = JSON.parse(localStorage.getItem('custom_product'));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          }else{
            var get_custom_product_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_custom_product_index >=0){
              
              load_custom_product_data[get_custom_product_index].model = value;
              
              localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
              
            }
          }
        }

        return true;
      });
    })

  }

  const handleCustomProductDimension = (e) => {
    let index = e.currentTarget.id;
    index = index.split('-')[1];
    let value = e.currentTarget.value;

    setCustomProduct(oldValue=> {
      return oldValue.filter((item)=>{
        if(item.index == index){
          item.dimension = value;
          // console.log(item.qty);
          var load_custom_product_data = JSON.parse(localStorage.getItem('custom_product'));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          }else{
            var get_custom_product_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_custom_product_index >=0){
              
              load_custom_product_data[get_custom_product_index].dimension = value;
              
              localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
              
            }
          }
        }

        return true;
      });
    })

  }

  const handleCustomProductMaterial = (e) => {
    let index = e.currentTarget.id;
    index = index.split('-')[1];
    let value = e.currentTarget.value;

    setCustomProduct(oldValue=> {
      return oldValue.filter((item)=>{
        if(item.index == index){
          item.material = value;
          // console.log(item.qty);
          var load_custom_product_data = JSON.parse(localStorage.getItem('custom_product'));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          }else{
            var get_custom_product_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_custom_product_index >=0){
              
              load_custom_product_data[get_custom_product_index].material = value;
              
              localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
              
            }
          }
        }

        return true;
      });
    })

  }

  const handleCustomProductColor = (e) => {
    let index = e.currentTarget.id;
    index = index.split('-')[1];
    let value = e.currentTarget.value;

    setCustomProduct(oldValue=> {
      return oldValue.filter((item)=>{
        if(item.index == index){
          item.color = value;
          // console.log(item.qty);
          var load_custom_product_data = JSON.parse(localStorage.getItem('custom_product'));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          }else{
            var get_custom_product_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_custom_product_index >=0){
              
              load_custom_product_data[get_custom_product_index].color = value;
              
              localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
              
            }
          }
        }

        return true;
      });
    })

  }

  const handleCustomProductQty = (e) => {
    let index = e.currentTarget.id;
    index = index.split('-')[1];
    let value = e.currentTarget.value;

    setCustomProduct(oldValue=> {
      return oldValue.filter((item)=>{
        if(item.index == index){
          item.qty = value;
          // console.log(item.qty);
          var load_custom_product_data = JSON.parse(localStorage.getItem('custom_product'));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          }else{
            var get_custom_product_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_custom_product_index >=0){
              
              load_custom_product_data[get_custom_product_index].qty = value;
              
              localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
              
            }
          }
        }

        return true;
      });
    })

  }

  const handleCustomProductNote = (e) => {
    let index = e.currentTarget.id;
    index = index.split('-')[1];
    let value = e.currentTarget.value;

    setCustomProduct(oldValue=> {
      return oldValue.filter((item)=>{
        if(item.index == index){
          item.note = value;
          // console.log(item.qty);
          var load_custom_product_data = JSON.parse(localStorage.getItem('custom_product'));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          }else{
            var get_custom_product_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_custom_product_index >=0){
              
              load_custom_product_data[get_custom_product_index].note = value;
              
              localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
              
            }
          }
        }

        return true;
      });
    })

  }
  
  const handelQty = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let value = e.currentTarget.value;
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let isCustom = id.split('-')[3];
    let measurementValue = document.getElementById('mv_'+id).innerHTML;
    
    setPorduct(oldValue => {
      return oldValue.filter((item)=>{
        if(item.measurementValue == measurementValue){
          item.qty = value;
          // console.log(item.qty);
          var load_subcategories = JSON.parse(localStorage.getItem('sub_categories'));
          if(load_subcategories === null || typeof load_subcategories === "undefined"){

          }else{
            var get_sub_cat_index = load_subcategories.findIndex(el => el.subcategoryId == subcategoryId);
            if(get_sub_cat_index >=0){
              var products_array = load_subcategories[get_sub_cat_index].products;
              var get_product_index = products_array.findIndex(el => el.productId == productId);
              var product_mesurment = load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
              if(isCustom == 0){
                var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
                load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index].qty = value;

              }else{
                load_subcategories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = value;
              }

              localStorage.setItem("sub_categories", JSON.stringify(load_subcategories));
              
            }
          }
        }

        return true;
      });
    });

  }
  
  const handelNote = (e) =>{
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let value = e.currentTarget.value;
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let isCustom = id.split('-')[3];
    let measurementValue = document.getElementById('mv_'+id).innerHTML;
    
    setPorduct(oldValue => {
      return oldValue.filter((item)=>{
        if(item.measurementValue == measurementValue){
          item.note = value;
          // console.log(item.qty);
          var load_subcategories = JSON.parse(localStorage.getItem('sub_categories'));
          // console.log(load_subcategories);
          if(load_subcategories === null || typeof load_subcategories === "undefined"){

          }else{
            var get_sub_cat_index = load_subcategories.findIndex(el => el.subcategoryId == subcategoryId);
            if(get_sub_cat_index >=0){
              var products_array = load_subcategories[get_sub_cat_index].products;
              var get_product_index = products_array.findIndex(el => el.productId == productId);
              var product_mesurment = load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
              if(isCustom == 0){
                var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
                load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index].note = value;

              }else{
                load_subcategories[get_sub_cat_index]['products'][get_product_index].note = value;
              }

              localStorage.setItem("sub_categories", JSON.stringify(load_subcategories));
              
            }
          }
        }

        return true;
      });
    });
  }

  const handelCustomRemove = (e) => {
    e.preventDefault();
    let id = e.currentTarget.id;
    let index = id.split('_')[1];
    let file = id.split('_')[2];
        
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      
      if (result.isConfirmed) {

        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);

        fetch(services.url+"/remove_custom_product_file.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: formData,
        })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          
          setCustomProduct(customProduct.filter(item => item.index != index));

          let load_custom_product_data = JSON.parse(localStorage.getItem("custom_product"));
          if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){

          }else{

            var get_index = load_custom_product_data.findIndex(el => el.index == index);
            if(get_index >=0){
              var new_array = load_custom_product_data.filter((item) => item.index != index);
              if(new_array.length == 0){
                localStorage.removeItem("custom_product");
              }else{
                localStorage.setItem("custom_product", JSON.stringify(new_array));
              }
            }
          }
        });

        
      }
    })
    
  }

  const handelRemove = (e) =>{
    e.preventDefault();
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let isCustom = id.split('-')[3];
    let measurementValue = document.getElementById('mv_'+id).innerHTML;

    // console.log(products);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      
      if (result.isConfirmed) {
        setPorduct(products.filter(item => item.measurementValue !== measurementValue));
        var load_subcategories = JSON.parse(localStorage.getItem('sub_categories'));
        let load_material_data = JSON.parse(localStorage.getItem("material_data"));
        if(load_subcategories === null || typeof load_subcategories === "undefined"){

        }else{
          var get_sub_cat_index = load_subcategories.findIndex(el => el.subcategoryId == subcategoryId);
          if(get_sub_cat_index >=0){
            var products_array = load_subcategories[get_sub_cat_index].products;
            var get_product_index = products_array.findIndex(el => el.productId == productId);
            var product_mesurment = load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
            
            if(isCustom == 0){

              var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
              if(get_product_mesurment_index >= 0){
                var new_measurement_array = product_mesurment.filter((item) => item.measurementValue !== measurementValue);
                load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = new_measurement_array;
              }

            }else{
              load_subcategories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = '';
              load_subcategories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = '';
            }

            if(load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'].length == 0 && load_subcategories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value === ''){

              // console.log('mesurement is zero');
              if(load_material_data === null || typeof load_material_data === "undefined"){

              }else{
                var new_material_data = load_material_data.filter((item) => item.product_id !== productId);
                // console.log(new_material_data);
                localStorage.setItem("material_data", JSON.stringify(new_material_data));
              }
              
              var new_product_array = products_array.filter((item) => item.productId !== productId);
              if(new_product_array.length == 0){
                var new_subcategories = load_subcategories.filter((item) => item.subcategoryId !== subcategoryId);
                if(new_subcategories.length == 0){
                  localStorage.removeItem('sub_categories');
                  localStorage.removeItem('material_data');
                }else{
                  localStorage.setItem("sub_categories", JSON.stringify(new_subcategories));
                }
              }else{
                load_subcategories[get_sub_cat_index].products = new_product_array;
                localStorage.setItem("sub_categories", JSON.stringify(load_subcategories));
              }
              
            }else{
              localStorage.setItem("sub_categories", JSON.stringify(load_subcategories));
            }
          }
          
        }
        // console.log(load_subcategories);
        /*
        setPorduct(products.filter(item => item.measurementValue !== measurementValue));
        
        var load_subcategories = JSON.parse(localStorage.getItem('sub_categories'));
        if(load_subcategories === null || typeof load_subcategories === "undefined"){

        }else{
          
          var get_sub_cat_index = load_subcategories.findIndex(el => el.subcategoryId == subcategoryId);
          if(get_sub_cat_index >=0){
            var products_array = load_subcategories[get_sub_cat_index].products;
            var get_product_index = products_array.findIndex(el => el.productId == productId);
            var product_mesurment = load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];

            if(isCustom == 0){

              var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
              if(get_product_mesurment_index >= 0){
                var new_measurement_array = product_mesurment.filter((item) => item.measurementValue !== measurementValue);
                load_subcategories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = new_measurement_array;
              }

            }else{
              load_subcategories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = '';
              load_subcategories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = '';
            }

            localStorage.setItem("sub_categories", JSON.stringify(load_subcategories));
            
          }
        }
        */
      }
    
    });

    
    
  }

  const handleCustomProductFile = (e) =>{
    if(e.target.files.length == 0){

    }else{
      let id = e.currentTarget.id;
      let index = id.split('-')[1];
      let old_file = id.split('-')[2];

      // console.log(e.currentTarget.id);
      // console.log(e.target.files[0]);

      let fileObject = e.target.files[0];

      setLoading(true);

      const formData = new FormData();
      formData.append('file', fileObject);
      formData.append('old_file', old_file);

      fetch(services.url+"/update_custom_product_file.php", {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: formData,
      })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setLoading(false);
        
        let load_custom_product_data = JSON.parse(localStorage.getItem("custom_product"));
        if(load_custom_product_data === null || typeof load_custom_product_data === "undefined"){


        }else{
          setCustomProduct(oldValue => {
            return oldValue.filter((item) => {
              if(item.index == index){
                item.original_file_name = res.original_file_name;
                item.file = res.file_name;
              }
              return true;
            });
          });

          var custom_product_index = load_custom_product_data.findIndex(el => el.index == index);
          
          load_custom_product_data[custom_product_index].original_file_name = res.original_file_name;
          load_custom_product_data[custom_product_index].file = res.file_name;
          localStorage.setItem("custom_product", JSON.stringify(load_custom_product_data));
        }
      });

      
    }
    
  }

  const gotoStep3 = () => {

    const formData = new FormData();
    let project = JSON.parse(localStorage.getItem('project'));
    var load_subcategories = JSON.parse(localStorage.getItem('sub_categories'));
    let load_material_data = JSON.parse(localStorage.getItem("material_data"));
    let load_custom_product_data = JSON.parse(localStorage.getItem("custom_product"));

    formData.append('project', JSON.stringify(project));
    formData.append('products', JSON.stringify(load_subcategories));
    formData.append('materials', JSON.stringify(load_material_data));
    formData.append('custom_product', JSON.stringify(load_custom_product_data));
    formData.append('url', services.baseUrl);
    

    setLoading(true);

    fetch(services.url+"/create_project.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setLoading(false);
        
        // Swal.fire({
        //   title: "Success!",
        //   text: "Project has been added successfully!",
        //   icon: "success",
        //   confirmButtonText: "Okay",
        // });
        
        setCustomProduct([]);
        setPorduct([]);
        localStorage.removeItem('project');
        localStorage.removeItem('sub_categories');
        localStorage.removeItem('material_data');
        localStorage.removeItem('custom_product');
        navigate('/projects/add-new/step-3');
        
      });

    // navigate("/projects/add-new/step-3");
  };

  /*
  fetch(services.url+"/get_product_database.php", {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
  })
  .then((res) => res.json())
  .then((res) => {

  })
  */
  
  $("#product_database").autocomplete({
    source: function(request,cb){
      let project = JSON.parse(localStorage.getItem('project'));
      let supplier = project.supplierName;
      fetch(services.url+"/get_product_database.php?supplier="+supplier+"&search_text="+request.term, {
        method: "GET",
        // headers: {
        //   "Content-Type": "application/json",
        // },
      })
      .then((res) => res.json())
      .then((res) => {
        let result;
        result = [
          {
            label: 'There is no matching product found for '+request.term,
            value: ''
          }
        ];
        let products = res.products
        if(products.length){
          result = $.map(products, function(obj){
            return {
              label: obj.productName,
              value: obj.productName,
              data: obj
            }
          })
        }
        // console.log(result);
        cb(result);
      })
    },
    search: function(event, ui){
      setSearchProduct(true);
    },
    open: function(event, ui){
      setSearchProduct(false);
    },
    select:function(event, selectedData){
      console.log(selectedData);
      if(selectedData && selectedData.item && selectedData.item.data){
        let productData = selectedData.item.data; 
        setFoundProductDatabase(true);
        setModalView(true);

        let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

        if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

        }else{
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == productData.subcategory_id);
          // console.log(get_sub_cat_index);
          if(get_sub_cat_index >= 0){
            var products = load_sub_categories[get_sub_cat_index]['products'];
            // console.log(products);
            products.forEach((val,key)=>{
              var subcategory_product_index = Object.values(productData).indexOf(val.productId);
              if(subcategory_product_index > -1){
                productData.selected = true;
                productData.custom_measurement = val.product_mesurment_value;
                productData.custom_measurement_qty = val.product_mesurment_qty;
                
                var product_measurement = val.product_mesurment;
                if(product_measurement.length > 0){
                  product_measurement.forEach((mval,mkey)=>{
                    var sub_categorie_product_measurement =  productData.measurement;
                    var sub_categorie_product_measurement_index = sub_categorie_product_measurement.findIndex(el => el.measurement == mval.measurementValue);

                    if(sub_categorie_product_measurement_index >=0){
                      productData.measurement[sub_categorie_product_measurement_index].qty = mval.qty;
                    }
                    // console.log(mval);
                  })
                }
              }
              
            })
          }
        }

        setProductDatabase(productData);
        // console.log(productData);

      }else{
        setFoundProductDatabase(false);
        setProductDatabase({});
        setTimeout(() => {
          document.getElementById("product_database").value = '';
        }, 100);
      }
    },
    autoFocus: true
  });

  const measurementInput = (e) => {
    let value = e.currentTarget.value;
    let id = e.currentTarget.id;
    // console.log(id);
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let measurementIndex = id.split('-')[2];
    let measurementValue = document.getElementById('smv_'+id).innerHTML;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let isCustom = 0;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    // if(value !== ''){
      // productDatabase.selected = true;
      // productDatabase.measurement[measurementIndex].qty = value;
    // }else{
    //   productDatabase.selected = false;
    //   productDatabase.measurement[measurementIndex].qty = value;
    // }
    // setProductDatabase(productDatabase);
    // console.log(productDatabase);
    
      setProductDatabase(oldValue=>({
        ...oldValue,
        measurement: oldValue.measurement.filter((item) => {
          if(item.measurement === measurementValue){
            item.qty = value;
          }
          return true;
        })
      }))
    

      let totalQty = 0;
      productDatabase.measurement.forEach(val=>{
        var qty = val.qty === '' || val.qty === null ? 0 : val.qty;
        totalQty += parseFloat(qty);
        // console.log(qty);
      })

      let customQty = productDatabase.custom_measurement_qty === '' || productDatabase.custom_measurement_qty === null ? 0 : productDatabase.custom_measurement_qty ;
      let netTotalQty = parseFloat(totalQty) + parseFloat(customQty);
      console.log(netTotalQty);

      let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

      if(netTotalQty > 0){
        setProductDatabase(oldValue=>({
          ...oldValue,
          selected:true,
        }))

        if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
          let load_new_sub_categories = Array();
          let products = Array();
          let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
          products.push(new_product);
          let new_sub_category = {subcategoryId, selected:true,products};
          
          load_new_sub_categories.push(new_sub_category);

          localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));
        }else{
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          if(get_sub_cat_index >= 0){
            
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
            // console.log(products);
            if(get_product_index >= 0){
              // console.log('product found');
              var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
              if(product_mesurment.length > 0){
                var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
                if(get_product_mesurment_index >= 0){
                  if(value === ''){
                    
                    var new_measurement_array = product_mesurment.filter((item) => item.measurementValue !== measurementValue);
                    // console.log(new_measurement_array);
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = new_measurement_array;
                  }else{
                    load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=value;
                  }
                  
                }else{
                  
                  var more_product_mesurment = {measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                  product_mesurment.push(more_product_mesurment);
                  load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
                }
              }else{
                  var more_product_mesurment = {measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                  product_mesurment.push(more_product_mesurment);
                  load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
              }
            }else{
              // console.log('product not found');
              let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              load_sub_categories[get_sub_cat_index]['products'] = products;
            }

          }else{
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty:value, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_sub_categories.push(new_sub_category);
          }
          
          localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
        }

      }else{
        setProductDatabase(oldValue=>({
          ...oldValue,
          selected:false,
        }))

        if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

        }else{
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          // console.log(get_sub_cat_index);
          var products_array = load_sub_categories[get_sub_cat_index].products;
          var new_product_array = products_array.filter((item) => item.productId !== productId);
          // console.log(new_product_array.length);
          if(new_product_array.length == 0){
            var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
            // console.log(new_sub_categories.length);
            if(new_sub_categories.length == 0){
              localStorage.removeItem("sub_categories");
            }else{
              localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
            }
          }else{
            load_sub_categories[get_sub_cat_index].products = new_product_array;
            localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
          }

          var load_material_data = JSON.parse(localStorage.getItem("material_data"));
          // console.log(material);
          // console.log(load_material_data);

          if (load_material_data === null || typeof load_material_data === "undefined") {

          }else{
            setMaterial(material.filter((item)=>{
              return item.product_id != productId;
            }));
    
            var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
            localStorage.setItem("material_data", JSON.stringify(new_material_data));
          }
        }
      }

      setTimeout(() => {
        let subcategories = JSON.parse(localStorage.getItem('sub_categories'));
  
        // console.log(subcategories);
        let product = [];
        if(subcategories === null || typeof subcategories === "undefined"){

        }else{
          subcategories.forEach((val,key) => {
            // products.push(val.products);
            val.products.forEach((val2,key2)=>{
              // console.log(val2);
              val2.product_mesurment.forEach((val3,key3)=>{
                  var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                  product.push(new_product_object);
                  // console.log(val3);
              })
              if(val2.product_mesurment_value !== ''){
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
                product.push(new_product_object);
              }
            })
            // console.log(val.products);
          })
        }

        let load_material_data = JSON.parse(localStorage.getItem("material_data"));
        if (load_material_data === null || typeof load_material_data === "undefined") {
          // console.log(load_material_data);
          
        } else { 
          var material_data = [];
          load_material_data.forEach((val) => {
            // console.log(val);
            var new_material_data = val;
            material_data.push(new_material_data);
          })
          setMaterial(material_data);
          
        }
        
        setPorduct(product);
      }, 100);

  }

  const increment_measurement = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let measurementIndex = id.split('-')[2];
    let measurementValue = document.getElementById('smv_'+id).innerHTML;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let isCustom = 0;
    let qty = productDatabase.measurement[measurementIndex].qty === '' ? 1 : parseInt(productDatabase.measurement[measurementIndex].qty)+parseInt(1);
    let model_no = e.currentTarget.getAttribute('data-model_no');
    
    productDatabase.measurement[measurementIndex].qty = qty;

    setProductDatabase(oldValue=>({
      ...oldValue,
      measurement: oldValue.measurement.filter((item) => {
        if(item.measurement === measurementValue){
          item.qty = qty;
        }
        return true;
      })
    }))
  

    let totalQty = 0;
    productDatabase.measurement.forEach(val=>{
      var qty = val.qty === '' || val.qty === null ? 0 : val.qty;
      totalQty += parseFloat(qty);
      // console.log(qty);
    })

    let customQty = productDatabase.custom_measurement_qty === '' || productDatabase.custom_measurement_qty === null ? 0 : productDatabase.custom_measurement_qty ;
    let netTotalQty = parseFloat(totalQty) + parseFloat(customQty);
    console.log(netTotalQty);

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
    console.log(load_sub_categories);
    if(netTotalQty > 0){
      
      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:true,
      }))

      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
        
        let load_new_sub_categories = Array();
        let products = Array();
        let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
        products.push(new_product);
        let new_sub_category = {subcategoryId, selected:true,products};
        
        load_new_sub_categories.push(new_sub_category);

        localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

      }else{
        
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);

        if(get_sub_cat_index >= 0){
          
          var products = load_sub_categories[get_sub_cat_index]['products'];
          var get_product_index = products.findIndex(el => el.productId == productId);

          if(get_product_index >= 0){
            
            var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
            if(product_mesurment.length > 0){
              var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
              
              if(get_product_mesurment_index >= 0){
                load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=qty;
              }else{
                
                var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
                product_mesurment.push(more_product_mesurment);
                load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
              }
            }else{
              var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              product_mesurment.push(more_product_mesurment);
              load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
            }

          }else{
            
            let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            load_sub_categories[get_sub_cat_index]['products'] = products;
          }

        }else{
          let products = Array();
          let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
          products.push(new_product);
          let new_sub_category = {subcategoryId, selected:true,products};
          
          load_sub_categories.push(new_sub_category);

        }
        
        localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

      }

    }else{

      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:false,
      }))

    }

    setTimeout(() => {
      let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

      // console.log(subcategories);
      let product = [];
      if(subcategories === null || typeof subcategories === "undefined"){

      }else{
        subcategories.forEach((val,key) => {
          // products.push(val.products);
          val.products.forEach((val2,key2)=>{
            // console.log(val2);
            val2.product_mesurment.forEach((val3,key3)=>{
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                product.push(new_product_object);
                // console.log(val3);
            })
            if(val2.product_mesurment_value !== ''){
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
              product.push(new_product_object);
            }
          })
          // console.log(val.products);
        })
      }

      let load_material_data = JSON.parse(localStorage.getItem("material_data"));
      if (load_material_data === null || typeof load_material_data === "undefined") {
        // console.log(load_material_data);
        
      } else { 
        var material_data = [];
        load_material_data.forEach((val) => {
          // console.log(val);
          var new_material_data = val;
          material_data.push(new_material_data);
        })
        setMaterial(material_data);
        
      }
      
      setPorduct(product);
    }, 100);
  }

  const decrement_measurement = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let measurementIndex = id.split('-')[2];
    let measurementValue = document.getElementById('smv_'+id).innerHTML;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let isCustom = 0;
    let qty = productDatabase.measurement[measurementIndex].qty === '' ? 0 : parseInt(productDatabase.measurement[measurementIndex].qty);
    let model_no = e.currentTarget.getAttribute('data-model_no');

    console.log(qty);

    if(parseInt(qty) > 0)
      qty--
    
    console.log(qty);

    productDatabase.measurement[measurementIndex].qty = qty;
    
    setProductDatabase(oldValue=>({
      ...oldValue,
      measurement: oldValue.measurement.filter((item) => {
        if(item.measurement === measurementValue){
          item.qty = qty;
        }
        return true;
      })
    }))
    
    

    let totalQty = 0;
    productDatabase.measurement.forEach(val=>{
      var qty = val.qty === '' || val.qty === null ? 0 : val.qty;
      totalQty += parseFloat(qty);
      // console.log(qty);
    })
    
    let customQty = productDatabase.custom_measurement_qty === '' || productDatabase.custom_measurement_qty === null ? 0 : productDatabase.custom_measurement_qty ;
    let netTotalQty = parseFloat(totalQty) + parseFloat(customQty);
    console.log(netTotalQty);

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    if(netTotalQty > 0){
      
      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:true,
      }))

      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

        let load_new_sub_categories = Array();
        let products = Array();
        let new_product = {productId, productImage, product_mesurment_value:'', product_mesurment_qty:'', model:model_no, metarial:[], color:[], product_mesurment:[{measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''}], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
        products.push(new_product);
        let new_sub_category = {subcategoryId, selected:true,products};
        
        load_new_sub_categories.push(new_sub_category);

        localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

      }else{

        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        var products = load_sub_categories[get_sub_cat_index]['products'];
        var get_product_index = products.findIndex(el => el.productId == productId);
            
        var product_mesurment = load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'];
        
        if(product_mesurment.length > 0){
          var get_product_mesurment_index = product_mesurment.findIndex(el => el.measurementValue == measurementValue);
          
          if(get_product_mesurment_index >= 0){
            if(qty == 0){
              var new_measurement_array = product_mesurment.filter((item) => item.measurementValue !== measurementValue);
              // console.log(new_measurement_array);
              load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = new_measurement_array;
            }else{
              load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'][get_product_mesurment_index]['qty']=qty;
            }
            
          }else{
            var more_product_mesurment = {measurementValue, qty, isCustom, model:model_no, metarial:[], color:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            product_mesurment.push(more_product_mesurment);
            load_sub_categories[get_sub_cat_index]['products'][get_product_index]['product_mesurment'] = product_mesurment;
          }
        }

        localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

      }

    }else{

      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:false,
      }))

      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

      }else{
        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        // console.log(get_sub_cat_index);
        var products_array = load_sub_categories[get_sub_cat_index].products;
        var new_product_array = products_array.filter((item) => item.productId !== productId);
        // console.log(new_product_array);
        if(new_product_array.length == 0){
          
          var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
          // console.log(new_sub_categories.length);
          if(new_sub_categories.length == 0){
            localStorage.removeItem("sub_categories");
          }else{
            localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
          }
          
        }else{
          load_sub_categories[get_sub_cat_index].products = new_product_array;
          localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
        }

        var load_material_data = JSON.parse(localStorage.getItem("material_data"));
        // console.log(material);
        // console.log(load_material_data);

        if (load_material_data === null || typeof load_material_data === "undefined") {

        }else{
          setMaterial(material.filter((item)=>{
            return item.product_id != productId;
          }));
  
          var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
          localStorage.setItem("material_data", JSON.stringify(new_material_data));
        }
      }

      

    }
    
    setTimeout(() => {
      let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

      // console.log(subcategories);
      let product = [];
      if(subcategories === null || typeof subcategories === "undefined"){

      }else{
        subcategories.forEach((val,key) => {
          // products.push(val.products);
          val.products.forEach((val2,key2)=>{
            // console.log(val2);
            val2.product_mesurment.forEach((val3,key3)=>{
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                product.push(new_product_object);
                // console.log(val3);
            })
            if(val2.product_mesurment_value !== ''){
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
              product.push(new_product_object);
            }
          })
          // console.log(val.products);
        })
      }

      let load_material_data = JSON.parse(localStorage.getItem("material_data"));
      if (load_material_data === null || typeof load_material_data === "undefined") {
        // console.log(load_material_data);
        
      } else { 
        var material_data = [];
        load_material_data.forEach((val) => {
          // console.log(val);
          var new_material_data = val;
          material_data.push(new_material_data);
        })
        setMaterial(material_data);
        
      }
      
      setPorduct(product);
    }, 100);

    
  }
  
  const measurementValueInput_custom = (e) => {

    let value = e.currentTarget.value;
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let measurementQty = document.getElementById('mcq_'+id).value;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    setProductDatabase(oldValue=>({
      ...oldValue,
      custom_measurement:value
    }))
    
    let totalQty = 0;
    productDatabase.measurement.forEach(val=>{
      var qty = val.qty === '' || val.qty === null ? 0 : val.qty;
      totalQty += parseFloat(qty);
      // console.log(qty);
    })
    
    let customQty = productDatabase.custom_measurement_qty === '' || productDatabase.custom_measurement_qty === null ? 0 : productDatabase.custom_measurement_qty ;
    let netTotalQty = parseFloat(totalQty) + parseFloat(customQty);
    console.log(netTotalQty);

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    if(netTotalQty > 0){
      
      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:true,
      }))

      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

        if(value !== ''){
          let load_new_sub_categories = Array();
          let products = Array();
          let new_product = {productId, productImage, product_mesurment_value:value, product_mesurment_qty:measurementQty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
          products.push(new_product);
          let new_sub_category = {subcategoryId, selected:true,products};
          
          load_new_sub_categories.push(new_sub_category);

          localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));
        }

      }else{

        if(value !== ''){
                
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          if(get_sub_cat_index >= 0){
            
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
            // console.log(products);
            if(get_product_index >= 0){
              // console.log('product found');
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = value;
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = measurementQty;
              
            }else{
              let new_product = {productId, productImage, product_mesurment_value:value, product_mesurment_qty:measurementQty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              load_sub_categories[get_sub_cat_index]['products'] = products;
            }

          }else{
            
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:value, product_mesurment_qty:measurementQty, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_sub_categories.push(new_sub_category);
          }
        }else{
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          if(get_sub_cat_index >= 0){
            
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
            // console.log(products);
            if(get_product_index >= 0){
              // console.log('product found');
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = '';
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = '';
              
            }

          }
        }
        
        
        localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

      }

    }else{

      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:false,
      }))

    }

    setTimeout(() => {
      let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

      // console.log(subcategories);
      let product = [];
      if(subcategories === null || typeof subcategories === "undefined"){

      }else{
        subcategories.forEach((val,key) => {
          // products.push(val.products);
          val.products.forEach((val2,key2)=>{
            // console.log(val2);
            val2.product_mesurment.forEach((val3,key3)=>{
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                product.push(new_product_object);
                // console.log(val3);
            })
            if(val2.product_mesurment_value !== ''){
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
              product.push(new_product_object);
            }
          })
          // console.log(val.products);
        })
      }

      let load_material_data = JSON.parse(localStorage.getItem("material_data"));
      if (load_material_data === null || typeof load_material_data === "undefined") {
        // console.log(load_material_data);
        
      } else { 
        var material_data = [];
        load_material_data.forEach((val) => {
          // console.log(val);
          var new_material_data = val;
          material_data.push(new_material_data);
        })
        setMaterial(material_data);
        
      }
      
      setPorduct(product);
    }, 100);

  }

  const measurementInput_custom = (e) => {
    let value = e.currentTarget.value;
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let measurementValue = document.getElementById('mcv_'+id).value;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    productDatabase.custom_measurement_qty = value;
    
    let totalQty = 0;
    productDatabase.measurement.forEach(val=>{
      var qty = val.qty === '' || val.qty === null ? 0 : val.qty;
      totalQty += parseFloat(qty);
      // console.log(qty);
    })
    
    let customQty = productDatabase.custom_measurement_qty === '' || productDatabase.custom_measurement_qty === null ? 0 : productDatabase.custom_measurement_qty ;
    let netTotalQty = parseFloat(totalQty) + parseFloat(customQty);
    console.log(netTotalQty);

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
    
    
    if(netTotalQty > 0){
      
      productDatabase.selected = true;

      setProductDatabase(productDatabase);

      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

        if(value !== ''){
          let load_new_sub_categories = Array();
          let products = Array();
          let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
          products.push(new_product);
          let new_sub_category = {subcategoryId, selected:true,products};
          
          load_new_sub_categories.push(new_sub_category);

          localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));
        }

      }else{

        if(value !== ''){
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          if(get_sub_cat_index >= 0){
            
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
            // console.log(products);
            if(get_product_index >= 0){
              // console.log('product found');
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = measurementValue;
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = value;
              
            }else{
              let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              load_sub_categories[get_sub_cat_index]['products'] = products;
            }

          }else{
            let products = Array();
            let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            let new_sub_category = {subcategoryId, selected:true,products};
            
            load_sub_categories.push(new_sub_category);
          }
        }else{
          var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
          if(get_sub_cat_index >= 0){
            
            var products = load_sub_categories[get_sub_cat_index]['products'];
            var get_product_index = products.findIndex(el => el.productId == productId);
            // console.log(products);
            if(get_product_index >= 0){
              // console.log('product found');
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = '';
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = '';
              
            }else{
              let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:value, model:model_no, metarial:[], color:[], product_mesurment:[], note: '', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
              products.push(new_product);
              load_sub_categories[get_sub_cat_index]['products'] = products;
            }

          }
        }
        
        
        localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

      }

    }else{

      productDatabase.selected = false;

      setProductDatabase(productDatabase);

      var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
      // console.log(get_sub_cat_index);
      var products_array = load_sub_categories[get_sub_cat_index].products;
      var new_product_array = products_array.filter((item) => item.productId !== productId);
      // console.log(new_product_array.length);
      if(new_product_array.length == 0){
        var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
        // console.log(new_sub_categories.length);
        if(new_sub_categories.length == 0){
          localStorage.removeItem("sub_categories");
        }else{
          localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
        }
      }else{
        load_sub_categories[get_sub_cat_index].products = new_product_array;
        localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
      }

      var load_material_data = JSON.parse(localStorage.getItem("material_data"));
      // console.log(material);
      // console.log(load_material_data);

      if (load_material_data === null || typeof load_material_data === "undefined") {

      }else{
        setMaterial(material.filter((item)=>{
          return item.product_id != productId;
        }));

        var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
        localStorage.setItem("material_data", JSON.stringify(new_material_data));
      }

    }
    
    setTimeout(() => {
      let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

      // console.log(subcategories);
      let product = [];
      if(subcategories === null || typeof subcategories === "undefined"){

      }else{
        subcategories.forEach((val,key) => {
          // products.push(val.products);
          val.products.forEach((val2,key2)=>{
            // console.log(val2);
            val2.product_mesurment.forEach((val3,key3)=>{
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                product.push(new_product_object);
                // console.log(val3);
            })
            if(val2.product_mesurment_value !== ''){
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
              product.push(new_product_object);
            }
          })
          // console.log(val.products);
        })
      }

      let load_material_data = JSON.parse(localStorage.getItem("material_data"));
      if (load_material_data === null || typeof load_material_data === "undefined") {
        // console.log(load_material_data);
        
      } else { 
        var material_data = [];
        load_material_data.forEach((val) => {
          // console.log(val);
          var new_material_data = val;
          material_data.push(new_material_data);
        })
        setMaterial(material_data);
        
      }
      
      setPorduct(product);
    }, 100);

  }

  const increment_measurement_custom = (e) => {

    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let measurementValue = document.getElementById('mcv_'+id).value;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let qty = productDatabase.custom_measurement_qty === '' ? 1 : parseInt(productDatabase.custom_measurement_qty)+parseInt(1);
    let model_no = e.currentTarget.getAttribute('data-model_no');

    console.log(qty);

    productDatabase.custom_measurement_qty = qty;

    setProductDatabase(oldValue=>({
      ...oldValue,
      custom_measurement_qty:qty,
    }))
    
    let totalQty = 0;
    productDatabase.measurement.forEach(val=>{
      var qty = val.qty === '' || val.qty === null ? 0 : val.qty;
      totalQty += parseFloat(qty);
      // console.log(qty);
    })
    
    let customQty = productDatabase.custom_measurement_qty === '' || productDatabase.custom_measurement_qty === null ? 0 : productDatabase.custom_measurement_qty ;
    let netTotalQty = parseFloat(totalQty) + parseFloat(customQty);
    console.log(netTotalQty);

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));
    
    
    if(netTotalQty > 0){
      
      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:true,
      }))

      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
        let load_new_sub_categories = Array();
        let products = Array();
        let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
        products.push(new_product);
        let new_sub_category = {subcategoryId, selected:true,products};
        
        load_new_sub_categories.push(new_sub_category);

        localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

      }else{

        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);

        if(get_sub_cat_index >= 0){

          var products = load_sub_categories[get_sub_cat_index]['products'];
          var get_product_index = products.findIndex(el => el.productId == productId);

          if(get_product_index >= 0){

            load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = measurementValue;
            load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = qty;
            

          }else{
            let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            load_sub_categories[get_sub_cat_index]['products'] = products;
          }

        }else{
          let products = Array();
          let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
          products.push(new_product);
          let new_sub_category = {subcategoryId, selected:true,products};
          
          load_sub_categories.push(new_sub_category);

        }
        
        localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

      }

    }else{

      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:false,
      }))

    }

    setTimeout(() => {
      let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

      // console.log(subcategories);
      let product = [];
      if(subcategories === null || typeof subcategories === "undefined"){

      }else{
        subcategories.forEach((val,key) => {
          // products.push(val.products);
          val.products.forEach((val2,key2)=>{
            // console.log(val2);
            val2.product_mesurment.forEach((val3,key3)=>{
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                product.push(new_product_object);
                // console.log(val3);
            })
            if(val2.product_mesurment_value !== ''){
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
              product.push(new_product_object);
            }
          })
          // console.log(val.products);
        })
      }

      let load_material_data = JSON.parse(localStorage.getItem("material_data"));
      if (load_material_data === null || typeof load_material_data === "undefined") {
        // console.log(load_material_data);
        
      } else { 
        var material_data = [];
        load_material_data.forEach((val) => {
          // console.log(val);
          var new_material_data = val;
          material_data.push(new_material_data);
        })
        setMaterial(material_data);
        
      }
      
      setPorduct(product);
    }, 100);
    
  }

  const decrement_measurement_custom = (e) => {
    let id = e.currentTarget.id;
    id = id.split('_')[1];
    let subcategoryId = id.split('-')[0];
    let productId = id.split('-')[1];
    let productIndex = id.split('-')[2];
    let measurementValue = document.getElementById('mcv_'+id).value;
    let isCustom = 0;
    let productImage = document.getElementById('pi_'+subcategoryId+'-'+productId).value;
    let model_no = e.currentTarget.getAttribute('data-model_no');

    let qty = productDatabase.custom_measurement_qty === '' ? 1 : productDatabase.custom_measurement_qty;
    if(parseInt(qty) > 0)
      qty--

    console.log(qty);

    productDatabase.custom_measurement_qty = qty;

    setProductDatabase(oldValue=>({
      ...oldValue,
      custom_measurement_qty:qty,
    }))
    
    let totalQty = 0;
    productDatabase.measurement.forEach(val=>{
      var qty = val.qty === '' || val.qty === null ? 0 : val.qty;
      totalQty += parseFloat(qty);
      // console.log(qty);
    })
    
    let customQty = productDatabase.custom_measurement_qty === '' || productDatabase.custom_measurement_qty === null ? 0 : productDatabase.custom_measurement_qty ;
    let netTotalQty = parseFloat(totalQty) + parseFloat(customQty);
    console.log(netTotalQty);

    let load_sub_categories = JSON.parse(localStorage.getItem("sub_categories"));

    if(netTotalQty > 0){
      
      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:true,
      }))

      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){
            
        let load_new_sub_categories = Array();
        let products = Array();
        let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
        products.push(new_product);
        let new_sub_category = {subcategoryId, selected:true,products};
        
        load_new_sub_categories.push(new_sub_category);

        localStorage.setItem("sub_categories", JSON.stringify(load_new_sub_categories));

      }else{

        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);

        if(get_sub_cat_index >= 0){

          var products = load_sub_categories[get_sub_cat_index]['products'];
          var get_product_index = products.findIndex(el => el.productId == productId);

          if(get_product_index >= 0){
            if(qty > 0){
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = measurementValue;
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = qty;
            }else{
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_value = '';
              load_sub_categories[get_sub_cat_index]['products'][get_product_index].product_mesurment_qty = '';
            }
            
          }else{
            let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
            products.push(new_product);
            load_sub_categories[get_sub_cat_index]['products'] = products;
          }

        }else{
          let products = Array();
          let new_product = {productId, productImage, product_mesurment_value:measurementValue, product_mesurment_qty:qty, model:model_no, metarial:[], color:[], product_mesurment:[], note:'', cbm:'', unitPrice:'', totalPrice:'', commentaire:''};
          products.push(new_product);
          let new_sub_category = {subcategoryId, selected:true,products};
          
          load_sub_categories.push(new_sub_category);

        }
        
        localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));

      }

    }else{

      setProductDatabase(oldValue=>({
        ...oldValue,
        selected:false,
      }))
      
      if(load_sub_categories === null || typeof load_sub_categories === "undefined"){

      }else{

        var get_sub_cat_index = load_sub_categories.findIndex(el => el.subcategoryId == subcategoryId);
        // console.log(get_sub_cat_index);
        var products_array = load_sub_categories[get_sub_cat_index].products;
        var new_product_array = products_array.filter((item) => item.productId !== productId);
        // console.log(new_product_array.length);
        if(new_product_array.length == 0){
          var new_sub_categories = load_sub_categories.filter((item) => item.subcategoryId !== subcategoryId);
          // console.log(new_sub_categories.length);
          if(new_sub_categories.length == 0){
            localStorage.removeItem("sub_categories");
          }else{
            localStorage.setItem("sub_categories", JSON.stringify(new_sub_categories));
          }
        }else{
          load_sub_categories[get_sub_cat_index].products = new_product_array;
          localStorage.setItem("sub_categories", JSON.stringify(load_sub_categories));
        }

        var load_material_data = JSON.parse(localStorage.getItem("material_data"));
        // console.log(material);
        // console.log(load_material_data);
        if (load_material_data === null || typeof load_material_data === "undefined") {

        }else{
          setMaterial(material.filter((item)=>{
            return item.product_id != productId;
          }));
  
          var new_material_data = load_material_data.filter((item)=> item.product_id != productId);
          localStorage.setItem("material_data", JSON.stringify(new_material_data));
        }

      }

    }

    setTimeout(() => {
      let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

      // console.log(subcategories);
      let product = [];
      if(subcategories === null || typeof subcategories === "undefined"){

      }else{
        subcategories.forEach((val,key) => {
          // products.push(val.products);
          val.products.forEach((val2,key2)=>{
            // console.log(val2);
            val2.product_mesurment.forEach((val3,key3)=>{
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                product.push(new_product_object);
                // console.log(val3);
            })
            if(val2.product_mesurment_value !== ''){
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
              product.push(new_product_object);
            }
          })
          // console.log(val.products);
        })
      }

      let load_material_data = JSON.parse(localStorage.getItem("material_data"));
      if (load_material_data === null || typeof load_material_data === "undefined") {
        // console.log(load_material_data);
        
      } else { 
        var material_data = [];
        load_material_data.forEach((val) => {
          // console.log(val);
          var new_material_data = val;
          material_data.push(new_material_data);
        })
        setMaterial(material_data);
        
      }
      
      setPorduct(product);
    }, 100);
  }

  const handelSelectMaterial = (ele) => {
    let product_id = ele.currentTarget.id;
    navigate('/projects/add-new/project-material-single/'+product_id);
  }

  const handelMaterialDelete = (productId, index) => {
    var load_material_data = JSON.parse(localStorage.getItem("material_data"));
    var get_product_index = load_material_data.findIndex(el => el.product_id == productId);
    // console.log(material);

    setMaterial(material.filter((item)=>{
      if(item.product_id == productId){
        
        var load_material_data = JSON.parse(localStorage.getItem("material_data"));
        var new_material_data = item.material_data.filter((item2)=> item2.index !== index);
        
        if(new_material_data.length == 0){
          var new_material_data = material.filter((item3) => item3.product_id !== productId);
          if(new_material_data.length == 0){
            localStorage.removeItem("material_data");
          }else{
            localStorage.setItem("material_data", JSON.stringify(new_material_data));
          }
          return item.product_id !== productId;
        }else{
          item.material_data = new_material_data;
          var get_product_index = load_material_data.findIndex(el => el.product_id == productId);
          load_material_data[get_product_index].material_data = new_material_data;
          localStorage.setItem("material_data", JSON.stringify(load_material_data));
        }
        
        return item;
      }else{
        return item;
      }
    }))

    setTimeout(() => {
      let subcategories = JSON.parse(localStorage.getItem('sub_categories'));

      // console.log(subcategories);
      let product = [];
      if(subcategories === null || typeof subcategories === "undefined"){

      }else{
        subcategories.forEach((val,key) => {
          // products.push(val.products);
          val.products.forEach((val2,key2)=>{
            // console.log(val2);
            val2.product_mesurment.forEach((val3,key3)=>{
                var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val3.measurementValue, qty:val3.qty, model:val3.model, metarial:val3.metarial, color: val3.color, note:val3.note, isCustom: 0};
                product.push(new_product_object);
                // console.log(val3);
            })
            if(val2.product_mesurment_value !== ''){
              var new_product_object = {subcategoryId:val.subcategoryId, productId:val2.productId, productImage:val2.productImage, measurementValue:val2.product_mesurment_value, qty:val2.product_mesurment_qty, model:val2.model, metarial:val2.metarial, color: val2.color, note:val2.note, isCustom: 1};
              product.push(new_product_object);
            }
          })
          // console.log(val.products);
        })
      }

      let load_material_data = JSON.parse(localStorage.getItem("material_data"));
      if (load_material_data === null || typeof load_material_data === "undefined") {
        // console.log(load_material_data);
        
      } else { 
        var material_data = [];
        load_material_data.forEach((val) => {
          // console.log(val);
          var new_material_data = val;
          material_data.push(new_material_data);
        })
        setMaterial(material_data);
        
      }
      
      setPorduct(product);
    }, 100);
  }

  const closeProductDatabaseModal = () => {
    setModalView(false);
    document.getElementById("product_database").value = '';
  }

  return (
    <div>
      <TopBar title="Request quote"/>
      
      {modalView ? <div className="full_body"></div> : ''}
      {modalView ?
       <div className="single_product_modal">
        <div className="single_product_modal_header">
          <div className="header-title">
            Add product database
          </div>
          <div className="close-btn" onClick={closeProductDatabaseModal}>
            <span><FaTimes/></span>
          </div>
        </div>
        <div className="single_product_modal_body">
          {foundProductDatabase ?
          <div className="single_product_box">
            <div className="product_item_single">
              {productDatabase.selected ? <span className="selected">Selected</span>:''}
              <div className="product_image">
                <img src={productDatabase.image}/>
                <input type="hidden" id={'pi_'+productDatabase.subcategory_id+'-'+productDatabase.product_id} value={productDatabase.image}/>
              </div>
              <div className="product_name">
                <h2>{productDatabase.productName}</h2>
              </div>
              <div className="product_measurement">
                <div className="measurement_title">
                  <span>Size</span>
                  <span>Quantity</span>
                </div>
                {productDatabase.measurement.map((measurement, key2)=>(
                  
                  <div className="measurement_value">
                    <span id={'smv_'+productDatabase.subcategory_id+'-'+productDatabase.product_id+'-'+key2}>{measurement.measurement}</span>
                    <span className="measurement_input">
                      <input type="text" className="mqty" autoComplete="off" data-model_no={productDatabase.reference} onChange={measurementInput} id={'smq_'+productDatabase.subcategory_id+'-'+productDatabase.product_id+'-'+key2} data-measurement_key={key2} value={measurement.qty}/>
                      <span className="measurement_btn">
                        <button onClick={increment_measurement} data-model_no={productDatabase.reference} className="increment_measurement" id={'inc_'+productDatabase.subcategory_id+'-'+productDatabase.product_id+'-'+key2}><FaAngleUp/></button>
                        <button onClick={decrement_measurement} data-model_no={productDatabase.reference} className="decrement_measurement" id={'decInc_'+productDatabase.subcategory_id+'-'+productDatabase.product_id+'-'+key2}><FaAngleDown/></button>
                      </span>
                    </span>
                  </div>
                ))}
                <div className="measurement_value">
                  <span>
                    <input autoComplete="off" id={'mcv_'+productDatabase.subcategory_id+'-'+productDatabase.product_id} type="text" className="custom_measurement" data-model_no={productDatabase.reference} onChange={measurementValueInput_custom} value={productDatabase.custom_measurement} placeholder="Custom size"/>
                  </span>
                  <span className="measurement_input">
                    <input type="text" className="mqty" autoComplete="off" onChange={measurementInput_custom} data-model_no={productDatabase.reference} id={'mcq_'+productDatabase.subcategory_id+'-'+productDatabase.product_id} value={productDatabase.custom_measurement_qty}/>
                    <span className="measurement_btn">
                      <button data-model_no={productDatabase.reference} onClick={increment_measurement_custom} className="increment_measurement" id={'cinc_'+productDatabase.subcategory_id+'-'+productDatabase.product_id}><FaAngleUp/></button>
                      <button data-model_no={productDatabase.reference} onClick={decrement_measurement_custom} className="decrement_measurement" id={'cdecInc_'+productDatabase.subcategory_id+'-'+productDatabase.product_id}><FaAngleDown/></button>
                    </span>
                  </span>
                </div>
              </div>
              <div className="selected_material_box">
                {
                  material.map((material) => (
                    <>
                      {
                        material.product_id == productDatabase.product_id ?
                        <div className="product_material" style={{textAlign:'left'}}>
                          <div className="product_material_title" style={{textAlign:'left'}}>
                            Select Material
                          </div>
                          {
                            material.material_data.map((material_data) => (
                              <>
                                <div className="product_material_data_title" style={{textAlign:'left'}}>
                                  {material_data.subCatName}
                                </div>
                                <div className="product_material_data_item">
                                  <div className="product_material_data_name">{material_data.name}</div>
                                  <span className="product_material_data_remove" onClick={()=>handelMaterialDelete(productDatabase.product_id, material_data.index)}><FaTimes size={12} color="#ff0000"/></span>
                                </div>
                              </>
                            ))
                          }
                          
                        </div>
                        : ''
                      }
                    </>
                  ))
                }
              </div>
              <div className="select_material_btn">
                <button id={productDatabase.product_id} onClick={handelSelectMaterial}><FaPlus/> Select material</button>
              </div>
            </div>
          </div>
            : ''}
        </div>
        <div className="record_payment_footer">
          <button onClick={closeProductDatabaseModal}>Cancel</button>
        </div>
      </div>
       : ''}

      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <div className="flex justify-between items-center">
            <p className="text-xl text-white">Digital Proof Details</p>
            <p className="text-xl text-white">Step 2</p>
          </div>
        </div>
        <form action="">
          <div className="form-body bg-white p-8 rounded-b-[10px]">
            <p className="text-blue font-bold mb-4">Product database</p>
            <div className="product_database_search_box">
              <input
                type="text"
                placeholder="SEARCH"
                className="w-full p-2 rounded"
                name=""
                id="product_database"
              />
              {searchProduct ? <Spinner/> : ''}
            </div>
            <p className="text-blue font-bold my-4">Custom Product Product</p>
            <div className="flex justify-start items-center gap-3 mb-5">
              <Link
                to={"/projects/add-new/project-product"}
                className="btn-blue flex justify-center items-center gap-2 px-5 py-3"
              >
                <FaPlus /> <span>Product</span>
              </Link>
              <Link
                to={"/projects/add-new/custom-product"}
                className="btn-blue flex justify-center items-center gap-2 px-5 py-3"
              >
                <FaPlus /> <span>Custom Product</span>
              </Link>
            </div>
            <div className="overflow-x-auto">
              {/* <Table
                dataSource={dataSource}
                className="text-center md:w-full w-[400%]"
                columns={columns}
              /> */}
            </div>
            {/* <div className="custom_product_box"> */}
              {/* <div className="custom_product_box_header">
                <span>Item</span>
                <span>File technique</span>
                <span>Model NO.</span>
                <span>Dimension(mm)</span>
                <span>Material</span>
                <span>Color</span>
                <span>Quantity</span>
                <span>&nbsp;</span>
              </div>
              <div className="custom_product_box_body">
                sdfsdf
              </div> */}
            {/* </div> */}

            <table className="project_product_box" cellspacing="0">
              <thead>
                <tr className="project_product_header">
                  <th>Item</th>
                  <th>File technique</th>
                  <th>Model NO.</th>
                  <th>Dimension(mm)</th>
                  <th>Material</th>
                  <th>Color</th>
                  <th>Quantity</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {customProduct.length != 0 ?
                  customProduct.map((customProduct, key)=>(
                    <tr className="project_product_item" key={key}>
                      <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                        {key+1}
                      </td>
                      <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                        <label>
                          <input type="file" onChange={handleCustomProductFile} id={'file-'+customProduct.index+'-'+customProduct.file} className="input-file-technique" />
                          <img style={{margin:'auto', width:'40px'}}
                            src={fileIcon}
                            alt=""
                          />
                        </label>
                      </td>
                      <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                        <input className="custom-product-model" id={'model-'+customProduct.index} onChange={handleCustomProductModel} value={customProduct.model}/>
                      </td>
                      <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                        <input className="custom-product-dimension" id={'dimension-'+customProduct.index} onChange={handleCustomProductDimension} value={customProduct.dimension}/>
                      </td>
                      <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                        <input className="custom-product-material" id={'material-'+customProduct.index} onChange={handleCustomProductMaterial} value={customProduct.material}/>
                      </td>
                      <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                        <input className="custom-product-color" id={'color-'+customProduct.index} onChange={handleCustomProductColor} value={customProduct.color}/>
                      </td>
                      <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                        <input className="custom-product-qty" id={'qty-'+customProduct.index} style={{textAlign:'center'}} onChange={handleCustomProductQty} value={customProduct.qty}/>
                      </td>
                      <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                        <textarea className="custom-product-note" id={'note-'+customProduct.index} onChange={handleCustomProductNote}>{customProduct.note}</textarea>
                      </td>
                      <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                        <button id={'removebtn_'+customProduct.index+'_'+customProduct.file} onClick={handelCustomRemove} className="text-white bg-red-500 w-[30px] h-[30px] flex justify-center items-center rounded-full">
                          <FaTimes />
                        </button>
                      </td>
                    </tr>
                  ))
                  :''
                }              
              </tbody>
            </table>
            
            <table className="project_product_box" cellspacing="0">
              <thead>
                <tr className="project_product_header">
                  <th>Item</th>
                  <th>Picture</th>
                  <th>Model NO.</th>
                  <th>Dimension(mm)</th>
                  <th>Material</th>
                  <th>Quantity</th>
                  <th>Note</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {
                products.map((product,key)=>(
                  <tr className="project_product_item" key={key}>
                    <td className="project_product_item_right" style={{borderLeft:'1px solid', borderTop:'1px solid', borderBottom:'1px solid', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}>
                      {key+1}
                    </td>
                    <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                      <img style={{margin:'auto', width:'80px'}} src={product.productImage}/>
                    </td>
                    <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                      {product.model ==='' ? 'empty' : product.model}
                    </td>
                    <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}} id={'mv_'+product.subcategoryId+'-'+product.productId+'-'+key+'-'+product.isCustom}>
                      {product.measurementValue}
                    </td>
                    <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                      {
                        
                        material.map((material) => (
                          <>
                            {
                              material.product_id == product.productId ?
                              <div className="product_material" style={{margin:'auto'}}>
                                {
                                  material.material_data.map((material_data) => (
                                    <>
                                      <div className="product_material_data_title">
                                        {material_data.subCatName}
                                      </div>
                                      <div className="product_material_data_item">
                                        <div className="product_material_data_name">{material_data.name}</div>
                                      </div>
                                    </>
                                  ))
                                }
                                
                              </div>
                              : ''
                            }
                          </>
                        ))
                      }
                    </td>
                    <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                      <input className="product_box_qty" onChange={handelQty} id={'qty_'+product.subcategoryId+'-'+product.productId+'-'+key+'-'+product.isCustom} value={product.qty}/>
                    </td>
                    <td className="project_product_item_right" style={{borderTop:'1px solid', borderBottom:'1px solid'}}>
                      <textarea className="product_box_note" id={'note_'+product.subcategoryId+'-'+product.productId+'-'+key+'-'+product.isCustom} onChange={handelNote} value={product.note}></textarea>
                    </td>
                    <td style={{borderTop:'1px solid', borderBottom:'1px solid', borderRight:'1px solid', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}>
                      <button id={'removebtn_'+product.subcategoryId+'-'+product.productId+'-'+key+'-'+product.isCustom} onClick={handelRemove} className="text-white bg-red-500 w-[30px] h-[30px] flex justify-center items-center rounded-full">
                        <FaTimes />
                      </button>
                    </td>
                  </tr>
                ))
                }                
              </tbody>
            </table>
            
          </div>

          <div className="text-center mt-10">
            <button
              onClick={gotoStep3}
              type="button"
              className="btn-blue px-10"
            >
              {project.requestType === 'new' ? 'Request Quote' : 'Update Request'} 
            </button>
          </div>
        </form>
        {isLoading ? <LoadingSpinner/> : ""}
      </div>
      <Footer/>
    </div>
  );
};

export default Step2;
