import React from "react";
import { FaBell, FaCog, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";

import check from "../../assets/img/check.png";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const Step3 = () => {
  return (
    <div>
      <TopBar title="Request quote"/>
      
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <div className="flex justify-between items-center">
            <p className="text-xl text-white">Digital Proof Details</p>
            <p className="text-xl text-white">Step 3</p>
          </div>
        </div>
        <div className="py-36 bg-white rounded-b-[10px]">
          <div className="text-center">
            <img src={check} className="mx-auto w-[60px] mb-4" alt="" />
            <h2 className="font-bold text-blue text-[35px]">REQUEST SEND</h2>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Step3;
