import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaSearch, FaUser, FaEllipsisH, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import StatCards from "../../components/stat-cards";
import GetCustomerName from "../../utils/getCustomerName";
import GetSupplierName from "../../utils/getSupplierName";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import LoadingSpinner from "../../components/Loading_spinner";
import services from "../../components/services";
import Pagination from "../../components/pagination";
import Swal from "sweetalert2";
import { Dropdown } from "antd";

const Dashboard = () => {
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [dataLength, setDataLength] = useState(null);
  const [projectFound, setProjectFound] = useState(false);
  const [execute, setExecute] = useState(false);
  const [role, setRole] = useState(null);
  const [totalProject, setTotalProject] = useState(null);
  const [pending, setPending] = useState(null);
  const [validate, setValidate] = useState(null);

  const navigate = useNavigate();
  //  const [supplier, setSupplier] = React.useState([]);

  //  useEffect(() => {
  //    fetch(
  //      `https://sensar.vercel.app/api/v1/supplier/${project?.data?.supplierName}`,
  //      {
  //        method: "GET",
  //        headers: {
  //          "Content-Type": "application/json",
  //        },
  //      }
  //    )
  //      .then((res) => res.json())

  //      .then((res) => {
  //        setSupplier(res);
  //      });
  //  }, [project.data.supplierName]);

  const getProject = (pageNo, searchText='') => {

    let user_role = localStorage.getItem("sensar_user_role");
    let user_role_id = localStorage.getItem("sensar_user_role_id");


    setRole(user_role);

    // const source = new EventSource(services.url+"fetch_projects.php?id=27&user_role_id="+user_role_id+"&per_page="+limit+"&page="+pageNo+"&search_text="+searchText,{
    //   withCredentials: true,
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Headers": "*",
    //     "Access-Control-Allow-Methods": "*",
    //   },
    // });
    // source.onmessage = function(event){
    //   setLoading(false);
    //   setExecute(true);
    //   let projects = JSON.parse(event.data);
    //   setDataLength(projects.no_of_rows);
    //   console.log(projects);
    //   // console.log(projects.projects);
    //   setProjects(projects.projects);
    //   setTotalProject(projects.totalProject);
    //   setPending(projects.pending);
    //   setValidate(projects.validate);
    // }

    
    
    fetch(services.url+"/get_project_details.php?user_role_id="+user_role_id+"&per_page="+limit+"&page="+pageNo+"&search_text="+searchText, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((projects) => {
        setLoading(false);
        setExecute(true);
        setDataLength(projects.no_of_rows);
        console.log(projects);
        // console.log(projects.projects);
        setProjects(projects.projects);
        setTotalProject(projects.totalProject);
        setPending(projects.pending);
        setValidate(projects.validate);
      });
      
  }

  useEffect(() => {
    setLoading(true);

    getProject(page);

  }, []);

  

  let totalPage = Math.ceil(dataLength/limit);
  let start = (page - 1) * limit + 1;
  let end = page * limit;

  function handlePageChange(value){
    let pageno = page;
    if(value === "... "){
      setPage(1);
      pageno = 1;
    }
    else if(value === "pre"){
      if(page !== 1){
        setPage(page - 1);
        pageno = page - 1;
      }
    }else if(value === "next"){
      if(page !== totalPage){
        setPage(page + 1);
        pageno = page + 1;
      }
    }else if(value === " ..."){
      setPage(totalPage);
      pageno = totalPage;
    }else{
      setPage(value);
      pageno = value;
    }
    setLoading(true);
    getProject(pageno);
  }

  const view_project_validation = (project_id) => {
    // alert(project_id);
    navigate('/projects/validation/'+project_id);
  }

  const view_admin_project = (project_id) => {
    // alert(project_id);
    navigate('/projects/details/'+project_id);
  }

  const view_supplier_project = (project_id) => {
    // alert(project_id);
    navigate('/projects/supplier/'+project_id);
  }

  const create_supplier_project = (project_id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      
        if (result.isConfirmed) {

          setLoading(true);

          fetch(services.url+"/suuplier_project_process.php?id="+project_id)
          .then((res) => res.json())
          .then((res) => {
              setLoading(false);
              Swal.fire({
                  title: "Success!",
                  text: "Project has been processed successfully!",
                  icon: "success",
                  confirmButtonText: "Okay",
                }).then((result) => {
                  if (result.isConfirmed) {
                    getProject(page);
                  }
                  
                });
          })
        }
    })
  }

  const deleteProject = (id, role) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          role,
          id
        };
        setLoading(true);
        fetch(services.url+"/delete_project.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          setPage(1);
          getProject(1);
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        });
      }
      
    });
  }

  const handleCheckbox = (id) => {

    setProjects(oldValue => {
      return oldValue.filter((item) => {
        if(item.project_id == id && item.checked == false){
          item.checked = true;
        }else if(item.project_id == id && item.checked == true){
          item.checked = false;
        }
        return true;
      })
    })
  }

 

  return (
    <div>
      <TopBar isSearch="true" title="Dashboard"/>
      {loading ? <LoadingSpinner/> : ''}
      <div className="mt-6">
        <StatCards totalProject={totalProject} pending={pending} validate={validate} />
      </div>

      <div className="project-ongoing w-full overflow-hidden mt-6 bg-white rounded-[10px] lg:p-5 p-[10px]">
        <p className="text-lg font-bold text-blue">Project on going</p>
        <div className="overflow-x-auto">
          {
            role === 'Admin' ?
            <table className="table mt-6 stat-table lg:w-full w-[300%]">
              <thead>
                <tr>
                  <th className="text-left flex text-blue text-sm font-semibold">
                    Name Supplier
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Customer
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Date
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Contact
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Status
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Digital Proof
                  </th>
                  {/* <th className="text-center text-blue text-sm font-semibold">
                    Action
                  </th> */}
                </tr>
              </thead>

              <tbody>
                {projects.map((project) => (
                  <tr>
                    <td className="py-4">
                      <div className="flex justify-start items-center gap-4 border-l-2 pl-3 border-[#4d44b5]">
                        <input type="checkbox" name="" id="" onChange={()=>handleCheckbox(project.project_id)} />
                        <div className="avatar bg-[#c1bbeb] flex justify-center items-center text-white w-[40px] h-[40px] rounded-full">
                          <FaUser />
                        </div>
                        <p className="text-lg font-bold text-blue">
                          {/* <GetCustomerName project={project} /> */}
                          {project.supplier}
                        </p>
                      </div>
                    </td>
                    <td className="py-4 text-center">
                      <span className="text-blue font-semibold">
                        {/* <GetSupplierName project={project} /> */}
                        {project.customer}
                      </span>
                    </td>
                    <td className="py-4 text-center">
                      <span className="text-[#a098ae]">
                        {project.startDate}
                      </span>
                    </td>
                    <td className="py-4 text-center">
                      <span className="text-blue font-semibold">
                        {project.countryName}
                      </span>
                    </td>
                    <td className="py-4 text-center">
                      <span className="text-[#ff6600] font-semibold">
                        {project.validation == 1 && project.paid == 1 ?
                        <span className="text-[#099916] font-semibold">
                          Complete
                        </span>
                        :
                        <span className="text-[#ff6600] font-semibold">
                          In process
                        </span>
                        }
                      </span>
                    </td>
                    <td className="py-4 text-center">
                      <div className="flex justify-center items-center gap-3">
                        {
                        project.validation == 1 && project.paid == 0 ? 
                        <button className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#099916]">
                          Poof validate
                        </button>
                        : 
                        project.validation == 1 && project.paid == 1 ?
                        <button className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#099916]">
                          Done
                        </button>
                        :
                        <button onClick={()=>view_project_validation(project.project_id)} className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#ff6600]">
                          Waiting Answer
                        </button>
                        }
                        
                        <button onClick={()=>view_admin_project(project.project_id)} className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#4d44b5]">
                          View
                        </button>
                        {project.checked ?
                          <button onClick={()=>deleteProject(project.project_id, 'admin')} className="text-white bg-red-500 w-[30px] h-[30px] flex justify-center items-center rounded-full">
                            <FaTimes />
                          </button>
                          : ''}
                      </div>
                    </td>
                    {/* <td className="py-4 text-center">
                      <button className="btn">
                        <FaEllipsisH />
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            : 
            <table className="table mt-6 stat-table lg:w-full w-[300%]">
              <thead>
                <tr>
                  <th className="text-left flex text-blue text-sm font-semibold">
                    Name
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Date
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Contact
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Status
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Digital Proof
                  </th>
                  <th className="text-center text-blue text-sm font-semibold">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {projects.map((project) => (
                  <tr>
                    <td className="py-4">
                      <div className="flex justify-start items-center gap-4 border-l-2 pl-3 border-[#4d44b5]">
                        <input type="checkbox" name="" id="" />
                        <div className="avatar bg-[#c1bbeb] flex justify-center items-center text-white w-[40px] h-[40px] rounded-full">
                          <FaUser />
                        </div>
                        <p className="text-lg font-bold text-blue">
                          {/* <GetCustomerName project={project} /> */}
                          {project.customer}
                        </p>
                      </div>
                    </td>
                    <td className="py-4 text-center">
                      <span className="text-blue font-semibold">
                        {/* <GetSupplierName project={project} /> */}
                        {project.startDate}
                      </span>
                    </td>
                    <td className="py-4 text-center">
                      <span className="text-blue font-semibold">
                        {project.countryName}
                      </span>
                    </td>
                    <td className="py-4 text-center">
                      {
                        project.new_request == 1 ? 
                        <span className="text-[#ff6600] font-semibold">
                          In process
                        </span>
                        :
                        project.send_qoute == 1 && project.validation == 0 && project.process == 0 ?
                        <span className="text-[#ff6600] font-semibold project-status">
                          Waiting validation from customer
                        </span>
                        :
                        project.send_qoute == 1 && project.validation == 1 && project.process == 0 ?
                        <span className="text-[#099916] font-semibold">
                          Validate
                        </span>
                        :
                        project.send_qoute == 1 && project.validation == 1 && project.process == 1 ?
                        <span className="text-[#099916] font-semibold">
                          Validate
                        </span>
                        :''
                      }
                    </td>
                    <td className="py-4 text-center">
                      <div className="flex justify-center items-center gap-3">
                        <button onClick={()=>view_supplier_project(project.project_id)} className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#4d44b5]">
                          View
                        </button>
                        {/* {
                          project.new_request == 1 ?
                          <button onClick={()=>view_supplier_project(project.project_id)} className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#4d44b5]">
                            View
                          </button>
                          :
                          project.send_qoute == 1 && project.validation == 0 && project.process == 0 ?
                          <button onClick={()=>view_supplier_project(project.project_id)} className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#4d44b5]">
                            View
                          </button>
                          :
                          project.send_qoute == 1 && project.validation == 1 && project.process == 0 ?
                          <button onClick={()=>view_supplier_project(project.project_id)} className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#4d44b5]">
                            View
                          </button>
                          :
                          project.send_qoute == 1 && project.validation == 1 && project.process == 1 ?
                          <button onClick={()=>view_supplier_project(project.project_id)} className="w-[100px] px-4 rounded-full py-2 text-xs text-white bg-[#4d44b5]">
                            View
                          </button>
                          :''
                        } */}
                        
                      </div>
                    </td>
                    <td className="py-4 text-center">
                      <button className="btn">
                      <Dropdown
                        menu={{
                          items:[
                            {
                              key: "1",
                              danger: true,
                              label: <button onClick={() => deleteProject(project.project_id,'supplier')}>Delete</button>,
                              // onClick: () => deleteUser(user.id),
                              // get the exact id of the customer and pass it to the handleDelete function
                            },
                          ],
                        }}
                        trigger={["click"]}
                      >
                        <a className="action_btn" onClick={(e) => e.preventDefault()}>
                          {/* <FaEllipsisH className="text-[#a098ae] ml-auto block" /> */}
                          <FaEllipsisH />
                        </a>
                      </Dropdown>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
          
          <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} start={start} end={end} totalData={dataLength} onPageChange={handlePageChange}/>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Dashboard;
