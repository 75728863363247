import React from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import AllProducts from "../../components/products";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const Products = () => {
  return (
    <div className="customers">
      <TopBar title="Products"/>
      
      <AllProducts />
      <Footer/>
    </div>
  );
};

export default Products;
