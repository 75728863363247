import React from "react";
import "../../assets/css/add_subcategory_card.css";

export default function AddNewSubcategoryCard(props) {
    // console.log(props);
    const [name, setName] = React.useState(props.subCategories.name);
    
  return (
    <div className="subcategory-card">
        <span className="subcategory_close" name={props.subCategories.name} onClick={props.closeFunction}>
            &times;
        </span>
        <img
            src={props.subCategories.image}
            alt="logo"
            className=""
        />
        <p>
            {'subcategory_id' in props.subCategories ? <input type="hidden" value={props.subCategories.subcategory_id} className="subcategory_id"/>:''}

            <input
            type="text"
            className="p-1 border rounded subcategory_name"
            value={name}
            data-ind={props.index}
            data-subtype={'type' in props.subCategories?'old':'new'}
            id={props.subCategories.name}
            onChange={(e)=>{
                setName(e.target.value)
                // console.log(e);
            }}
            onKeyUp={props.updateName}
            />
        </p>
        {'subcategory_id' in props.subCategories ? '':<input type="file" className="subcategory_images" id={'sub_cat_img-'+props.subCategories.id}/>}
        
    </div>
  );
}