import React, {useState, useEffect} from "react";
import logo from "../../assets/img/logo.png";
import dashboard from "../../assets/img/home.png";
import factory from "../../assets/img/factory.png";
import customer from "../../assets/img/teacher.png";
import projects from "../../assets/img/projects.png";
import invoices from "../../assets/img/invoices.png";
import quotes from "../../assets/img/quotes.png";
import user from "../../assets/img/user.png";
import message from "../../assets/img/message.png";
import product from "../../assets/img/product.png";
import material from "../../assets/img/material.png";
import linkGenerator from "../../assets/img/link_generator.png";
import { NavLink, Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Sidebar = () => {

  const [selected, setSelected] = useState(null);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const [showSideBar, setShowSideBar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activePage, setActivePage] = useState(null);
  const [activeSupPage, setActiveSupPage] = useState(null);
  const [userRole, setUserRole] = useState(null);

  
  window.addEventListener('resize', function(event) {
    if (window.matchMedia('screen and (max-width: 1023px)').matches) {
      setShowSideBar(false);
      setIsMobile(true);
      
    }else{
      setShowSideBar(true);
      setIsMobile(false);
    }
  }, true);


  useEffect(()=>{

    // localStorage.getItem("sensar_user_role")

    setUserRole(localStorage.getItem("sensar_user_role"));

    let current_url = window.location.href.replace(/(https?|http):\/\//i, "");
    setActivePage(current_url.split('/')[1]);
    if(current_url.split('/')[2] !== ''){
      setActiveSupPage(current_url.split('/')[1]+'-'+current_url.split('/')[2]);
    }
    
    if (window.matchMedia('screen and (max-width: 1023px)').matches) {
      setShowSideBar(false);
      setIsMobile(true);
    }else{
      setShowSideBar(true);
      setIsMobile(false);
    }
    // if (window.matchMedia('screen and (max-width: 768px)').matches) {
    //   console.log('mobile');
    // }else{
    //   console.log('others');
    // }
  }, []);

  var menuItems = [];

  if(userRole === 'Admin'){
    menuItems = [
      {
        id: 1,
        name: "Dashboard",
        icon: dashboard,
        submenu: [],
        link: "/dashboard",
      },
      {
        id: 2,
        name: "Supplier",
        icon: factory,
        submenu: [
          {
            id: 1,
            name: "All Supplier",
            link: "/supplier/all",
          },
          {
            id: 2,
            name: "Add Supplier",
            link: "/supplier/add-new",
          },
        ],
        link: "/supplier",
      },
      {
        id: 3,
        name: "Customer",
        icon: customer,
        submenu: [
          {
            id: 1,
            name: "All Customer",
            link: "/customer/all",
          },
          {
            id: 2,
            name: "Add Customer",
            link: "/customer/add-new",
          },
        ],
        link: "/customer",
      },
      {
        id: 4,
        name: "Projects",
        icon: projects,
        submenu: [
          {
            id: 1,
            name: "All Projects",
            link: "/projects/all",
          },
          {
            id: 2,
            name: "Add Projects",
            link: "/projects/add-new",
          },
        ],
        link: "/projects",
      },
      {
        id: 5,
        name: "Invoices",
        icon: invoices,
        submenu: [],
        link: "/invoices",
      },
      // {
      //   id: 6,
      //   name: "Quotes",
      //   icon: quotes,
      //   submenu: [],
      //   link: "/quotes",
      // },
      {
        id: 7,
        name: "User",
        icon: user,
        submenu: [
          {
            id: 1,
            name: "All Users",
            link: "/users/all",
          },
          {
            id: 2,
            name: "Add New",
            link: "/users/add-new",
          },
          // {
          //   id: 2,
          //   name: "Profile",
          //   link: "/users/profile",
          // },
        ],
        link: "/user",
      },
      // {
      //   id: 8,
      //   name: "Message",
      //   icon: message,
      //   submenu: [],
      //   link: "/message",
      // },
      {
        id: 9,
        name: "Products",
        icon: product,
        submenu: [
          {
            id: 1,
            name: "All Products",
            link: "/products/all-products",
          },
          {
            id: 2,
            name: "Add Single Product",
            link: "/products/add-new",
          },
          {
            id: 3,
            name: "Add Multiple Products",
            link: "/products/add-multiple",
          },
          {
            id: 4,
            name: "Categories",
            link: "/product/categories",
          }
        ],
        link: "/products",
      },
      {
        id: 10,
        name: "Material",
        icon: material,
        submenu: [
          {
            id: 1,
            name: "All Material",
            link: "/material/all-materials",
          },
          {
            id: 2,
            name: "Add Single Material",
            link: "/material/add-single",
          },
          {
            id: 3,
            name: "Add Multiple Material",
            link: "/material/add-multiple",
          },
          {
            id: 4,
            name: "Categories",
            link: "/material/categories",
          },
        ],
        link: "/material",
      },
      {
        id: 11,
        name: "Link Generator",
        icon: linkGenerator,
        submenu: [],
        link: "/link_generator",
      },
    ];
  }else{
    menuItems = [
      {
        id: 1,
        name: "Dashboard",
        icon: dashboard,
        submenu: [],
        link: "/dashboard",
      },
      {
        id: 2,
        name: "Projects",
        icon: projects,
        submenu: [],
        link: "/projects/supplier",
      },
    ];
  }

  const toggle = (itemId) => {
    
    if(selected === itemId){
      return setSelected(null);
    }
    setSelected(itemId);
  }

  const toggle2 = (itemId) => {
    // if(selected === itemId){
    //   return setSelected(null);
    // }
    setSelected(itemId);
    setSelectedSubmenu(null);

    if(isMobile && showSideBar){
      setShowSideBar(false);
    }
  }

  const handleMenuBar = () => {
    setShowSideBar(true);
  }

  const handleMenuClose = () => {
    setShowSideBar(false);
  }

  const handleNavLink = (itemId,subId) => {
    // if(selectedSubmenu === subId){
    //   return setSelectedSubmenu(null);
    // }
    let submenuid = itemId+'-'+subId;
    setSelectedSubmenu(submenuid);
    if(isMobile && showSideBar){
      setShowSideBar(false);
    }
  }

  return (
    <div className={showSideBar?`bg-[#4d44b5] p-[30px] pr-0 h-full min-h-screen sidebar`: `bg-[#4d44b5] p-[30px] pr-0 h-full min-h-screen sidebar sidebar-hide`}>
      
      <div className="text-center pr-[30px]">
        <img src={logo} alt="logo" className="block mx-auto mb-10" />
        <span onClick={handleMenuBar} className={showSideBar?`menu-bar hide-menu-bar`:`menu-bar`}><FaBars size="22"/></span>
        <span onClick={handleMenuClose} className={isMobile && showSideBar ? `menu-close`: `menu-close hide-menu-close`}><FaTimes size="22"/></span>
      </div>
      <ul>
        {menuItems.map((item) => (
          <li key={item.id} className="mb-1">
            {item.submenu.length > 0 ? (
              <>
                <button
                  className={selected === item.id || (selected === null && activePage === item.name.toLocaleLowerCase()) ? `flex main-menu justify-start items-center w-full active` : `flex main-menu justify-start items-center w-full`}
                  onClick={() => toggle(item.id)}
                    //{
                    // let id = e.currentTarget.id;
                    // let itemId = id.split('-')[1];
                    // let mainMenu = document.getElementsByClassName('main-menu');
                    // for(let i=0; i<mainMenu.length; i++){
                    //   mainMenu[i].classList.remove('active');
                    // }
                    // let activeMainMenu = document.getElementsByClassName(`main-menu-${itemId}`);
                    // alert(activeMainMenu.length);
                    // for(let j=0; j<activeMainMenu.length; j++){
                    //   if(activeMainMenu[j].classList.contains('active')){
                    //     console.log('active');
                    //   }else{
                    //     console.log('not active');
                    //   }
                    // }
                    // if(activeMainMenu.classList.contains('active')) {
                    //     // do something if 'hasClass' is exist.
                    //     console.log('active');
                    // }else{
                    //   console.log('not active');
                    //   activeMainMenu.classList.add('active');
                    // }
                    // alert(e.currentTarget.classList('active').length);
                    // if(e.target.classList.contains("active")){
                    //   e.currentTarget.classList.remove("active");
                    //   console.log('remove');
                    // }else{
                    //   e.currentTarget.classList.add("active");
                    //   console.log('add');
                    // }

                    // let submenu = document.getElementsByClassName('submenu');
                    // for(let i=0; i<submenu.length; i++){
                    //   submenu[i].classList.remove('active');
                    // }
                    // const activesubmenu = document.querySelector(
                    //   `.submenu-${itemId}`
                    // );
                    // activesubmenu.classList.toggle("active");
                  //}}
                >
                  <img
                    src={item.icon}
                    alt={item.name}
                    className="block w-[20px] mr-4"
                  />
                  <span className="block pinkish">{item.name}</span>
                </button>
                <ul className={selected === item.id || (selected === null && activePage === item.name.toLocaleLowerCase()) ? `ml-5 submenu active` : `ml-5 submenu`}>
                  {item.submenu.map((sub) => (
                    <li key={sub.id} className="mb-1 submenu-item">
                      <Link
                        onClick={()=>handleNavLink(item.id, sub.id)}
                        to={sub.link}
                        className={selectedSubmenu === item.id+'-'+sub.id || (selectedSubmenu === null && activeSupPage === sub.link.split('/')[1]+'-'+sub.link.split('/')[2]) ? `flex justify-start items-center active`:`flex justify-start items-center`}
                      >
                        <span className="block text-white">{sub.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <Link
                to={item.link}
                className={selected === item.id || (selected === null && activePage === item.name.toLocaleLowerCase()) ? `active flex main-menu justify-start items-center sidebar-link`:`flex main-menu justify-start items-center sidebar-link`}
                onClick={()=>toggle2(item.id)}
              >
                <img
                  src={item.icon}
                  alt={item.name}
                  className="block w-[20px] mr-4"
                />
                <span className="block pinkish">{item.name}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
