import React, { useEffect, useState } from "react";
import { Dropdown } from "antd";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaAngleDown, FaTimes, FaDollarSign } from "react-icons/fa";
import services from "../../components/services";
import LoadingSpinner from "../../components/Loading_spinner";
import { format } from 'date-fns';
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function StartDate({value,onClick}){
  return (
    <div className="input-group">
        <input type="text" placeholder="From" className="form-control" value={value} onClick={onClick} readOnly/>
        <div className="input-group-append" onClick={onClick}>
            <span className="input-group-text">
                <FaCalendarAlt/>
            </span>
        </div>
    </div>
  )
}

function EndDate({value,onClick}){
  return (
    <div className="input-group">
        <input type="text" placeholder="To" className="form-control" value={value} onClick={onClick} readOnly/>
        <div className="input-group-append" onClick={onClick}>
            <span className="input-group-text">
                <FaCalendarAlt/>
            </span>
        </div>
    </div>
  )
}

function PaymentDate({value,onClick}){
  return (
    <div className="input-group">
        <input type="text" className="form-control paymentDate" value={value} onClick={onClick} readOnly/>
        <div className="input-group-append" onClick={onClick}>
            <span className="input-group-text">
                <FaCalendarAlt/>
            </span>
        </div>
    </div>
  )
}

const Invoice = () => {
  const [customers, setCustomers] = React.useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = React.useState([]);
  const [unpaid, setUnpaid] = useState(0);
  const [due, setDue] = useState(0);
  const [paid, setPaid] = useState(0);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState('');
  const [payableAmount, setPayableAmount] = useState('');
  const [deposit, setDeposit] = useState('');
  const [depositError, setDepositError] = useState('');
  const [restAmount, setRestAmount] = useState('');
  const [fullAmount, setFullAmount] = useState('');
  const [fullPaymentError, setFullPaymentError] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [activeTab, setActiveTab] = useState('all');


  const navigate = useNavigate();

  const get_invoices = (customer='', status='', from='', to='', tab='') =>{

    setLoading(true);

    fetch(services.url+"/get_invoices.php?customer="+customer+'&status='+status+'&from='+from+'&to='+to+'&tab='+tab, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((res) => {
        setLoading(false);
        // console.log(res);
        setUnpaid(res.unpaid);
        setDue(res.due);
        setPaid(res.paid);
        setInvoices(res.invoices);
      });
  }

  useEffect(() => {
    setLoading(true);
    fetch(services.url+"/get_customer.php", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())

    .then((res) => {
      setLoading(false);
      setCustomers(res);
    });

    get_invoices();

  }, []);

  var items = [
    {
      key: "1",
      label: <button>View</button>,
    },
    {
      key: "2",
      label: <button>Export as pdf</button>,
    },
    {
      key: "3",
      danger: true,
      label: <button>Delete</button>,
      // onClick: () => deleteUser(user.id),
      // get the exact id of the customer and pass it to the handleDelete function
    },
  ];

  var items2 = [
    {
      key: "1",
      label: <button>Export as pdf</button>,
    },
    {
      key: "2",
      danger: true,
      label: <button>Delete</button>,
      // onClick: () => deleteUser(user.id),
      // get the exact id of the customer and pass it to the handleDelete function
    },
  ];

  const handlePaymentModal = (e) => {
    let invoice_id = e.currentTarget.getAttribute("data-payment_invoice_id");
    let payable_amount = e.currentTarget.getAttribute("data-payable_amount");
    // alert(payable_amount);
    setPaymentInvoiceId(invoice_id);
    setPayableAmount(payable_amount);
    setPaymentDate('');
    setDeposit('');
    setRestAmount('');
    setFullAmount('');
    setPaymentMethod('');
    setDepositError('');
    setFullPaymentError('');
    setDisableBtn(true);
    setModalView(true)
  }

  const handlePaymentDate = (date) => {
    setPaymentDate(date);
    if(parseFloat(fullAmount) == parseFloat(payableAmount) && paymentMethod !== ''){
      setDisableBtn(false);
    }else if(deposit !== '' && paymentMethod !== ''){
      setDisableBtn(false);
    }else{
      setDisableBtn(true);
    }
    // setDisableBtn(false);
  }

  const handleDeposit = (e) => {
    const re = /^[0-9\b]+$/;
    
    if(re.test(e.target.value)){
        if(payableAmount.length > 0 && (parseFloat(e.target.value) > parseFloat(payableAmount))){
          setDepositError('Deposit over limit!');
          setTimeout(() => {
            setDepositError('');
          }, 3000);
        }else{
          setDeposit(e.target.value);
          let calRestAmount = payableAmount - e.target.value;
          setRestAmount(calRestAmount);
          setDepositError('');
        }
        
    }else{
      if(e.target.value !== ''){
        setDepositError('Allow only number.');
        setTimeout(() => {
          setDepositError('');
        }, 3000);
      }else{
        setDeposit('');
        setDepositError('Allow only number.');
        setTimeout(() => {
          setDepositError('');
        }, 3000);
      }
      
    }

    if(parseFloat(e.target.value) == parseFloat(payableAmount) && paymentDate !== '' && paymentMethod !== ''){
      setDisableBtn(false);
    }else if(e.target.value !== '' && paymentDate !== '' && paymentMethod !== ''){
      setDisableBtn(false);
    }else{
      setDisableBtn(true);
    }
  }

  const handleFullAmount = (e) =>{
    const re = /^[0-9\b]+$/;
    
    if(re.test(e.target.value)){
        if(payableAmount.length > 0 && (parseFloat(e.target.value) > parseFloat(payableAmount))){
          setDeposit('');
          setRestAmount('');
          setFullPaymentError('Payment over limit!');
          setTimeout(() => {
            setFullPaymentError('');
          }, 3000);
        }else{
          setDeposit('');
          setRestAmount('');
          setFullAmount(e.target.value);
          setFullPaymentError('');
        }
        
    }else{
      if(e.target.value !== ''){
        setFullPaymentError('Allow only number.');
        setTimeout(() => {
          setFullPaymentError('');
        }, 3000);
      }else{
        setFullAmount('');
        setFullPaymentError('Allow only number.');
        setTimeout(() => {
          setFullPaymentError('');
        }, 3000);
      }
      
    }

    if(parseFloat(e.target.value) == parseFloat(payableAmount) && paymentDate !== '' && paymentMethod !== ''){
      setDisableBtn(false);
    }else if(deposit !== '' && paymentDate !== '' && paymentMethod !== ''){
      setDisableBtn(false);
    }else{
      setDisableBtn(true);
    }
  }

  const handlePaymentMethod = (e) => {
    setPaymentMethod(e.currentTarget.value);

    if(parseFloat(fullAmount) == parseFloat(payableAmount) && paymentDate !== '' && e.currentTarget.value !== ''){
      setDisableBtn(false);
    }else if(deposit !== '' && paymentDate !== '' && e.currentTarget.value !== ''){
      setDisableBtn(false);
    }else{
      setDisableBtn(true);
    }
  }

  const view_project = (id) => {
    // /invoices/details
    navigate('/invoices/details/'+id);
  }

  const export_pdf = (id) => {
    // /invoices/exportpdf
    navigate('/invoices/exportpdf/'+id);
  }

  const handleSubmitPayment = (e) => {
    
    let payDate = format(paymentDate, 'yyyy-MM-dd');

    const data = {
      paymentInvoiceId,
      paymentDate:payDate,
      deposit,
      restAmount,
      fullAmount,
      paymentMethod
    }
    // console.log(data);
    
    setPaymentDate('');
    setDeposit('');
    setRestAmount('');
    setFullAmount('');
    setPaymentMethod('');
    setDepositError('');
    setFullPaymentError('');
    setDisableBtn(true);
    setModalView(false);

    setLoading(true);
    
    fetch(services.url+"/payment_invoice.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // }
      body: JSON.stringify(data),
    })
    .then((res) => res.json())

    .then((res) => {
      setLoading(false);
      get_invoices();
      Swal.fire({
        title: "Success!",
        text: "Invoice payment done!",
        icon: "success",
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.isConfirmed) {
            
        }
        
      });

    });
    
  }

  const handleCustomer = (e) => {
    let customer = e.currentTarget.value;
    let status = document.getElementById('status').value;
    let from = '';
    let to = '';
    if(startDate !== null){
      from = format(startDate, 'yyyy-MM-dd');
    }
    if(endDate !== null){
      to = format(endDate, 'yyyy-MM-dd');
    }
    setActiveTab('all');
    get_invoices(customer, status, from, to);
  }

  const handleStatus = (e) => {
    let status = e.currentTarget.value;
    let customer = document.getElementById('customer').value;
    let from = '';
    let to = '';
    if(startDate !== null){
      from = format(startDate, 'yyyy-MM-dd');
    }
    if(endDate !== null){
      to = format(endDate, 'yyyy-MM-dd');
    }
    if(status == 0){
      setActiveTab('unpaid');
    }else{
      setActiveTab('all');
    }
    
    get_invoices(customer, status, from, to);
  }
  
  const handleStartDate = (date) => {
    setStartDate(date)
    let customer = document.getElementById('customer').value;
    let status = document.getElementById('status').value;
    let from = format(date, 'yyyy-MM-dd');
    let to = '';
    if(endDate !== null){
      to = format(endDate, 'yyyy-MM-dd');
    }
    setActiveTab('all');
    get_invoices(customer, status, from, to);
  }

  const handleEndDate = (date) => {
    setEndDate(date)
    let customer = document.getElementById('customer').value;
    let status = document.getElementById('status').value;
    let from = '';
    
    if(startDate !== null){
      from = format(startDate, 'yyyy-MM-dd');
    }
    let to = format(date, 'yyyy-MM-dd');

    setActiveTab('all');
    get_invoices(customer, status, from, to);
  }

  const handleTab = (tab) => {
    let customer = document.getElementById('customer').value;
    let status = document.getElementById('status').value;
    let from = '';
    let to = '';
    if(startDate !== null){
      from = format(startDate, 'yyyy-MM-dd');
    }
    if(endDate !== null){
      to = format(endDate, 'yyyy-MM-dd');
    }
    setActiveTab(tab);
    get_invoices(customer, status, from, to, tab);
  }

  const deleteInvoice = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id
        };
        fetch(services.url+"/delete_project.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          get_invoices();
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        });
      }
      
    });
  }

  return (
    <div>
      {modalView ? <div className="full_body"></div> : ''}
      {modalView ?
       <div className="record_payment_modal">
        <div className="record_payment_modal_header">
          <div className="header-title">
            Record a payment for this invoice
          </div>
          <div className="close-btn" onClick={()=>setModalView(false)}>
            <span><FaTimes/></span>
          </div>
        </div>
        <div className="record_payment_modal_body">
          <p>Record a payment you've already received, such as cash, check, or bank payment.</p>
          <div className="record_payment_date">
            <label htmlFor="">Payment date</label>
            <Datepicker showMonthDropdown showYearDropdown selected={paymentDate} onChange={date=>handlePaymentDate(date)} shouldCloseOnSelect customInput={<PaymentDate/>}/>
          </div>
          <div className="record_payment_subpart">
              <span>IF HAVE DEPOSITE</span>
          </div>
          <div style={{textAlign:"center", width:"75%", margin:"auto"}}>
            <div className="record_payment_input">
              <label htmlFor="">Deposit</label>
              <div className="input-group">
                  <div className="input-group-append">
                      <span className="input-group-text">
                          <FaDollarSign/>
                      </span>
                  </div>
                  <input type="text" className="form-control" value={deposit} onChange={handleDeposit}/>
                  <span className="validation-error text-danger">{depositError}</span>
              </div>
            </div>
            <div className="record_payment_input">
              <label htmlFor="">Rest or Full Amount</label>
              <div className="input-group">
                  <div className="input-group-append">
                      <span className="input-group-text">
                          <FaDollarSign/>
                      </span>
                  </div>
                  <input type="text" readOnly value={restAmount} className="form-control"/>
              </div>
            </div>
          </div>
          <div className="record_payment_subpart">
              <span>IF PAYMENT ONE TIME (NO DEPOSITE)</span>
          </div>
          <div style={{textAlign:"center", width:"75%", margin:"auto"}}>
            <div className="record_payment_input">
              <label htmlFor="">Full Amount</label>
              <div className="input-group">
                  <div className="input-group-append">
                      <span className="input-group-text">
                          <FaDollarSign/>
                      </span>
                  </div>
                  <input type="text" value={fullAmount} onChange={handleFullAmount} className="form-control"/>
                  <span className="validation-error text-danger">{fullPaymentError}</span>
              </div>
            </div>
            <div className="record_payment_input">
              <label htmlFor="">Payment method</label>
              <div className="input-group">
                <select class="form-select" onChange={handlePaymentMethod} aria-label="Default select example">
                  <option value='' selected>Select a payment method...</option>
                  <option value="PAYPAL">PAYPAL</option>
                  <option value="BANK TRANSFER">BANK TRANSFER</option>
                  <option value="PAYMENT ONLINE">PAYMENT ONLINE</option>
                  <option value="CASH">CASH</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="record_payment_footer">
          <button onClick={()=>setModalView(false)}>Back</button>
          <button onClick={()=>setModalView(false)}>Cancel</button>
          <button disabled={disableBtn} onClick={handleSubmitPayment} >Submit</button>
        </div>
      </div>
       : ''}
      
      <div className="card p-10 bg-white">
        <h2 className="text-3xl font-bold">Invoices</h2>
        <div className="card mt-8 border md:w-1/2 w-full p-4">
          <div className="flex justify-between items-start border-b mb-5 pb-2">
            <div className="left w-1/2">
              <p className="font-bold">Overdue</p>
              <p className="text-2xl">
                ${due} <span className="text-red text-lg">USD</span>
              </p>
            </div>
            <div className="left w-1/2">
              <p className="font-bold">Paid</p>
              <p className="text-2xl">
                ${paid} <span className="text-red text-lg">USD</span>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex justify-start items-center">
          <form>
            <div className="row">
                <div className="col-md-3 col-sm-6 mb-2">
                  <select name="" className="form-select" id="customer" onChange={handleCustomer}>
                    <option value="">All Project</option>
                    {customers.map((customer) => (
                      <option value={customer.id}>
                        {customer.companyName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3 col-sm-6 mb-2">
                  <select name="" className="form-select" id="status" onChange={handleStatus}>
                    <option value="">All Status</option>
                    <option value="0">Waiting</option>
                    <option value="1">Done</option>
                  </select>
                </div>
                <div className="col-md-6 col-sm-6 mb-2">
                  <div className="date_start_end">
                    <Datepicker showMonthDropdown showYearDropdown selected={startDate} onChange={date=>handleStartDate(date)} maxDate={endDate} shouldCloseOnSelect customInput={<StartDate/>}/>
                      &nbsp;
                    <Datepicker showMonthDropdown showYearDropdown selected={endDate} minDate={startDate} onChange={date=>handleEndDate(date)} customInput={<EndDate/>}/>
                  </div>
                </div>
            </div>
        </form>
            {/* <form
              action=""
              className="w-full flex gap-4 justify-between items-center"
            >
              <select name="" className="border w-full p-3 rounded" id="">
                {customers.map((customer) => (
                  <option value={customer.companyName}>
                    {customer.companyName}
                  </option>
                ))}
              </select>
              <select name="" className="border w-full p-3 rounded" id="">
                <option value="inactive">Inactive</option>
                <option value="active">Inactive</option>
              </select>
              <Datepicker showMonthDropdown showYearDropdown selected={startDate} onChange={date=>setStartDate(date)} maxDate={endDate} shouldCloseOnSelect customInput={<StartDate/>}/>
                &nbsp;
              <Datepicker showMonthDropdown showYearDropdown selected={endDate} minDate={startDate} onChange={date=>setEndDate(date)} customInput={<EndDate/>}/>
            </form> */}
          </div>
        </div>

        <div className="mt-3">
            <div className="invoice-tabs">
              <div className="invoice-tab-btns">
                <button type="button" onClick={()=>handleTab('unpaid')} class={activeTab === 'unpaid' ? 'invoice-tabs-btn btn-primary active' : 'invoice-tabs-btn btn-primary'}>
                  Unpaid <span class="badge invoice-unpaid-badge rounded-circle">{unpaid}</span>
                </button>
                <button type="button" onClick={()=>handleTab('all')} class={activeTab === 'all' ? 'invoice-tabs-btn btn-primary active' : 'invoice-tabs-btn btn-primary'}>
                  All invoices
                </button>
              </div>
            </div>
        </div>

        <div className="mt-3">
          <table className="w-full table">
            <thead>
              <th className="p-3 border-b text-center">Status</th>
              <th className="p-3 border-b text-center">Date</th>
              <th className="p-3 border-b text-center">Number</th>
              <th className="p-3 border-b text-center">Customer</th>
              <th className="p-3 border-b text-center">Amount Due</th>
              <th className="p-3 border-b text-center">Actions</th>
            </thead>

            <tbody>
              {
                invoices.map((invoice,key) => (
                  <tr>
                    <td className="text-center p-3 border-t border-b">
                      {invoice.paid == 1 ?
                        <span className="bg-green-800 d-block px-2 py-1 rounded-md text-white">
                          Done
                        </span>
                        :
                        <span className="bg-red-500 d-block px-2 py-1 rounded-md text-white">
                          Waiting
                        </span>
                       }
                    </td>
                    <td className="text-center p-3 border-t border-b">
                      {/* 12/01/2023 */}
                      <span className="text-black">{format(invoice.startDate, 'MM/dd/yyyy')}</span>
                    </td>
                    <td className="text-center p-3 border-t border-b">
                      <span className="text-black">0</span>
                    </td>
                    <td className="text-center p-3 border-t border-b">
                      <span className="text-black">{invoice.companyName}</span>
                    </td>
                    <td className="text-center p-3 border-t border-b">
                      <span className="text-black">
                        ${invoice.amountDue}
                      </span>
                    </td>
                    <td className="text-center p-3 border-t border-b">
                      <div className="action-btns">
                        {
                          invoice.due == 1 ?
                          <>
                            <button data-payment_invoice_id={invoice.id} data-payable_amount={invoice.amountDue} onClick={handlePaymentModal}>
                              Record Payment
                            </button>
                            <Dropdown
                              menu={{
                                items:[
                                  {
                                    key: "1",
                                    label: <button>View</button>,
                                    onClick: () => view_project(invoice.id),
                                  },
                                  {
                                    key: "2",
                                    label: <button>Export as pdf</button>,
                                    onClick: () => export_pdf(invoice.id)
                                  },
                                  {
                                    key: "3",
                                    danger: true,
                                    label: <button>Delete</button>,
                                    onClick: () => deleteInvoice(invoice.id)
                                    // onClick: () => deleteUser(user.id),
                                    // get the exact id of the customer and pass it to the handleDelete function
                                  },
                                ],
                              }}
                              trigger={["click"]}
                            >
                            <button onClick={(e) => e.preventDefault()}>
                              <FaAngleDown/>
                            </button>
                            </Dropdown>
                          </>
                          :
                          <>
                              <button onClick={()=>view_project(invoice.id)}>
                                View
                              </button>
                              <Dropdown
                                menu={{
                                items : [
                                  {
                                    key: "1",
                                    label: <button>Export as pdf</button>,
                                    onClick: () => export_pdf(invoice.id)
                                  },
                                  {
                                    key: "2",
                                    danger: true,
                                    label: <button>Delete</button>,
                                    onClick: () => deleteInvoice(invoice.id)
                                    // onClick: () => deleteUser(user.id),
                                    // get the exact id of the customer and pass it to the handleDelete function
                                  },
                                ],
                                }}
                                trigger={["click"]}
                              >
                              <button onClick={(e) => e.preventDefault()}>
                                <FaAngleDown/>
                              </button>
                              </Dropdown>
                          </>
                        }
                        
                      </div>
                    </td>
                  </tr>
                ))
              }
              {/* <tr>
                <td className="text-center p-3 border-t border-b">
                  <span className="bg-red-500 d-block px-2 py-1 rounded-md text-white">
                    Waiting
                  </span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">12/01/2023</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">30</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">Hermes</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">$3000</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <div className="action-btns">
                    <button onClick={()=>setModalView(true)}>
                      Record Payment
                    </button>
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                    <button onClick={(e) => e.preventDefault()}>
                      <FaAngleDown/>
                    </button>
                    </Dropdown>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-center p-3 border-t border-b">
                  <span className="bg-red-500 d-block px-2 py-1 rounded-md text-white">
                    Waiting
                  </span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">12/01/2023</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">30</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">Hermes</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">$3000</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <div className="action-btns">
                    <button onClick={()=>setModalView(true)}>
                      Record Payment
                    </button>
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                    <button onClick={(e) => e.preventDefault()}>
                      <FaAngleDown/>
                    </button>
                    </Dropdown>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-center p-3 border-t border-b">
                  <span className="bg-green-800 d-block px-2 py-1 rounded-md text-white">
                    Done
                  </span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">12/04/2023</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">30</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">Hermes</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">$3000</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <div className="action-btns">
                    <button>
                      View
                    </button>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: <button>Export as pdf</button>,
                          },
                          {
                            key: "2",
                            danger: true,
                            label: <button>Delete</button>,
                            // onClick: () => deleteUser(user.id),
                            // get the exact id of the customer and pass it to the handleDelete function
                          },
                        ],
                      }}
                      trigger={["click"]}
                    >
                    <button onClick={(e) => e.preventDefault()}>
                      <FaAngleDown/>
                    </button>
                    </Dropdown>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-center p-3 border-t border-b">
                  <span className="bg-green-800 d-block px-2 py-1 rounded-md text-white">
                    Done
                  </span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">12/05/2023</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">30</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">Hermes</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <span className="text-black">$3000</span>
                </td>
                <td className="text-center p-3 border-t border-b">
                  <div className="action-btns">
                    <button>
                      View
                    </button>
                    <Dropdown
                      menu={{
                       items : items2,
                      }}
                      trigger={["click"]}
                    >
                    <button onClick={(e) => e.preventDefault()}>
                      <FaAngleDown/>
                    </button>
                    </Dropdown>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      {loading ? <LoadingSpinner/>:''}
    </div>
  );
};

export default Invoice;
