import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import services from "../../components/services";
import LoadingSpinner from "../../components/Loading_spinner";
import Spinner from "../../components/spinner";
import Pagination from "../../components/pagination";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const MaterialsCollection = () => {
  

  const [melamine, setMelamine] = React.useState([]);
  const [screenPartition, setScreenPartition] = React.useState([]);
  const [colorDoor, setColorDoor] = React.useState([]);
  const [loading, setLoading] = useState(false);
  
  const {id} = useParams();

  

  useEffect(() => {
    let data = {material_id:id};
    setLoading(true);
    fetch(services.url+"/get_materials3.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        // console.log(res);
        setMelamine(res.melamine);
        setScreenPartition(res.screen_partition);
        setColorDoor(res.color_door);
        // console.log(subcategory);
      });

  }, []);


  return (
    <div className="customers">
      <TopBar title="Materials"/>
      
      <h1 style={{marginTop:'15px', marginLeft:'10px', fontWeight:'bold'}}>Melamine</h1>
      <div className="melamine_box">
        <div className="material_data_box">
        {melamine.map((melamine, key)=>(
            <div className='material_data_box_item' data-index={melamine.index} key={key}>
                <div className="material_data_box_item_image">
                <img src={services.url+melamine.image}/>
                <input type="hidden" value={melamine.image} id={"melamine_image-"+melamine.index}/>
                </div>
                <div className="material_data_box_item_name">
                <h1>{melamine.name}</h1>
                <input type="hidden" value={melamine.name} id={"melamine_name-"+melamine.index}/>
                </div>
            </div>
        ))}
        </div>
      </div>
    <h1 style={{marginTop:'15px', marginLeft:'10px', fontWeight:'bold'}}>Screen Partition</h1>
    <div className="screen_partition_box">
        <div className="material_data_box">
            {screenPartition.map((screenPartition, key)=>(
                <div className='material_data_box_item' data-index={screenPartition.index} key={key}>
                    <div className="material_data_box_item_image">
                    <img src={services.url+screenPartition.image}/>
                    <input type="hidden" value={screenPartition.image} id={"screen_partition_image-"+screenPartition.index}/>
                    </div>
                    <div className="material_data_box_item_name">
                    <h1>{screenPartition.name}</h1>
                    <input type="hidden" value={screenPartition.name} id={"screen_partition_name-"+screenPartition.index}/>
                    </div>
                </div>
            ))}
        </div>
    </div>
    <div style={{marginTop:'15px', marginLeft:'10px'}}><span style={{fontWeight:'bold'}}>Color Door</span><span>(optional)</span></div>
    <div className="color_door_box">
        <div className="material_data_box">
            {colorDoor.map((colorDoor,key)=>(
                <div className='material_data_box_item' data-index={colorDoor.index} key={key}>
                    <div className="material_data_box_item_image">
                    <img src={services.url+colorDoor.image}/>
                    <input type="hidden" value={colorDoor.image} id={"color_door_image-"+colorDoor.index}/>
                    </div>
                    <div className="material_data_box_item_name">
                    <h1>{colorDoor.name}</h1>
                    <input type="hidden" value={colorDoor.name} id={"color_door_name-"+colorDoor.index}/>
                    </div>
                </div>
            ))}
        </div>
    </div>

      {loading ? <LoadingSpinner/> : ""}
      <Footer/>
    </div>
  );
};

export default MaterialsCollection;
