import React from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import AllMaterials from "../../components/all-materials";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const Materials = () => {
  
  return (
    <div className="customers">
      <TopBar title="Materials"/>
      
      <AllMaterials />

      <Footer/>
    </div>
  );
};

export default Materials;
