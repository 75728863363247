import React from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import CollectionCard from "../collection-card";

const ProductDetails = ({ collections, deleteSubcategory }) => {
  return (
    <>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-[20px]">
        {collections.map((customer) => (
          <CollectionCard customer={customer} key={customer.id} deleteSubcategory={deleteSubcategory} />
        ))}
      </div>
    </>
  );
};

export default ProductDetails;
