import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/main.layout";
import Customers from "../pages/customers";
import AddNewCustomer from "../pages/add-new-customer";
import Dashboard from "../pages/dashboard";
import Projects from "../pages/projects";
import AddNewProject from "../pages/add-new-project";
import Step2 from "../pages/add-new-project/step2";
import Step3 from "../pages/add-new-project/step3";
import Products from "../pages/products";
import Materials from "../pages/materials";
import ProductDetailsPage from "../pages/product-details";
import AddNewProduct from "../pages/add-new-product";
import MaterialsDetails from "../pages/material-details";
import AddNewMaterial from "../pages/add-new-material";
import Supplier from "../pages/supplier";
import AddNewSupplier from "../pages/add-new-supplier";
import Categories from "../pages/categories";
import MaterialCategories from "../pages/material-categories";
import AddNewCategory from "../pages/add-new-category";
import AddNewMaterialCategory from "../pages/add-new-material-category";
import CustomProduct from "../pages/custom-product";
import ProjectProduct from "../pages/project-product";
import ProjectMaterial from "../pages/project-material";
import User from "../pages/user";
import AddNewUser from "../pages/add-new-user";
import UserProfile from "../pages/user-profile";
import Invoice from "../pages/invoice";
import UpdateCategory from "../pages/update-category";
import UpdateMaterialCategory from "../pages/update-material-category";
import CollectionProduct from "../pages/product-collection";
import MaterialsCollection from "../pages/material-collection";
import EditSupplier from "../pages/edit-supplier";
import UpdateCustomer from "../pages/edit-customer";
import Login from "../pages/login-page";
import Quotes from "../pages/quotes";
import Message from "../pages/message";
import UpdateUser from "../pages/update-user";
import ProjectsSupplier from "../pages/project-supplier";
import ProjectsSupplierDetails from "../pages/project-supplier-details";
import ProjectsSupplierUpdate from "../pages/project-supplier-update";
import ProjectsDetails from "../pages/project-details";
import ProjectsValidation from "../pages/project-validation";
import InvoiceDetails from "../pages/invoice_details";
import InvoiceExportPdf from "../pages/invoice-export-pdf";
import AddNewMaterialMultiple from "../pages/add-new-material-multiple";
import AddNewProductMultiple from "../pages/add-new-product-multiple";
import ProjectMaterialSingle from "../pages/project-material-single";
import CreateImageLink from "../pages/create-image-link";


const router = createBrowserRouter([
  {
      path: "/",
      element: <Login />,
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/customer/all",
        element: <Customers />,
      },
      {
        path: "/customer/add-new",
        element: <AddNewCustomer />,
      },
      {
        path: "/customer/edit/:id",
        element: <UpdateCustomer />,
      },
      {
        path: "/supplier/all",
        element: <Supplier />,
      },
      {
        path: "/supplier/add-new",
        element: <AddNewSupplier />,
      },
      {
        path: "/supplier/edit/:id",
        element: <EditSupplier />,
      },
      {
        path: "/projects/all",
        element: <Projects />,
      },
      {
        path: "/projects/details/:id",
        element: <ProjectsDetails />,
      },
      {
        path: "/projects/validation/:id",
        element: <ProjectsValidation />,
      },
      {
        path: "/projects/add-new",
        element: <AddNewProject />,
      },
      {
        path: "/projects/add-new/step-2",
        element: <Step2 />,
      },
      {
        path: "/projects/add-new/step-3",
        element: <Step3 />,
      },
      {
        path: "/projects/add-new/custom-product",
        element: <CustomProduct />,
      },
      {
        path: "/projects/add-new/project-product",
        element: <ProjectProduct />,
      },
      {
        path: "/projects/add-new/project-material/:product_id",
        element: <ProjectMaterial/>,
      },
      {
        path: "/projects/add-new/project-material-single/:product_id",
        element: <ProjectMaterialSingle/>,
      },
      {
        path: "/projects/supplier",
        element: <ProjectsSupplier />,
      },
      {
        path: "/projects/supplier/:id",
        element: <ProjectsSupplierDetails />,
      },
      {
        path: "/projects/supplier/edit/:id",
        element: <ProjectsSupplierUpdate />,
      },
      {
        path: "/products/all-products",
        element: <Products />,
      },
      {
        path: "/products/:id",
        element: <ProductDetailsPage />,
      },
      {
        path: "/products/collection/:id",
        element: <CollectionProduct/>,
      },
      {
        path: "/products/add-new",
        element: <AddNewProduct />,
      },
      {
        path: "/products/add-multiple",
        element: <AddNewProductMultiple />,
      },
      {
        path: "/product/categories",
        element: <Categories />,
      },
      {
        path: "/product/categories/add-new",
        element: <AddNewCategory />,
      },
      {
        path: "/product/categories/:id/edit",
        element: <UpdateCategory />,
      },
      {
        path: "/link_generator",
        element: <CreateImageLink/>,
      },
      {
        path: "/material",
        element: <Materials />,
      },
      {
        path: "/material/all-materials",
        element: <Materials />,
      },
      {
        path: "/material/:id",
        element: <MaterialsDetails />,
      },
      {
        path: "/material/collection/:id",
        element: <MaterialsCollection />,
      },
      {
        path: "/material/add-new",
        element: <AddNewMaterial />,
      },
      {
        path: "/material/add-single",
        element: <AddNewMaterial />,
      },
      {
        path: "/material/add-multiple",
        element: <AddNewMaterialMultiple />,
      },
      {
        path: "/material/categories",
        element: <MaterialCategories />,
      },
      {
        path: "/material/categories/:id/edit",
        element: <UpdateMaterialCategory />,
      },
      {
        path: "/material/categories/add-new",
        element: <AddNewMaterialCategory />,
      },
      {
        path: "/users/all",
        element: <User />,
      },
      {
        path: "/users/add-new",
        element: <AddNewUser />,
      },
      {
        path: "/user/edit/:id",
        element: <UpdateUser />,
      },
      {
        path: "/users/:id",
        element: <UserProfile />,
      },
      {
        path: "/users/profile",
        element: <UserProfile />,
      },
      {
        path: "/invoices",
        element: <Invoice />,
      },
      {
        path: "/invoices/details/:id",
        element: <InvoiceDetails />,
      },
      {
        path: "/invoices/exportpdf/:id",
        element: <InvoiceExportPdf/>,
      },
      {
        path: "/quotes",
        element: <Quotes/>
      },
      {
        path: "/message",
        element: <Message/>
      },
      {
        path: "*",
        element: <h2>Page Not Found</h2>,
      },
    ],
  },
]);

export default router;
