import axios from "axios";
import React, {useEffect, useState} from "react";
import { FaBell, FaCog, FaUser } from "react-icons/fa";
import { Link, useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../components/services";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";

const EditSupplier = () => {


  const {id} = useParams();

  const navigate = useNavigate();

  const [logoUrl, setLogoUrl] = React.useState(null);
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [categoryProduct, setCategoryProduct] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [description, setDescription] = useState('');
  
  useEffect(() => {

    let data = {supplier_id:id};

    fetch(services.url+"/get_supplier_for_edit.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setLogoUrl(res.logo);
        setCompanyName(res.companyName);
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setPhone(res.phone);
        setAddress(res.address)
        setCategoryProduct(res.category);
        setEmail(res.email);
        setCountry(res.country);
        setProvince(res.province);
        setDescription(res.description);
      });
      // setLoading(true);

  }, []);

  const handleLogoUpload = (e) => {

    if(e.target.files.length == 0){

    }else{
        const payload = new FormData();
        payload.append("image", e.target.files[0]);

        axios
        .post(
            "https://api.imgbb.com/1/upload?key=bcc56bad76ece711720725d2a509d3dc",
            payload
        )
        .then((response) => {
            // console.log("response", response);
            // console.log("response url", response.data.data.image.url);
            setLogoUrl(response.data.data.image.url);
            // console.log("success");
        })
        .catch((error) => {
            console.log("error", error);
            alert("try another image agian");
        });
    }
    
    // imgbbUploader()
  };
  const handleUpdateSupplier = (e) => {
    e.preventDefault();

    const form = e.target;

    const logo = logoUrl;
    const companyName = form.companyName.value;
    const firstName = form.firstName.value;
    const phone = form.phone.value;
    const address = form.address.value;
    const email = form.email.value;
    const lastName = form.lastName.value;
    const country = form.country.value;
    const province = form.province.value;
    const category = form.category.value;
    const description = form.description.value;

    const data = {
      id,
      logo,
      companyName,
      firstName,
      lastName,
      phone,
      email,
      address,
      country,
      province,
      category,
      description,
    };
    // console.log(data);
    
    
    fetch(services.url+"/update_supplier.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if(res.status === true){
          Swal.fire({
            title: "Success!",
            text: "Supplier has been updated successfully!",
            icon: "success",
            confirmButtonText: "Okay",
          }).then((result) => {
            if (result.isConfirmed) {
                navigate('/supplier/all');
            }
            
          });
        //   form.reset();
        }else{
          Swal.fire({
            title: "Fail!",
            text: "Supplier updated fail!",
            icon: "warning",
            confirmButtonText: "Okay",
          });
        }
        
      });
      
  };
  return (
    <div>
      <TopBar title="Edit Customer"/>
      
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Supplier / Factory Details</p>
        </div>
        <form
          onSubmit={handleUpdateSupplier}
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          <div className="flex md:flex-row flex-col gap-5 justify-between items-start">
            <div className="lg:w-[2/12] md:w-1/2 w-full">
              <p className="text-blue font-bold">Logo Company *</p>
              <div className="form_logo_box">
              {logoUrl && (
                <img
                  src={logoUrl}
                  alt="logo"
                  className="rounded-full"
                />
              )}
              </div>
              <label
                htmlFor="logo"
                className="logo-uploader block text-center mt-4"
              >
                <input type="file" name="logo" id="logo" onChange={handleLogoUpload} />
                <span className="text-sm text-gray-400">
                  Drag and drop or click here to select file
                </span>
              </label>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">Name of Company *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Samantha"
                  name="companyName"
                  required
                  id=""
                  value={companyName}
                  onChange={(e)=>setCompanyName(e.target.value)}
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">
                  First Name* (Representant)
                </p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Jakarta"
                  name="firstName"
                  required
                  id=""
                  value={firstName}
                  onChange={(e)=>setFirstName(e.target.value)}
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Phone *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="+1234567890"
                  name="phone"
                  required
                  id=""
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Address *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Address"
                  name="address"
                  required
                  id=""
                  value={address}
                  onChange={(e)=>setAddress(e.target.value)}
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Category Product *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Category product"
                  name="category"
                  required
                  id=""
                  value={categoryProduct}
                  onChange={(e)=>setCategoryProduct(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[5/12] md:w-[1/2] w-full">
              <div className="form-group">
                <p className="text-blue font-bold mb-4">Email *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="william@mail.com"
                  name="email"
                  required
                  id=""
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Last Name *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Mana William"
                  name="lastName"
                  required
                  id=""
                  value={lastName}
                  onChange={(e)=>setLastName(e.target.value)}
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Country *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="United States"
                  name="country"
                  required
                  id=""
                  value={country}
                  onChange={(e)=>setCountry(e.target.value)}
                />
              </div>
              <div className="form-group mt-6">
                <p className="text-blue font-bold mb-4">Province *</p>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  placeholder="Province "
                  name="province"
                  required
                  id=""
                  value={province}
                  onChange={(e)=>setProvince(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="form-group lg:w-10/12 px-12 py-6 ml-auto">
            <p className="text-blue font-bold mb-4">description *</p>
            <textarea
              name="description"
              id="description"
              className="w-full p-2 border rounded"
              cols="30"
              rows="5"
              required
              placeholder="Description"
              onChange={(e)=>setDescription(e.target.value)}
              value={description}
            >
            </textarea>
          </div>

          <div className="text-right mt-7">
            <button
              type="submit"
              className="btn-blue bg-[#4D43B5] text-white px-8 py-2 rounded"
            >
              Update
            </button>
          </div>
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default EditSupplier;
