import React, { useEffect, useState } from "react";
import { Dropdown } from "antd";
import {
  FaBell,
  FaCaretLeft,
  FaCaretRight,
  FaCog,
  FaEllipsisH,
  FaSearch,
  FaUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import services from "../../components/services";
// import Pagination from "../pagination";
// import LoadingSpinner from "../../components/Loading_spinner";
import Pagination from "../../components/pagination";
import LoadingSpinner from "../../components/Loading_spinner";
import Spinner from "../../components/spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import Swal from "sweetalert2";

const User = () => {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [dataLength, setDataLength] = useState(null);
  const [userFound, setUserFound] = useState(false);
  const [execute, setExecute] = useState(false);

  const getUsers = (pageNo, searchText='') => {
    // console.log(page);
    fetch(services.url+"/users.php?per_page="+limit+"&page="+pageNo+"&search_text="+searchText)
      .then((res) => res.json())
      .then((res) => {
        setExecute(true);
        setDataLength(res.no_of_rows);
        setUsers(res.users);
        setLoading(false);
        setSearchLoading(false);
        if(res.found === 1){
          setUserFound(true);
        }else{
          setUserFound(false);
        }
        console.log(res);
      });
  };
  

  useEffect(() => {
    setLoading(true);
    getUsers(page);
  }, []);

  let totalPage = Math.ceil(dataLength/limit);
  let start = (page - 1) * limit + 1;
  let end = page * limit;

  function handlePageChange(value){
    let pageno = page;
    if(value === "... "){
      setPage(1);
      pageno = 1;
    }
    else if(value === "pre"){
      if(page !== 1){
        setPage(page - 1);
        pageno = page - 1;
      }
    }else if(value === "next"){
      if(page !== totalPage){
        setPage(page + 1);
        pageno = page + 1;
      }
    }else if(value === " ..."){
      setPage(totalPage);
      pageno = totalPage;
    }else{
      setPage(value);
      pageno = value;
    }
    setLoading(true);
    getUsers(pageno);
  }

  const handleSearch = (ele) => {
    let searchText = ele.currentTarget.value;
    setSearchLoading(true);
    getUsers(1, searchText);
  }

  const items = [
    {
      key: "1",
      danger: true,
      label: <button>Delete</button>,
    },
  ];

  const deleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          userId
        };

        setLoading(true);
        fetch(services.url+"/delete_user.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          setPage(1);
          getUsers(1);
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        });
      }
      
    });
  }

  return (
    <div>
      <TopBar title="All Users"/>
      
      <div className="mt-10 bg-white p-10 rounded-md">
        <div class="table-responsive">
        <table className="table w-full">
          <thead>
            <tr>
              <th className="text-blue p-3 text-left border-b">Username</th>
              <th className="text-blue p-3 text-left border-b">Name</th>
              <th className="text-blue p-3 text-left border-b">Email</th>
              <th className="text-blue p-3 text-left border-b">Company</th>
              <th className="text-blue p-3 text-left border-b">Role</th>
              <th className="text-blue p-3 text-center border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              var items = [
                {
                  key: "1",
                  label: (
                    <a rel="noopener noreferrer" href={'/user/edit/'+user.id}>
                      Edit
                    </a>
                  ),
                },
                {
                  key: "2",
                  danger: true,
                  label: <button onClick={() => deleteUser(user.id)}>Delete</button>,
                  // onClick: () => deleteUser(user.id),
                  // get the exact id of the customer and pass it to the handleDelete function
                },
              ];
              return (
                <tr key={user.id}>
                  <td className="text-blue p-3 text-left border-b">
                    {/* <Link to={`/users/${user.id}`}>{user.userName}</Link> */}
                    {user.userName}
                  </td>
                  <td className="text-blue p-3 text-left border-b">
                    {user.firstName} {user.lastName}
                  </td>
                  <td className="text-blue p-3 text-left border-b">
                    {user.email}
                  </td>
                  <td className="text-blue p-3 text-left border-b">
                    <Link to={user.website}>{user.website}</Link>
                  </td>
                  <td className="text-blue p-3 text-left border-b">
                    {user.role}
                  </td>
                  <td className="text-blue p-3 text-center border-b">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                  >
                    <a className="action_btn" onClick={(e) => e.preventDefault()}>
                      <FaEllipsisH className="text-[#a098ae] ml-auto block" />
                    </a>
                  </Dropdown>
                    {/* <button>
                      <FaEllipsisH className="text-xl text-blue" />
                    </button> */}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
      </div>

      <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} start={start} end={end} totalData={dataLength} onPageChange={handlePageChange}/>

      {loading ? <LoadingSpinner/> : ""}
      <Footer/>
    </div>
  );
};

export default User;
