import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaUser, FaTrash, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import services from "../../components/services";
import '../../assets/css/add_product.css';
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import axios from "axios";

const CreateImageLink = () => {

  const [logoUrl, setLogoUrl] = React.useState([]);
  const [isLoading, setLoading] = useState(false);
  
  function handleFileChange(e) {
    //   console.log(e.target.files);
    if(e.target.files.length > 0){
      let files = Array.from(e.target.files);
      let total = files.length;
      let count = 1;

      setLoading(true);

      // let newArray = [];

      files.forEach(file=>{
          // console.log(file);
          const payload = new FormData();
          payload.append("image", file);

          axios
              .post(
              services.url+'link_generate.php',
              payload,
              {
                headers:{
                  'content-type':'multipart/form-data'
                }
              }
              )
              .then((response) => {
              // console.log("response", response.data.url);
              // console.log("response url", response.data.data.image.url);
              let newObject = {url:response.data.url};
              logoUrl.push(newObject);
              
              setLogoUrl(logoUrl);
              // console.log(count);
              if(total == count){
                  setLoading(false);
                  // console.log('done');
              }
              count++;
              // setLogoUrl(response.data.data.image.url);
              
              // console.log("success");
              })
              .catch((error) => {
              console.log("error", error);
              alert("try another image agian");
              });
              
              
      })
    }
    
  }

  return (
    <div>
      <TopBar title="Link Generator"/>
      
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">Image Details</p>
        </div>
        <form
          action=""
          className="form-body bg-white p-8 rounded-b-[10px]"
        >
          <div className="flex md:flex-row flex-col gap-5 justify-between items-start">
            <div className="lg:w-[2/12] md:w-1/2 w-full">
              <p className="text-blue font-bold">Photo Product *</p>
              <label
                htmlFor="logo"
                className="logo-uploader block text-center mt-4"
              >
                <input type="file" multiple name="logo" id="logo" onChange={handleFileChange} />
                <span className="text-sm text-gray-400">
                  Drag and drop or click here to select file
                </span>
              </label>
            </div>
            <div className="w-full">
              {/* <textarea style={{border:'1px solid', width:'100%', height:'300px'}}></textarea> */}
              {logoUrl.map((url, key)=>(
                <p key={key}>
                    {url.url}
                </p>
              ))}
            </div>
          </div>
          {isLoading ? <LoadingSpinner/> : ""}
        </form>
      </div>
      <Footer/>
    </div>
  );
};

export default CreateImageLink;
