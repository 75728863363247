import React, {useState, useEffect} from "react";
import Sidebar from "../components/sidebar";
import { Navigate, Outlet } from "react-router-dom";

const MainLayout = () => {

  const [showSideBar, setShowSideBar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  window.addEventListener('resize', function(event) {
    if (window.matchMedia('screen and (max-width: 1023px)').matches) {
      setShowSideBar(false);
      setIsMobile(true);
    }else{
      setShowSideBar(true);
      setIsMobile(false);
    }
  }, true);

  useEffect(()=>{
    if (window.matchMedia('screen and (max-width: 1023px)').matches) {
      setShowSideBar(false);
      setIsMobile(true);
    }else{
      setShowSideBar(true);
      setIsMobile(false);
    }
  },[]);

  let login = localStorage.getItem("sensar_login");
  if(login){
    return (
      <div className="flex lg:flex-row flex-col justify-between items-start">
        {/* <div className="lg:w-[250px] w-full sidebar-holder"> */}
        {/* <div className="lg:w-[0px] sidebar-holder"> */}
        <div className="lg:w-[250px] sidebar-holder">
          <Sidebar />
        </div>
        {/* <div className="lg:w-[calc(100%-0px)] main lg:p-[35px] p-[10px] bg-[#f3f4ff] min-h-screen"> */}
        <div className={showSideBar ? `main-margin lg:w-[calc(100%-0px)] main lg:p-[35px] p-[10px] bg-[#f3f4ff] min-h-screen`:`lg:w-[calc(100%-0px)] main lg:p-[35px] p-[10px] bg-[#f3f4ff] min-h-screen`}>
          <Outlet />
        </div>
      </div>
    );
  }else{
    return <Navigate to="/"  replace={true} />;
  }
  
};

export default MainLayout;
