import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaPlus, FaSearch, FaUser, FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ProductDetails from "../../components/product-details";
import collection1 from "../../assets/img/collection5.png";
import collection2 from "../../assets/img/collection2.png";
import collection3 from "../../assets/img/collection3.png";
import collection4 from "../../assets/img/collection4.png";
import services from "../../components/services";
import LoadingSpinner from "../../components/Loading_spinner";
import Spinner from "../../components/spinner";
import Pagination from "../../components/pagination";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";
import Swal from "sweetalert2";

const ProductDetailsPage = () => {

  const [categories, setCategories] = React.useState([]);
  const [subcategories, setSubcategories] = React.useState([]);
  const [catId, setCatId] = useState('all');
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [dataLength, setDataLength] = useState(null);
  const [subcategoriesFound, setSubcategoriesFound] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [execute, setExecute] = useState(false);

  const {id} = useParams();

  const detailsData = [
    {
      id: 1,
      name: "Name Collection",
      image: collection1,
      tag: "Workstation/Desk",
    },
    {
      id: 2,
      name: "Name Collection",
      image: collection2,
      tag: "Sofa",
    },
    {
      id: 3,
      name: "Name Collection",
      image: collection3,
      tag: "Chair",
    },
    {
      id: 4,
      name: "Name Collection",
      image: collection4,
      tag: "Partition",
    },
    {
      id: 5,
      name: "Name Collection",
      image: collection1,
      tag: "Wall Panel",
    },
    {
      id: 6,
      name: "Name Collection",
      image: collection2,
      tag: "Silence Booth",
    },
    {
      id: 7,
      name: "Name Collection",
      image: collection3,
      tag: "Chair",
    },
    {
      id: 8,
      name: "Name Collection",
      image: collection4,
      tag: "Partition",
    },
  ];

  const [filteredCollection, setFilteredCollection] =
    React.useState(detailsData);

  const getSubcategory = (pageNo, searchText, supplier, category='all') => {
    
    fetch(services.url+"/get_sub_category3.php?per_page="+limit+"&page="+pageNo+"&search_text="+searchText+"&supplier="+supplier+"&category_id="+category)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setDataLength(res.no_of_rows);
        setExecute(true);
        setLoading(false);
        setSearchLoading(false);
        setSubcategories(res.subcategories);
        if(res.found === 1){
          setSubcategoriesFound(true);
        }else{
          setSubcategoriesFound(false);
        }
        // console.log(subcategory);
      });
  }

  useEffect(() => {

    let data = {supplier_id:id};
    
    fetch(services.url+"/get_category2.php", {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setCategories(res.categories);
        // console.log(subcategory);
      });
      setLoading(true);
      getSubcategory(page, searchText, id);
  }, []);

  const handelCategory = (e) => {
    let cat_id = e.currentTarget.id;
    // alert(cat_id);
    const filterBtn = document.querySelectorAll(".filterBtn");
    filterBtn.forEach((btn) => btn.classList.remove("active"));
    e.currentTarget.classList.add("active");
    setCatId(cat_id);
    setExecute(false);
    setLoading(true);
    setPage(1);
    getSubcategory(1, searchText, id, cat_id);

  }

  let totalPage = Math.ceil(dataLength/limit);
  let start = (page - 1) * limit + 1;
  let end = page * limit;

  function handlePageChange(value){
    let pageno = page;
    if(value === "... "){
      setPage(1);
      pageno = 1;
    }
    else if(value === "pre"){
      if(page !== 1){
        setPage(page - 1);
        pageno = page - 1;
      }
    }else if(value === "next"){
      if(page !== totalPage){
        setPage(page + 1);
        pageno = page + 1;
      }
    }else if(value === " ..."){
      setPage(totalPage);
      pageno = totalPage;
    }else{
      setPage(value);
      pageno = value;
    }
    setLoading(true);
    getSubcategory(pageno, searchText, id, catId);
  }

  const handleSearch = (ele) => {
    let searchInputText = ele.currentTarget.value;
    setExecute(false);
    setSearchLoading(true);
    setSearchText(searchInputText)
    setPage(1);
    getSubcategory(1, searchInputText, id, catId);
  }

  const deleteSubcategory = (subcatid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          id:subcatid
        };
        fetch(services.url+"/delete_product_subcategory.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          setPage(1);
          getSubcategory(1, searchText, id);
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        });
      }
      
    });
  }

  return (
    <div className="customers">
      <TopBar title="Products"/>
      
      <div className="flex md:flex-row flex-col-reverse md:gap-0 gap-3 md:p-0 p-3 md:my-7 justify-between items-center">
        <form
          action=""
          className="search flex justify-between items-center gap-3 bg-white px-6 py-3 rounded-full"
        >
          <FaSearch className="text-blue" />
          <input type="text" className="w-[300px] search_input" name="" id="" onChange={handleSearch} autoComplete="off" />
          {searchLoading ? <Spinner/> : ''}
        </form>

        <div className="right text-end flex justify-end items-center gap-7">
          {/* <p className="text-blue flex justify-start items-center gap-2">
            Newest <FaCaretDown className="text-xl" />
          </p> */}
          <select
            className="text-blue bg-transparent outline-none shadow-none border-none flex justify-start items-center gap-2"
            name=""
            id=""
          >
            <option value="Newest">Newest</option>
            <option value="Oldest">Oldest</option>
          </select>

          <Link
            to={"/products/add-new"}
            className="btn-blue flex justify-center items-center gap-2 px-5 py-3"
          >
            <FaPlus /> <span>Product</span>
          </Link>
        </div>
      </div>

      <div className="filters mb-10 flex flex-wrap justify-center items-center gap-[15px]">
        <button className="btn filterBtn active" onClick={handelCategory} id="all">All</button>
        {categories.map(categorie=>(
          <>
          <span className="text-blue">|</span>
          <button className="btn filterBtn" onClick={handelCategory} id={categorie.id}>{categorie.categoryName}</button>
          </>
        ))}
        {/* <span className="text-blue">|</span>
        <button className="btn filterBtn">Workstation/Desk</button>
        <span className="text-blue">|</span>
        <button className="btn filterBtn">Sofa</button>
        <span className="text-blue">|</span>
        <button className="btn filterBtn">Chair</button>
        <span className="text-blue">|</span>
        <button className="btn filterBtn">Partition</button>
        <span className="text-blue">|</span>
        <button className="btn filterBtn">Wall Panel</button>
        <span className="text-blue">|</span>
        <button className="btn filterBtn">Silence Booth</button> */}
      </div>
      {execute ?
      subcategoriesFound ?
       <ProductDetails collections={subcategories} deleteSubcategory={deleteSubcategory} />
       :
        <div className="not-found-text">Data not found!</div>
        : ''}
      
      <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} start={start} end={end} totalData={dataLength} onPageChange={handlePageChange}/>
      {loading ? <LoadingSpinner/> : ""}
      <Footer/>
    </div>
  );
};

export default ProductDetailsPage;
