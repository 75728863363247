import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

// import customer1 from "../../assets/img/customer1.png";
// import customer2 from "../../assets/img/customer2.png";
// import customer3 from "../../assets/img/customer3.png";
// import customer4 from "../../assets/img/customer4.png";
// import customer5 from "../../assets/img/customer5.png";
// import customer6 from "../../assets/img/customer6.png";
// import customer7 from "../../assets/img/customer7.png";
// import customer8 from "../../assets/img/customer8.png";
// import customer9 from "../../assets/img/customer9.png";
// import customer10 from "../../assets/img/customer10.png";
// import customer11 from "../../assets/img/customer11.png";
// import customer12 from "../../assets/img/customer12.png";
// import supplier from "../../assets/img/supplier.png";
import { FaCaretLeft, FaCaretRight, FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProjectCard from "../project-card";
import services from "../../components/services";
import Pagination from "../pagination";
import LoadingSpinner from "../../components/Loading_spinner";
import Spinner from "../../components/spinner";

const AllProjects = () => {
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [dataLength, setDataLength] = useState(null);
  const [projectFound, setProjectFound] = useState(false);
  const [execute, setExecute] = useState(false);
  const [orderBy, setOrderBy] = useState('Newest');
  const [searchText, setSearchText] = useState('');

  const getProjects = (pageNo, searchTextValue=searchText, orderByValue = orderBy) => {
    // console.log(page);
    fetch(services.url+"/projects.php?per_page="+limit+"&page="+pageNo+"&search_text="+searchTextValue+"&orderBy="+orderByValue)
      .then((res) => res.json())
      .then((res) => {
        setExecute(true);
        setDataLength(res.no_of_rows);
        setProjects(res.projects);
        setLoading(false);
        setSearchLoading(false);
        if(res.found === 1){
          setProjectFound(true);
        }else{
          setProjectFound(false);
        }
        // console.log(res);
      });
  };

  useEffect(() => {
    setLoading(true);
    getProjects(page);
    // fetch("https://sensar.vercel.app/api/v1/projects", {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => res.json())

    //   .then((res) => {
    //     setProjects(res);
    //   });
  }, []);

  let totalPage = Math.ceil(dataLength/limit);
  let start = (page - 1) * limit + 1;
  let end = page * limit;
  
  function handlePageChange(value){
    let pageno = page;
    if(value === "... "){
      setPage(1);
      pageno = 1;
    }
    else if(value === "pre"){
      if(page !== 1){
        setPage(page - 1);
        pageno = page - 1;
      }
    }else if(value === "next"){
      if(page !== totalPage){
        setPage(page + 1);
        pageno = page + 1;
      }
    }else if(value === " ..."){
      setPage(totalPage);
      pageno = totalPage;
    }else{
      setPage(value);
      pageno = value;
    }
    setLoading(true);
    getProjects(pageno);
  }

  const handleSearch = (ele) => {
    let searchTextValue = ele.currentTarget.value;
    setSearchText(searchTextValue)
    setSearchLoading(true);
    getProjects(1, searchTextValue, orderBy);
  }
  

  const handleOrderBy = (ele) => {
    let orderByValue = ele.currentTarget.value;
    setOrderBy(orderByValue);
    getProjects(1, searchText, orderByValue);
  }

  const deleteCard = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#F00",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          role:'admin',
          id
        };
        fetch(services.url+"/delete_project.php", {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((res) => {
          setPage(1);
          getProjects(1);
          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        });
      }
      
    });
    /*
    fetch(`https://sensar.vercel.app/api/v1/supplier/delete/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        getSuppliers();
      });
    */
  };

  return (
    <>
      <div className="flex md:flex-row flex-col-reverse md:gap-0 gap-3 md:p-0 p-3 md:my-7 justify-between items-center">
        <form
          action=""
          className="search flex justify-between items-center gap-3 bg-white px-6 py-3 rounded-full"
        >
          <FaSearch className="text-blue" />
          <input type="text" className="w-[300px] search_input" name="" id="" onChange={handleSearch} autoComplete="off" />
          {searchLoading ? <Spinner/> : ''}
        </form>

        <div className="right text-end flex justify-end items-center gap-7">
          {/* <p className="text-blue flex justify-start items-center gap-2">
            Newest <FaCaretDown className="text-xl" />
          </p> */}
          <select
            className="text-blue bg-transparent outline-none shadow-none border-none flex justify-start items-center gap-2"
            name=""
            id=""
            onChange={handleOrderBy}
          >
            <option value="Newest">Newest</option>
            <option value="Oldest">Oldest</option>
          </select>

          <Link
            to={"/projects/add-new"}
            className="btn-blue flex justify-center items-center gap-2 px-5 py-3"
          >
            <FaPlus /> <span>Project</span>
          </Link>
        </div>
      </div>
      {execute ?
      projectFound ?
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-[20px]">
        {projects.map((project) => (
          <ProjectCard project={project} key={project.project_id} deleteCard={deleteCard} />
        ))}
      </div>
        :
        <div className="not-found-text">Data not found!</div>
      : ''}
      <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} start={start} end={end} totalData={dataLength} onPageChange={handlePageChange}/>

      {loading ? <LoadingSpinner/> : ""}
    </>
  );
};

export default AllProjects;
