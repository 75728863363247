/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { FaEllipsisH, FaChevronDown } from "react-icons/fa";
import { Dropdown } from "antd";
import noLogo from "../../assets/img/no_logo.png";
import { Link, useNavigate } from "react-router-dom";


const ProjectSupplierCard = (props) => {

  const navigate = useNavigate();
  const viewProject = (id) => {
    navigate('/projects/supplier/'+id);
  }
  const editProject = (id) => {
    navigate('/projects/supplier/edit/'+id);
  }

  const items = [
    {
      key: "1",
      label: "View",
      onClick: () => viewProject(props.project.project_id),
    },
    {
      key: "2",
      label: "Edit quote",
      onClick: () => editProject(props.project.project_id),
    },
  ];
  /*
  console.log("project", project.data);
  const [customer, setCustomer] = React.useState([]);
  const [supplier, setSupplier] = React.useState([]);

  useEffect(() => {
    fetch(
      `https://sensar.vercel.app/api/v1/customer/${project?.data?.companyName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())

      .then((res) => {
        setCustomer(res);
      });
  }, [project.data.companyName]);

  useEffect(() => {
    fetch(
      `https://sensar.vercel.app/api/v1/supplier/${project?.data?.supplierName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())

      .then((res) => {
        setSupplier(res);
      });
  }, [project.data.supplierName]);

  console.log("customer", customer);
  */
  return (
    <div className="p-[20px] bg-white rounded-[10px] text-center">
      <div className="text-right">
        {/* <button className="btn">
          <FaEllipsisH className="text-[#a098ae]" />
        </button> */}
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <a className="action_btn" onClick={(e) => e.preventDefault()}>
            <FaEllipsisH className="text-[#a098ae] ml-auto block" />
          </a>
        </Dropdown>
      </div>
      <div className="content-image-box">
        <img
          src={props.project.customer_logo === "" ? noLogo : props.project.customer_logo}
          className="block mx-auto"
          alt=""
        />
      </div>
      <p className="text-sm text-[#a098ae]">customer</p>
      <p className="text-blue text-lg font-bold">{props.project.customer}</p>
      
        {
          props.project.send_qoute == 1 && props.project.validation == 0 && props.project.process == 0
          ?
          <p className="text-[#ff6600] mt-3 flex text-sm justify-center items-center gap-1">
            Waiting validation from customer
            <FaChevronDown />
          </p>
          :
          props.project.send_qoute == 1 && props.project.validation == 1 && props.project.paid == 0
          ?
          <p className="text-[#099916] mt-3 flex text-sm justify-center items-center gap-1">
            Validated
            <FaChevronDown />
          </p>
          :
          props.project.send_qoute == 1 && props.project.validation == 1 && props.project.paid == 1
          ?
          <p className="text-[#099916] mt-3 flex text-sm justify-center items-center gap-1">
            Complete
            <FaChevronDown />
          </p>
          :''
        }
    </div>
  );
};

export default ProjectSupplierCard;
