import React, { useEffect, useState, useRef } from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import services from "../../components/services";
import { FaBell, FaCog, FaPlus, FaEdit, FaTrash, FaUser } from "react-icons/fa";
import LoadingSpinner from "../../components/Loading_spinner";
import TopBar from "../../components/top-bar";
import Footer from "../../components/footer";


const CollectionProduct = () => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [execute, setExecute] = useState(false);
  const [products, setProducts] = React.useState([]);
  const [productsFound, setProductsFound] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState('');
  
  const {id} = useParams();

  const getProducts = () => {
    
    fetch(services.url+"/get_products2.php?subcategory_id="+id)
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        
        setExecute(true);
        setLoading(false);
        setSubcategoryName(res.subcategory_name);
        // console.log(res.subcategory_name);
        setProducts(res.products);
        if(res.found === 1){
          setProductsFound(true);
        }else{
          setProductsFound(false);
        }
        // console.log(res.products);
      });
  }

  useEffect(() => {
    // localStorage.removeItem("sub_categories");
    setLoading(true);
    getProducts();
    
  }, []);

  

  return (
    <div>
      <TopBar title={subcategoryName.toUpperCase()}/>
      
      <div className="form mt-5">
        <div className="form-header bg-[#4D43B5] py-2 px-8 rounded-t-[10px]">
          <p className="text-xl text-white">{subcategoryName.toUpperCase()}</p>
        </div>
        <div className="card-body bg-white p-8 rounded-b-[10px]">
            <div className="flex justify-start mt-2 items-center gap-3 mb-5">
                <Link
                to={"/products/add-new"}
                className="btn-blue flex justify-center items-center gap-2 px-5 py-3"
                >
                <FaPlus /> <span>Product</span>
                </Link>
            </div>
            <div className="product_header">
              <span>Image</span>
              <span>Model No</span>
              <span>Measurement</span>
            </div>
            {products.map(product => (
              <div key={product.product_id} className="product_body">
                <span className="product_body_image">
                <img
                  src={product.image}
                  className="block mx-auto w-[80px]"
                  alt=""
                />
                </span>
                <span className="product_body_model" style={{textAlign:'center'}}>{product.reference}</span>
                <span className="product_body_measuremen">
                  {product.measurement.map((measurement,index)=>(
                    <span className="product_measurement2" key={index}>
                      {measurement.measurement}
                    </span>
                  ))}
                </span>
              </div>
            ))}
            {loading ? <LoadingSpinner/> : ""}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default CollectionProduct;
